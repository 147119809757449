<template>
    <div class="m3_contetns" style="margin-top: 50px">

    <!-- 회원가입 box 시작 { -->
    <div class="m_ipt_box">

        <!-- 회원가입 기본정보 시작 { -->
        <div class="mib_mod">
            <div class="w-100">
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9"><div class="h3 ff-kor1 fc-w6">회원정보수정</div></div>

                <!-- 이메일 -->
                <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">이메일</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3"><span class="fen-set1 fc-w6"> {{ email }} </span></div>
                </div>

                <!-- 이름 -->
                <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">{{ usrType == 'PA' ? '법인명' : '이름' }}</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="이름을 입력하세요" v-model="fullName"></div>
                </div>

                <!-- 휴대폰번호, 인증번호입력 -->
                <div class="mib_box_b">
                    <div class="mib_set1 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">휴대폰</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3">
                            <div class="x_div_center">
                                <div><input type="text" class="form-control fkr-set2" style="width:150px; height:36px" placeholder="'-' 없이 숫자만 입력" v-model="phone" :readonly="certPass"></div>
                                <div class="ps-1" v-show="phone != this.user.info.phone"><button class="fkr-set1 fc-w1" @click="callAuth()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;">인증요청</button></div>
                            </div>
                        </div>
                    </div>
                    <div class="mib_set2 py-3">
                        <div class="mib_b1 text-end"><span class="fkr-set1">인증번호</span></div>
                        <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                        <div class="mib_b3">
                            <div class="x_div_center">
                                <div><input type="text" class="form-control fkr-set2" style="width:150px; height:36px" placeholder="" v-model="certNmbr" :readonly="certPass || (phone == this.user.info.phone)"></div>
                                <div class="ps-1"><button class="fkr-set1 fc-w1" @click="confirmAuth()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;" v-show="phone != this.user.info.phone">확인</button></div>
                            </div>
                        </div>
                    </div>
                </div>
                <span v-if="buildMode !== 'production'" style="color: red; font-size: smaller; margin-left: 100px">개발서버에서는 번호인증이 필수가 아닙니다.</span>

                <!-- 회원유형 -->
                <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">회원유형</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3">
                        <div class="m_type_set">
                            <div class="m_type_left x_div_center px-2">
                                <input type="radio" name="userType" id="userType_CU" v-model="usrType" value="CU" disabled/>
                                <div>
                                    <div><label for="userType_CU" :class="usrType == 'CU'? 'mbs m_in sec' : 'mbs m_in'" p_id="1"></label></div>
                                    <div class="py-1"><span class="x_div_center fkr-set1">고객</span></div>
                                </div>
                                <div style="width:75px">

                                </div>
                            </div>
                            <div class="m_type_right x_div_center px-2">
                                <input type="radio" name="userType" id="userType_PA" v-model="usrType" value="PA" disabled/>
                                <div>
                                    <div><label for="userType_PA" :class="usrType == 'PA'? 'mbs m_pa sec' : 'mbs m_pa'" p_id="2"></label></div>
                                    <div class="py-1"><span class="x_div_center fkr-set1">파트너기관</span></div>
                                </div>
                                <div style="width:110px; margin-top: -20px;">

                                    <div class="ps-3">
                                        <ul class="custom-control custom-radio">
                                            <li class="chk_box"><input type="radio" id="s_partner1" name="s_partner" class="custom-control-input" v-model="usrRoles" value="DS" disabled>
                                            <label class="custom-control-label" for="s_partner1"><span class="ff-kor1 fs-08 fw-bold">설계사</span></label></li>
                                            <li class="chk_box"><input type="radio" id="s_partner2" name="s_partner" class="custom-control-input" v-model="usrRoles" value="MF" disabled>
                                            <label class="custom-control-label" for="s_partner2"><span class="ff-kor1 fs-08 fw-bold">모듈러제작사</span></label></li>
                                            <li class="chk_box"><input type="radio" id="s_partner3" name="s_partner" class="custom-control-input" v-model="usrRoles" value="CT" disabled>
                                            <label class="custom-control-label" for="s_partner3"><span class="ff-kor1 fs-08 fw-bold">시공사</span></label></li>
                                            <li class="chk_box"><input type="radio" id="s_partner3" name="s_partner" class="custom-control-input" v-model="usrRoles" value="IN" disabled>
                                            <label class="custom-control-label" for="s_partner3"><span class="ff-kor1 fs-08 fw-bold">인테리어사</span></label></li>
                                            <li class="chk_box"><input type="radio" id="s_partner3" name="s_partner" class="custom-control-input" v-model="usrRoles" value="SL" disabled>
                                            <label class="custom-control-label" for="s_partner3"><span class="ff-kor1 fs-08 fw-bold">사업파트너</span></label></li>
                                            <li class="chk_box"><input type="radio" id="s_partner6" name="s_partner" class="custom-control-input" v-model="usrRoles" value="OT" disabled>
                                            <label class="custom-control-label" for="s_partner6"><span class="ff-kor1 fs-08 fw-bold">기타</span></label></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 영업파트너 -->
                <!-- <div class="mib_box_a py-3">
                    <div class="mib_a1 text-end"><span class="fkr-set1">영업파트너</span></div>
                    <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                    <div class="mib_a3">

                        <div class="mib_a3s">
                            <div class="py-1"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder="E-mail"></div>
                            <div class="x_div_center py-1 text-center" style="width:30px"><span class="ff-eng1 fs-09 fw-bold fc-w3">@</span></div>
                            <div class="py-1 pe-2"><input type="text" class="form-control ff-eng1" style="width:180px; height:36px" placeholder=""></div>
                            <div class="mib_a3s_d py-1">
                                <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px">
                                    <option value="">직접입력</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="google.com">google.com</option>
                                    <option value="daum.net">daum.net</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div> -->

                <!-- 파트너기관 추가정보 시작 { -->
                <div id="pid_2" class="pid_2" v-if="usrType == 'PA'">

                    <div class="x_div_center pt-5 pb-3" style="border-bottom:1px solid #d9d9d9"><div class="h3 ff-kor1 fc-w6">파트너기관 추가정보</div></div>

                    <!-- 사업자정보 -->
                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">사업자등록번호</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3">
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:70px; height:36px" placeholder="" v-model="bsnssNmbrHead"></div>
                                <div class="x_div_center px-1">-</div>
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:50px; height:36px" placeholder="" v-model="bsnssNmbrMid"></div>
                                <div class="x_div_center px-1">-</div>
                                <div><input type="text" class="form-control fen-set1 text-center px-1" style="width:70px; height:36px" placeholder="" v-model="bsnssNmbrTail"></div>
                            </div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">사업자등록증</span></div>
                            <div class="mib_b2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
                            <div class="mib_b3" style="height: 36px; padding: 10px 0px 6px 0px;" v-if="!bsnssEditing">
                                <form method="post" action="/api/v1/user/bsnss/filedownload">
                                    <input type="hidden" name="fileName" :value="bsnssFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ this.user.info.bsnssOrgNm }} </span></a>
                                </form>
                                &nbsp;&nbsp;
                                <a href="javascript:void(0)" @click="bsnssEditing = true"><i class="fa fa-edit"/></a>
                            </div>
                            <div class="mib_b3" v-if="bsnssEditing">
                                <input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 0)">
                                &nbsp;&nbsp;
                                <a href="javascript:void(0)" @click="bsnssEditing = false" style="padding-top: 10px"><i class="fa fa-undo"/></a>
                            </div>
                        </div>
                    </div>

                    <!-- 주소 -->
					<div class="mib_box_a py-3">
						<div class="mib_a1 text-end"><span class="fkr-set1">주소</span></div>
						<div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%"></div></div>
						<div class="mib_a3">

							<div class="d-flex">
								<div class="py-1"><input type="text" class="form-control ff-eng1" v-model="postcode" style="width:100px; height:36px" placeholder="" readonly></div>
								<div class="py-1 ps-1"><button class="fkr-set1 fc-w1" @click="searchAddr()" style="width:75px; height:36px; border:0; background-color:#000; border-radius:4px;">주소검색</button></div>
							</div>

							<div class="">
								<div class="py-1"><input type="text" class="form-control fkr-set2" v-model="addr1" style="width:100%; height:36px" placeholder="기본주소" readonly></div>
							</div>

							<div class="">
								<div class="py-1"><input type="text" class="form-control fkr-set2" v-model="addr2" style="width:100%; height:36px" placeholder="상세주소"></div>
							</div>

						</div>
					</div>

                    <!-- 지명원, 국가기술자격증 -->
                    <div class="mib_box_b">
                        <div class="mib_set1 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">지명원</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3" style="height: 36px; padding: 10px 0px 6px 0px;" v-if="profOrgNm">
                                <form method="post" action="/api/v1/user/prof/filedownload">
                                    <input type="hidden" name="fileName" :value="profFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ profOrgNm }} </span></a>
                                </form>
                                &nbsp;&nbsp;
                                <a href="javascript:void(0)" @click="profDeleting = true; profOrgNm = ''"><i class="fa fa-trash"/></a>
                            </div>
                            <div class="mib_b3" v-if="!profOrgNm || profDeleting">
                                <input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 1)">
                            </div>
                        </div>
                        <div class="mib_set2 py-3">
                            <div class="mib_b1 text-end"><span class="fkr-set1">국가자격증</span></div>
                            <div class="mib_b2 x_div_center"></div>
                            <div class="mib_b3" style="height: 36px; padding: 10px 0px 6px 0px;" v-if="ntnlOrgNm">
                                <form method="post" action="/api/v1/user/ntnl/filedownload">
                                    <input type="hidden" name="fileName" :value="ntnlFileName"/>
                                    <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ ntnlOrgNm }} </span></a>
                                </form>
                                &nbsp;&nbsp;
                                <a href="javascript:void(0)" @click="ntnlDeleting = true; ntnlOrgNm = ''"><i class="fa fa-trash"/></a>
                            </div>
                            <div class="mib_b3" v-if="!ntnlOrgNm || ntnlDeleting">
                                <input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling($event, 2)">
                            </div>
                        </div>
                    </div>

                    <!-- 홈페이지 -->
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">홈페이지</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" name="" value="" class="form-control fen-set1" style="width:100%; height:36px" placeholder="https://" v-model="hmpg"></div>
                    </div>

                    <!-- 포트폴리오 -->
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">포트폴리오</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><input type="text" name="" value="" class="form-control fen-set1" style="width:100%; height:36px" placeholder="https://" v-model="link"></div>
                    </div>

                    <div style="display: flex; padding-bottom: 10px">
                        <div class="mib_box_c w-100 pt-3"><h3 class="fkr-set1 fc-w6">수상이력</h3></div>
                        <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%; margin-top:8px"></div></div>
                        <div class="mib_a3" style="padding-top: 15px"><h3 class="fkr-set1 fc-w6">수정을 원하시는 항목은 삭제 후 신규추가의 방식으로 수정해주세요.</h3></div>
                    </div>

                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background:#000;">
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">수상명</span></div>
                            <div class="x_div_center" style="width:25%"><span class="fkr-set2 fc-w1">수상기관</span></div>
                            <div class="x_div_center" style="width:30%"><span class="fkr-set2 fc-w1">프로젝트</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1">수상연도</span></div>
                            <div class="x_div_center" style="width:5%"><span class="fkr-set2 fc-w1"></span></div>
                        </div>

                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(award, index) in awardList" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2" style="text-align: center;"> {{ award.awardNm }} </span></div>
                            <div class="x_div_center px-1" style="width:25%"><span class="fkr-set2" style="text-align: center;"> {{ award.awardInst }} </span></div>
                            <div class="x_div_center px-1" style="width:30%"><span class="fkr-set2" style="text-align: center;"> {{ award.awardPrj }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2" style="text-align: center;"> {{ award.awardYear }} </span></div>
                            <div class="x_div_center px-1" style="width:5%"><a href="javascript:void(0)" class="rm_frm" @click="removeAwardList(index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a></div>
                        </div>
                        <div class="x_div_center ls_bg1" style="height:55px" v-for="(award, index) in awardAdded" :key="index">
                            <div class="x_div_center px-1" style="width:20%"><input type="text" v-model="award.awardNm" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="수상명"></div>
                            <div class="x_div_center px-1" style="width:25%"><input type="text" v-model="award.awardInst" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="수상기관"></div>
                            <div class="x_div_center px-1" style="width:30%"><input type="text" v-model="award.awardPrj" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="프로젝트"></div>
                            <div class="x_div_center px-1" style="width:20%"><input type="text" v-model="award.awardYear" class="form-control fkr-set2 fc-w5 text-center" style="width:100%; height:36px" placeholder="YYYY"></div>
                            <div class="x_div_center px-1" style="width:5%"><a href="javascript:void(0)" class="rm_frm" @click="removeAwardAdded(index)"><i class="fa-solid fa-circle-minus" style="color:#C00"></i></a></div>
                        </div>

                        <div id="v_frm"></div>

                        <div class="x_div_center py-2"><a href="javascript:void(0)" id="add_frm" @click="addAward()"><i class="fa-solid fa-circle-plus fs-15" style="color:#000"></i></a></div>

                    </div>
                </div>
                <!-- } 파트너기관 추가정보 끝 -->

                <!-- 약관동의 시작 { -->
                <div v-if="usrType == 'PA'">
                    <div class="pt-4 pb-2">
                        <ul class="custom-control custom-checkbox">
                            <li class="chk_box"><input type="checkbox" id="agree3" name="agree3" class="custom-control-input" v-model="checkNoti" value="true" >
                            <label class="custom-control-label" for="agree3"><span class="fkr-set2">수주 가능한 프로젝트의 입찰 모집 알림 수신에 동의합니다</span></label></li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <div class="pt-4 pb-2">
                        <ul class="custom-control custom-checkbox">
                            <li class="chk_box"><input type="checkbox" id="agree3" name="agree3" class="custom-control-input" v-model="checkNoti" value="true" >
                            <label class="custom-control-label" for="agree3"><span class="fkr-set2">프로젝트의 작업안내보고 알림 수신에 동의합니다</span></label></li>
                        </ul>
                    </div>
                </div>

                <!-- } 약관동의 끝 -->

                <div class="x_div_center pt-4" style="margin-top: 15px">
                    <div class="px-2"><button class="fkr-set1" @click="submit()" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">정보수정<i v-if="fetching" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    <div class="px-2"><button class="fkr-set1" onclick="location.href='/member/profile'" style="width:150px; height:37px; border:0; background-color:#000; border-radius:4px; color:#fff">취소</button></div>
                </div>

            </div>
        </div>
        <!-- } 회원가입 기본정보 끝 -->

    </div>
    <!-- } 회원가입 box 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
import store from "@/store"
export default {
    name: 'ModifyPage',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            email: '',
            fullName: '',
            phone: '',
            certNmbr: '',
            usrCertId: '',
            certPass: false,
            usrType: '',
            usrRoles: '',
            
            daumObj: null,
            bsnssNmbrHead: '',
            bsnssNmbrMid: '',
            bsnssNmbrTail: '',
            bsnssNmbr: '',
            file: [],
            postcode: '',
            addr1: '',
            addr2: '',
            hmpg: '',
            link: '',
            awardList: [],
            awardAdded: [],
            awardDeleted: [],
            
            bsnssFileName: '',
            profFileName: '',
            ntnlFileName: '',

            profOrgNm: '',
            ntnlOrgNm: '',

            bsnssEditing: false,
            profDeleting: false,
            ntnlDeleting: false,
            checkNoti: false,
            buildMode: process.env.NODE_ENV,
            fetching: false
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }

        this.email = this.user.info.email;
        this.fullName = this.user.info.fullName;
        this.phone = this.user.info.phone;
        this.usrType = this.user.info.usrType;
        this.usrRoles = this.user.info.usrRole;
        
        this.postcode = this.user.info.postCd;
        this.addr1 = this.user.info.addr1;
        this.addr2 = this.user.info.addr2;
        this.hmpg = this.user.info.hmpg;
        this.link = this.user.info.link;
        this.awardList = this.user.info.awardList;
        this.checkNoti = this.user.info.newBddngNotiRcvYn == "Y"? true : false;

        if(this.usrType == 'PA') {
            this.bsnssNmbrHead =  this.user.info.bsnssNmbr.substring(0,3);
            this.bsnssNmbrMid =  this.user.info.bsnssNmbr.substring(3,5);
            this.bsnssNmbrTail =  this.user.info.bsnssNmbr.substring(5);
            
            this.bsnssFileName = this.user.info.bsnssImgPath.replace("platform/usr/bsnss/", '');
            this.ntnlFileName = this.user.info.ntnlImgPath? this.user.info.ntnlImgPath.replace("platform/usr/ntnl/", '') : '';
            this.profFileName = this.user.info.profImgPath? this.user.info.profImgPath.replace("platform/usr/prof/", '') : '';

            this.profOrgNm = this.user.info.profOrgNm;
            this.ntnlOrgNm = this.user.info.ntnlOrgNm

            if(this.awardList.length > 0) {
                this.awardList.forEach((val) => {
                    val.dltYn = 'N';
                })
            }
        }

        this.daumObj = new window.daum.Postcode({
            oncomplete: (data) => {
            var addr = ''; // 주소 변수
            var extraAddr = ''; // 참고항목 변수

            //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
            if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                addr = data.roadAddress;
            } else { // 사용자가 지번 주소를 선택했을 경우(J)
                addr = data.jibunAddress;
            }

            // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
            if(data.userSelectedType === 'R'){
                // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                    extraAddr += data.bname;
                }
                // 건물명이 있고, 공동주택일 경우 추가한다.
                if(data.buildingName !== '' && data.apartment === 'Y'){
                    extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                }
                // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                if(extraAddr !== ''){
                    extraAddr = ' (' + extraAddr + ')';
                }
            }
            this.postcode = data.zonecode;
            this.addr1 = addr + extraAddr;
            }
        });
    },
    watch: {
        phone(val) {
            this.phone = val.replace(/-/g, '').replace(/\s/g, '');
        }
    },
    methods: {
        async callAuth() {
            if(!this.phone) {
                alert('휴대폰번호를 입력해주세요!');
            }
            else {
                var phoneRegular = this.phone.replace(/^0/g, '82');
                var rtnval = await Api.post('/api/v1/user/count/phone', {phone: this.phone});
                if(rtnval.result){
                    if(rtnval.data.count > 0) {
                        alert('이미 등록된 휴대폰번호입니다.');
                        return false;
                    }

                    var result = await Api.post('/api/v1/common/send/sms', {phone: phoneRegular});
                    if(result.result){
                        this.usrCertId = result.data.usrCertId;
                        alert('입력하신 번호로 인증번호가 전송되었습니다.\n인증번호를 입력하고 확인 버튼을 눌러주세요.')
                    }
                    else{
                        alert(result.errormsg);
                    }
                }
                else{
                    alert(rtnval.errormsg);
                }
            }
        },
        async confirmAuth() {
            var result = await Api.post('/api/v1/common/check/certNmbr', {certNmbr: this.certNmbr, usrCertId: this.usrCertId});
            if(result.result){
                this.certPass = true;
                alert('휴대폰번호가 정상적으로 인증되었습니다.')
            }
            else{
                alert(result.errormsg);
            }
        },
        fileHandling(e, index) {
            if(index == 1) {
                this.profDeleting = true;
            }
            else if(index == 2) {
                this.ntnlDeleting = true;
            }
            this.file[index] = e.target.files[0];
        },
        searchAddr() {
          this.daumObj.open();
        },
        addAward() {
            this.awardAdded.push({awardNm: '', awardInst: '', awardPrj: '', awardYear: '', dltYn: 'N'})
        },
        removeAwardList(index) {
            this.awardList[index].dltYn = 'Y';
            this.awardDeleted.push(this.awardList[index]);
            this.awardList = this.awardList.filter((v,i) => i != index );
        },
        removeAwardAdded(index) {
            this.awardAdded = this.awardAdded.filter((v,i) => i != index );
        },
        async submit() {
            this.bsnssNmbr = this.bsnssNmbrHead + this.bsnssNmbrMid + this.bsnssNmbrTail;
            if(this.validation()){
                var request = {
                    addr1: this.addr1,
                    addr2: this.addr2,
                    postCd: this.postcode,
                    awardList: this.awardList.concat(this.awardAdded).concat(this.awardDeleted),
                    email: this.email,
                    fullName: this.fullName,
                    hmpg: this.hmpg,
                    link: this.link,
                    phone: this.phone,
                    usrRoles: this.usrType == 'MA'? 'M3' : this.usrRoles,
                    usrType: this.usrType,
                    bsnssNmbr: this.bsnssNmbr,
                    bsnssOrgNm: this.bsnssEditing? this.file[0].name : this.user.info.bsnssOrgNm,
                    profOrgNm: this.profDeleting? (this.file[1]? this.file[1].name : '') : this.user.info.profOrgNm,
                    ntnlOrgNm: this.ntnlDeleting? (this.file[2]? this.file[2].name : '') : this.user.info.ntnlOrgNm,
                    bsnssDltYn: this.bsnssEditing? "Y" : "N",
                    profDltYn: this.profDeleting && !this.file[1]? "Y" : "N",
                    ntnlDltYn: this.ntnlDeleting && !this.file[2]? "Y" : "N",
                    newBddngNotiRcvYn: this.checkNoti? "Y" : "N"
                }
                const formData = new FormData();
                formData.append(
                    "request",
                    new Blob([JSON.stringify(request)], { type: "application/json" })
                );
                if(this.usrType == 'PA') {
                    if(this.bsnssEditing) {
                        formData.append("bsnssImg", this.file[0]);
                    }
                    if(this.profDeleting) {
                        formData.append("profImg", this.file[1]);
                    }
                    if(this.ntnlDeleting) {
                        formData.append("ntnlImg", this.file[2]);
                    }
                }
                this.fetching = true;
                var result = await Api.post('/api/v1/user/update', formData);
                this.fetching = false;
                if(result.result){
                    alert('정상적으로 수정되었습니다.')
                    const rtnval = await Api.get('/api/v1/user/find/usr/info');
                    if(rtnval.result){
                        store.commit('setUserInfo', {id: result.data.id, info: rtnval.data})
                    }
                    location.href='/member/profile';
                }
                else{
                    alert(result.errormsg);
                }
            }
        },
        validation() {
            if(!this.fullName) {
                alert('이름은 필수 입력사항입니다.');
                return false;
            }
            if(!this.phone) {
                alert('휴대폰번호는 필수 입력사항입니다.');
                return false;
            }
            if(this.usrType == 'PA') {
                if(!(this.bsnssNmbrHead && this.bsnssNmbrMid && this.bsnssNmbrTail)) {
                    alert('사업자등록번호는 필수 입력사항입니다.');
                    return false;
                }
                if(this.bsnssEditing && !this.file[0]) {
                    alert('사업자등록증은 필수 첨부파일입니다.');
                    return false;
                }
                if((this.hmpg && this.hmpg.search(/^http/g) < 0) || (this.link && this.link.search(/^http/g))) {
                    alert('사이트 주소는 http:// 또는 https:// 로 시작해야합니다.');
                    return false;
                }
            }
            if(this.phone != this.user.info.phone && !this.certPass && this.buildMode === 'production') {
                alert('휴대폰인증은 필수사항입니다.');
                return false;
            }

            return true;
        }
    }
}
</script>

<style scoped>
.custom-radio .custom-control-label::before, .custom-control-label::after {
    top: 0.2rem;
}
</style>