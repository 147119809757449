<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">시스템 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold">고객문의 목록</span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">총 문의: {{ numOfQuestions }} 건</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10명</option>
                <option value="20">페이지당 20명</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="findQuestionWithFilter()" style="width:120px; height:37px; margin-left: 20px">
              <option value=''>전체</option>
              <option value='0'>문의하기</option>
              <option value='1'>파트너지원</option>
            </select>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="ctgryFilter = ''; findQuestionWithFilter()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="ctgryFilter = ''; findQuestionWithFilter()" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>

          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">문의유형</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">고객명</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">연락처</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">이메일</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">문의일시</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">상세</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in questionList" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.ctgry == 0? '문의하기' : '파트너지원'}}</span></div>
                <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.fullName}}</span></div>
                <div class="x_div_center m_line" style="width:20%"><span class="fkr-set2 fs-9">{{item.phone}}</span></div>
                <div class="x_div_center m_line" style="width:20%"><span class="fkr-set2 fs-9">{{item.email}}</span></div>
                <div class="x_div_center m_line" style="width:20%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.replace('T', ' | ').split('.')[0] : ''}}</span></div>
                <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="showModal(item.mailHtml)"><i class="fa fa-share-from-square"/></a></span></div>
              </div>
            </div>
          </div>

          <!-- 페이징 -->
          <div class="x_div_center py-3" v-if="!fetching">
              <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
              <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
              <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
              <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; findQuestionsAll()" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
              <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
              <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
              <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>

    <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true" style="z-index:100000001;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

          <div class="w-100">
            <div class="x_div_center w-100"
              style="background-color:lightgray; border-top-left-radius:8px; border-top-right-radius:8px">
              <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">고객문의본문</span></div>
              <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()"
                  style="color:#000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
            </div>

            <div class="x_div_center py-2 px-4">
              <div class="w-100 x_div_center py-3">
                <div class="x_div_center px-2" v-html="mailHtml"></div>
              </div>
            </div>

            <div class="x_div_center py-4">
              <div class="px-2"><button @click="closeModal()" class="x_div_center"
                  style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000"
                  data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
            </div>

            <div style="height:20px"></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "SmtpHistory",
  components: {
		AdminLNB
	},
  props: {
      user: {
          type: Object,
          default: null
      },
  },
  data(){
    return {
        fetching: false,
        searchWord: null,
        numOfQuestions: '',
        questionList:[],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        ctgryFilter: '',
        mailHtml: ''
    }
  },
  created() {
    if(this.user.info.adminYn == 'E') {
      location.href = '/admin/estmt/list';
    }
    else if(this.user.info.adminYn == 'R') {
      location.href = '/admin/site/recent-list';
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.findQuestionsAll();
    this.setPaging();
  },
  methods:{
    async findQuestionsAll(){
      this.fetching = true;
      this.questionList = [];
      var rtnval = await Api.post('/api/v1/common/smtp/history/list', {page: this.currentPage, window: this.pageSize, ctgry: this.ctgryFilter? this.ctgryFilter : null, searchWord: this.searchWord});
      if(rtnval.result){
        this.questionList = rtnval.data.findAllSmtpHistoryList;
        this.numOfQuestions = rtnval.data.count;
      }
      this.fetching = false;
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.numOfQuestions / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        await this.findQuestionsAll();
        this.setPaging();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      await this.setPaging();
      this.findQuestionsAll();
    },

    async findQuestionWithFilter() {
      await this.findQuestionsAll();
      this.currentPage = 1;
      this.setPaging();
    },

    showModal(contents) {
      this.mailHtml = contents;
      window.$('#v-view').modal('show');
    },

    closeModal() {
      window.$('#v-view').modal('hide');
    }
    
  }
}
</script>

