<template>
    <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog modal-lg" role="document" style="width:480px">
            <div class="modal-content">

                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:lightgray; border-top-left-radius:8px; border-top-right-radius:8px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">회원님의 계정을 선택해주세요.</span></div>
                        <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                    </div>

                    <div class="x_div_center py-2">
                        <div class="x_div_center px-4 w-100" style="display:grid !important; justify-content: center !important;">
                          <span style="margin-top: 1.5rem !important;">회원명, 이메일 또는 휴대폰번호로 검색하세요.</span>
                          <div class="py-4" style="padding: 1.5rem !important;">
                            <div class="x_div d-flex" style="justify-content: space-between;">
                              <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" placeholder="휴대폰번호는 '-' 제외하고 입력" @keyup.enter="findUsrAll()" v-model="searchKeywordUsr"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem"></div>
                                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findUsrAll()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                              </div>
                            </div>
                            <div class="x_div">
                              <div id="auto_frm" v-show="isSearchUsr" class="p-2" style="display:none; position:absolute; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                                <div v-for="(item, index) in usrList" :key="index" class="x_div_center ls_bg1">
                                  <div class="w-100 text-start py-1 px-2">
                                    <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.id" class="fa fa-spinner fa-spin" style="color:#333"></i>
                                    <a href="javascript:void(0)" @click="customerId = item.id; customerNm = item.fullName; searchKeywordUsr = item.email; isSearchUsr = false">{{item.id? (item.fullName + ' - ' + item.email + ' - ' + item.phone) : ''}}</a>
                                  </div>
                                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="x_div_center py-4">
                        <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #000; border-radius:4px; color:#000" data-dismiss="modal"><span class="fkr-set2">취소</span></button></div>
                        <div class="px-2"><button class="x_div_center" @click="confirm()" style="width:90px; height:30px; border:0; background-color:#000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">확인</span></button></div>
                    </div>

                    <div style="height:20px"></div>

                </div>

            </div>
        </div>
    </div> 
</template>

<script>
    import Api from '@/api'
    export default {
        name: 'BiddingPrjDetail',
        data() {
            return {
                isSearchUsr: false,
                searchKeywordUsr: null,
                customerId: null,
                customerNm: null,
                usrList: [],
            }
        },
        methods: {
            async findUsrAll() {
                this.isSearchUsr = true;
                this.usrList = [{id:null}];
                var rtnval = await Api.post('/api/v1/admin/usr/mng/findAll/usrAll', {page: 1, window: 100, role: null, searchWord: this.searchKeywordUsr});
                if(rtnval.result){
                    this.usrList = rtnval.data.findAllUsrDtoList;
                }
            },
            confirm() {
                if(!this.customerId) {
                    alert('회원 계정을 검색 및 선택하세요.');
                    return;
                }
                this.$emit('selectedUsrId', {usrId: this.customerId, usrNm: this.customerNm});
                this.closeModal();
            },
            closeModal() {
                this.isSearchUsr = false;
                this.searchKeywordUsr = null;
                this.customerId = null;
                this.customerNm = null;
                window.$('#modalForm').modal('hide');
            }
        }
    }
</script>
