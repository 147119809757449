<template>
    <div class="d-flex">
        <AdminLNB/>
        <div class="ca_main">
            <div class="x_div_center">
                <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">회원가입 통계</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:20px"></div>

                <div class="w-100" style="display: flex;">
                    <div class="x_div_center" style="width:270px; height:34px; border:1px solid #d6d6d6; border-radius:4px; margin-left: 20px; margin-right: 20px;">
                        <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="countUsers()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                        <div class="x_div_center">
                            <select class="custom-select ff-eng1 fs-085" v-model="year" style="width:95px; height:30px; margin-left: 10px">
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                        </div>
                        <div class="x_div_center">
                            <select class="custom-select ff-eng1 fs-085" v-model="month" style="width:95px; height:30px; margin-left: 10px">
                                <option value="0">1</option>
                                <option value="1">2</option>
                                <option value="2">3</option>
                                <option value="3">4</option>
                                <option value="4">5</option>
                                <option value="5">6</option>
                                <option value="6">7</option>
                                <option value="7">8</option>
                                <option value="8">9</option>
                                <option value="9">10</option>
                                <option value="10">11</option>
                                <option value="11">12</option>
                            </select>
                        </div>
                    </div>
                    <span class="fkr-set2 fs-10">총 가입자: {{ numOfNewUsers[0] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">고객: {{ numOfNewUsers[1] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">설계사: {{ numOfNewUsers[2] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">제조사: {{ numOfNewUsers[3] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">시공사: {{ numOfNewUsers[4] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">인테리어사: {{ numOfNewUsers[5] }} 명</span>
                    <span class="fkr-set2 fs-10" style="margin-left:15px">사업파트너: {{ numOfNewUsers[6] }} 명</span>
                </div>

                <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else>
                    <LineChart :labels="labels" :data="data" />
                </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import LineChart from '@/components/Linechart.vue'
import Auth from '@/api/auth'
import Api from '@/api'

const roleCode = [
    'AL',
    'OW',
    'DS',
    'MF',
    'CT',
    'IN',
    'SL',
    'M3'
]

export default {
  name: "UsrSttc",
  components: {
		AdminLNB, LineChart
	},
  data(){
    return {
        numOfNewUsers: [0,0,0,0,0,0,0],
        year: 2023,
        month: 0,
        labels: [],
        data: [[],[],[],[],[],[],[]],
        fetching: false
    }
  },
  watch: {
    year() {
        this.countUsers();
    },
    month() {
        this.countUsers();
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },
    async countUsers() {
        var year = parseInt(this.year);
        var month = parseInt(this.month);
        var timezoneOffset = new Date().getTimezoneOffset() * 60000;
        var startDateTime = new Date(year, month, 1).getTime();
        var endDateTime = new Date(month == 11 ? year+1 : year, month == 11 ? 0 : month + 1, 1).getTime();
        var lastDate = new Date(month == 11 ? year+1 : year, month == 11 ? 0 : month + 1, 0).getDate();
        this.labels = Array.from({length:lastDate}, (v,i)=>i+1);
        this.fetching = true;
        for(var i=0; i<7; i++) {
            this.data[i] = Array.from({length:lastDate}).fill(0);
            var rtnval = await Api.post('/api/v1/admin/usr/mng/find/usr/plot/newusers', {startDt: new Date(startDateTime-timezoneOffset).toISOString(), endDt: new Date(endDateTime-timezoneOffset).toISOString(), role: i==0? null : roleCode[i]});
            if(rtnval.result){
                this.numOfNewUsers[i] = rtnval.data.count;
                rtnval.data.countList.forEach((val) => {
                    var index = parseInt(val.date.substring(8,10))-1;
                    this.data[i][index] = val.count;
                })
            }
            else {
                alert(rtnval.errormsg);
            }
        }
        this.fetching = false;
    }
 }
}
</script>