<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">시스템관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>코드목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">전체 코드 수: {{ codeList.length }}</span>
            <select class="custom-select ff-eng1 fs-085" v-model="stateFilter" @change="filterByCodeState(false)" style="width:120px; height:37px; margin-left: 20px">
              <option value="0">전체</option>
              <option value="1">사용중</option>
              <option value="2">미사용(삭제됨)</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="switchCtgry(false)" style="width:300px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in ctgryList" :key="i" :value="item.code">{{ item.name }}</option>
            </select>
            <div class="ps-2" style="margin-left: auto; padding-left: 180px !important;"><button class="fkr-set2 fs-095" @click="openEditCode(null)" style="width:80px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">코드 등록</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findCode()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findCode()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="코드검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:4%"><span class="fkr-set1 fs-9">순서</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">코드</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">코드이름</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">상위코드</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">상위코드이름</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">상위코드(2차)</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">상위코드(2차)이름</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:5%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in codeListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:4%"><span class="fkr-set2 fs-9" v-html="item.orderNum >= 0? item.orderNum : '<strong style=\'color:coral\'>미사용</strong>'"></span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.code}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.name}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.upperCode? item.upperCode.code : ''}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.upperCode? item.upperCode.name : ''}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.upperCodeSecondary? item.upperCodeSecondary.code : ''}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.upperCodeSecondary? item.upperCodeSecondary.name : ''}}</span></div>
                  <div class="x_div_center m_line" :style="'width:13%; ' + (item.orderNum < 0? 'color:lightgrey' : '')"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:5%;"><span class="fkr-set2 fs-9"><a href="javascript:void(0)" @click="openEditCode(item)"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>

      <div id="editCode" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="w-100">
              <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">코드 편집</span></div>
                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
              </div>

              <div class="x_div_center py-2 px-4">
                <div class="w-100">
                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">코드</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" placeholder="6자이내, 한글불가" v-model="codeInput" :disabled="editMode"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">코드 이름</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="nameInputed"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">비고</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="etcInputed"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">카테고리</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <select class="custom-select ff-eng1 fs-085" style="width:180px; height:36px" v-model="categoryCodeInput">
                          <option v-for="(item, i) in ctgryList.slice(1)" :key="i" :value="item.code">{{ item.name }}</option>
                        </select>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">순서</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3">
                        <div class="mib_a3s">
                          <div class="py-1">
                            <input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="orderNumInput">
                          </div>
                          <div class="mib_a2 x_div_center"><div style="width:6px; height:6px; background-color:#e60; border-radius:50%; margin-top:8px"></div></div>
                          <div class="mib_a3s_d py-1" style="padding-top: 15px !important"><h3 class="fkr-set1 fc-w6">동일 카테고리 내에서의 코드의 순서입니다.</h3></div>
                        </div>
                      </div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">상위코드</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="upprCdInput"></div>
                  </div>

                  <div class="mib_box_a py-3">
                      <div class="mib_a1 text-end"><span class="fkr-set1">상위코드(2차)</span></div>
                      <div class="mib_a2 x_div_center"></div>
                      <div class="mib_a3"><input type="text" class="form-control fkr-set2" style="width:180px; height:36px" v-model="upprCdScndryInput"></div>
                  </div>

                </div>
              </div>

              <div class="x_div_center py-4">
                <div class="px-2" v-if="editMode && using"><button @click="removeCode()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">삭제</span></button></div>
                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                <div class="px-2"><button @click="setCode()" class="x_div_center" :disabled="submitting" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">{{ editMode? (using? '수정' : '부활') : '등록' }}<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
              </div>

              <div style="height:20px"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "CodeList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        searchWord: '',
        ctgryList: [],
        codeList: [],
        codeListCategorized: [],
        codeListFiltered: [],
        codeListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        ctgryFilter: '',
        stateFilter: '0',
        codeInput: '',
        nameInputed: '',
        etcInputed: '',
        categoryCodeInput: '',
        orderNumInput: 0,
        upprCdInput: '',
        upprCdScndryInput: '',
        editMode: true,
        using: true,
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    await this.fetchCodes();
    this.setPaging();
    this.renderList();
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn != 'S'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchCodes() {
      this.fetching = true;
      this.codeList = [];
      this.ctgryList = [];
      const returnvalue = await Api.get('/api/v1/common/get/code/all');
      if(returnvalue.result) {
          this.codeList = returnvalue.data;
          this.codeListCategorized = this.codeList;
          this.codeListFiltered = this.codeListCategorized;
          this.codeList.forEach((code) => {
            if(!code.category && code.orderNum >= 0) {
              this.ctgryList.push(code);
            }
          })
          this.ctgryList = [{code: "", name: '전체'}, {code: 1, name: 'root코드'}].concat(this.ctgryList.sort((a, b) => a.orderNum - b.orderNum));
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    findCode() {
      this.codeListCategorized = [];
      this.codeList.forEach((code) => {
        if(code.code.indexOf(this.searchWord) >= 0 || code.name.indexOf(this.searchWord) >= 0) {
          this.codeListCategorized.push(code);
        }
      })
      this.filterByCodeState(false);
    },

    renderList() {
      this.codeListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.codeListFiltered.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.codeListFiltered.length); j++) {
        this.codeListPaged.push(this.codeListFiltered[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.codeListFiltered.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    switchCtgry(forceUpdate) {
      this.codeListCategorized = [];
      if(this.ctgryFilter == "") {
        this.codeListCategorized = this.codeList;
      }
      else {
        this.codeList.forEach((code) => {
          if((!code.category && this.ctgryFilter == 1) || (code.category && code.category.code == this.ctgryFilter)) {
            this.codeListCategorized.push(code);
          }
        })
      }
      this.filterByCodeState(forceUpdate);
    },

    filterByCodeState(forceUpdate) {
      if(!forceUpdate) {
        this.currentPage = 1;
      }
      this.codeListFiltered = [];
      switch(this.stateFilter) {
        case '0' : this.codeListFiltered = this.codeListCategorized;
          break;
        case '1' :
          this.codeListCategorized.forEach((code) => {
            if(code.orderNum >= 0) {
              this.codeListFiltered.push(code);
            }
          })
          break;
        case '2' :
          this.codeListCategorized.forEach((code) => {
            if(code.orderNum < 0) {
              this.codeListFiltered.push(code);
            }
          })
          break;
      }
      this.setPaging();
      this.renderList();
    },

    openEditCode(codeToEdit) { 
      if(codeToEdit) {
        this.codeInput = codeToEdit.code;
        this.nameInputed = codeToEdit.name;
        this.etcInputed = codeToEdit.etc;
        this.categoryCodeInput = codeToEdit.category? codeToEdit.category.code : 1;
        this.orderNumInput = codeToEdit.orderNum < 0? null : codeToEdit.orderNum;
        this.upprCdInput = codeToEdit.upperCode? codeToEdit.upperCode.code : null;
        this.upprCdScndryInput = codeToEdit.upperCodeSecondary? codeToEdit.upperCodeSecondary.code : null;
        this.editMode = true;
        this.using = codeToEdit.orderNum < 0? false : true;
      }
      else {
        this.codeInput = null;
        this.nameInputed = null;
        this.etcInputed = null;
        this.categoryCodeInput = 1;
        this.orderNumInput = 0;
        this.upprCdInput = null;
        this.upprCdScndryInput = null;
        this.editMode = false;
        this.using = true;
      }
      window.$('#editCode').modal('show');
    },

    async setCode() {
      this.submitting = true;
      const rtnval = await Api.post('/api/v1/common/' + (this.editMode? (this.using? 'update' : 'revert') : 'set') + '/code', {
        code: this.codeInput,
        name: this.nameInputed,
        etc: this.etcInputed,
        category: this.categoryCodeInput == 1? null : this.categoryCodeInput,
        orderNum: this.orderNumInput,
        upperCode: this.upprCdInput,
        upperCodeSecondary: this.upprCdScndryInput
      })
      if(rtnval.result) {
        alert('정상적으로 등록되었습니다.');
        window.$('#editCode').modal('hide');
        await this.fetchCodes();
        this.switchCtgry(true);
      }
      else {
        alert(rtnval.errormsg)
      }
      this.submitting = false;
    },

    async removeCode() {
      if(confirm('해당 코드를 미사용 처리하시겠습니까?')) {
        this.submitting = true;
        const rtnval = await Api.get('/api/v1/common/delete/code?codeId=' + this.codeInput)
        if(rtnval.result) {
          alert('정상적으로 삭제되었습니다.');
          window.$('#editCode').modal('hide');
          await this.fetchCodes();
          this.switchCtgry(true);
        }
        else {
          alert(rtnval.errormsg)
        }
        this.submitting = false;
      }
    },

    closeModal() {
			window.$('#editCode').modal('hide');
		},
  }
}
</script>

<style scoped>
.mib_box_a {
    width: auto;
}
</style>
