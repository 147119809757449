<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">임원진 프로필</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>프로필 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">           
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="5">페이지당 5개</option>
                <option value="10">페이지당 10개</option>
            </select> 
            <div class="ps-2" :style="'padding-left: ' + (editingOrder? '480px' : '500px') + ' !important'"><button class="fkr-set2 fs-095" style="width:100px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;" @click="editProfile(0)">새 프로필</button></div>
            <div class="ps-2" v-if="!editingOrder" style="margin-left: 20px;"><button class="fkr-set2 fs-095" @click="editingOrder = true;" :disabled="searchWord != ''" :style="'width:120px; height:35px; cursor:pointer; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (searchWord? 'opacity:0.5' : '')">노출순서 변경</button></div>
            <div class="ps-2" v-else style="margin-left: 20px;">
              <i class="fa fa-undo" style="cursor:pointer; margin-right:10px" @click="saveModification(false)"/><button class="fkr-set2 fs-095" @click="saveModification(true)" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">변경사항 저장</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findProfile(1)"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findProfile(1)" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>
          
          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">이름</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">직함</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">요약문</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">노출순서</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in fullSpecListPaged" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberName}}</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberTitle}}</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberSummary}}</span></div>
                <div v-if="!editingOrder" class="x_div_center" style="width:15%"><span class="fkr-set2 fs-9">{{(currentPageFullSpec-1) * pageSize + index + 1}}</span></div>
                <div v-if="editingOrder" class="x_div_center" style="width:15%; display: flex;">
                  <i class="fas fa-arrow-up" style="cursor:pointer" 
                    @click="var absltIdx = (currentPageFullSpec-1) * pageSize + index; 
                            if(absltIdx-1 >=0) [fullSpecList[absltIdx-1], fullSpecList[absltIdx]] = [fullSpecList[absltIdx], fullSpecList[absltIdx-1]]; 
                            renderListFullSpec(currentPageFullSpec)"/>
                  &nbsp;
                  <i class="fas fa-arrow-down" style="cursor:pointer" 
                    @click="var absltIdx = (currentPageFullSpec-1) * pageSize + index; 
                            if(absltIdx+1 < fullSpecList.length) [fullSpecList[absltIdx+1], fullSpecList[absltIdx]] = [fullSpecList[absltIdx], fullSpecList[absltIdx+1]]; 
                            renderListFullSpec(currentPageFullSpec)"/>
                </div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt.split('T')[0]}}</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9"><i class="fa fa-edit" style="cursor:pointer" @click="editProfile(item.memberId)"/></span></div>
              </div>
            </div>
          </div>

          <!-- 페이징 -->
          <div class="x_div_center py-3" v-if="!fetching">
              <a class="px-2" @click="switchPageFullSpec(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
              <a class="px-2" @click="switchPageFullSpec(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
              <span v-if="pageQueueFullSpec[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
              <a v-for="index in pageQueueFullSpec" :key="index" class="px-2" @click="renderListFullSpec(index);" style="margin-right: 10px"><span :class="'fen-set' + (currentPageFullSpec == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
              <span v-if="pageQueueFullSpec[pageQueueFullSpec.length-1] < numOfPageFullSpec" style="cursor:pointer;" class="fen-set2">...</span>
              <a class="px-2" @click="switchPageFullSpec(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
              <a class="px-2" @click="switchPageFullSpec(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
          </div>

          <div style="height:50px"></div>

          <div class="w-100" style="display: flex;">
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findProfile(0)"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findProfile(0)" style="border:0; width:170px; height:30px; padding:0 .3rem"></div>
              </div>
            </div>
          </div>

          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">이름</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">직함</span></div>
              <div class="x_div_center" style="width:20%"><span class="fkr-set1 fs-9">요약문</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">노출순서</span></div>
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in shortSpecListPaged" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberName}}</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberTitle}}</span></div>
                <div class="x_div_center" style="width:20%"><span class="fkr-set2 fs-9">{{item.memberSummary}}</span></div>
                <div v-if="!editingOrder" class="x_div_center" style="width:15%"><span class="fkr-set2 fs-9">{{(currentPageShortSpec-1) * pageSize + index + 1}}</span></div>
                <div v-if="editingOrder" class="x_div_center" style="width:15%; display: flex;">
                  <i class="fas fa-arrow-up" style="cursor:pointer" 
                    @click="var absltIdx = (currentPageShortSpec-1) * pageSize + index; 
                            if(absltIdx-1 >=0) [shortSpecList[absltIdx-1], shortSpecList[absltIdx]] = [shortSpecList[absltIdx], shortSpecList[absltIdx-1]]; 
                            renderListShortSpec(currentPageShortSpec)"/>
                  &nbsp;
                  <i class="fas fa-arrow-down" style="cursor:pointer" 
                    @click="var absltIdx = (currentPageShortSpec-1) * pageSize + index; 
                            if(absltIdx+1 < shortSpecList.length) [shortSpecList[absltIdx+1], shortSpecList[absltIdx]] = [shortSpecList[absltIdx], shortSpecList[absltIdx+1]]; 
                            renderListShortSpec(currentPageShortSpec)"/>
                </div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt.split('T')[0]}}</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set2 fs-9"><i class="fa fa-edit" style="cursor:pointer" @click="editProfile(item.memberId)"/></span></div>
              </div>
            </div>
          </div>

          <!-- 페이징 -->
          <div class="x_div_center py-3" v-if="!fetching">
              <a class="px-2" @click="switchPageShortSpec(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
              <a class="px-2" @click="switchPageShortSpec(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
              <span v-if="pageQueueShortSpec[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
              <a v-for="index in pageQueueShortSpec" :key="index" class="px-2" @click="renderListShortSpec(index);" style="margin-right: 10px"><span :class="'fen-set' + (currentPageShortSpec == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
              <span v-if="pageQueueShortSpec[pageQueueShortSpec.length-1] < numOfPageShortSpec" style="cursor:pointer;" class="fen-set2">...</span>
              <a class="px-2" @click="switchPageShortSpec(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
              <a class="px-2" @click="switchPageShortSpec(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "MemberList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        searchWord: '',
        editingOrder: false,
        fullSpecList: [],
        fullSpecListOrig:[],
        fullSpecListPaged: [],
        pageQueueFullSpec: [],
        currentPageFullSpec: 1,
        numOfPageFullSpec: 0,
        shortSpecList: [],
        shortSpecListOrig:[],
        shortSpecListPaged: [],
        pageQueueShortSpec: [],
        currentPageShortSpec: 1,
        numOfPageShortSpec: 0,
        pageSize: 5
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    await this.fetchMemberProfiles();
    this.setPagingFullSpec();
    this.renderListFullSpec(1);
    this.setPagingShortSpec();
    this.renderListShortSpec(1);
  },
  methods:{
    async fetchMemberProfiles() {
      this.fetching = true;
      const returnvalue = await Api.get('/api/v1/member/list');
      if(returnvalue.result) {
          this.fullSpecListOrig = returnvalue.data.filter((item) => item.thumbnailWebPath != null).sort((a,b) => a.orderInPage - b.orderInPage);
          this.fullSpecList = this.fullSpecListOrig;
          this.shortSpecListOrig = returnvalue.data.filter((item) => item.thumbnailWebPath == null).sort((a,b) => b.orderInPage - a.orderInPage);
          this.shortSpecList = this.shortSpecListOrig;
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    async findProfile(option) {
      if(this.editingOrder) {
        alert('노출순서 변경중에는 검색을 수행할 수 없습니다.');
        return;
      }
      if(option) {
        this.fullSpecList = this.fullSpecListOrig.filter((item) => item.memberName.indexOf(this.searchWord) >= 0 )
        this.setPagingFullSpec();
        this.renderListFullSpec(1);
      }
      else {
        this.shortSpecList = this.shortSpecListOrig.filter((item) => item.memberName.indexOf(this.searchWord) >= 0 )
        this.setPagingShortSpec();
        this.renderListShortSpec(1);
      }
    },

    renderListFullSpec(index) {
      this.currentPageFullSpec = index;
      this.fullSpecListPaged = [];
      for(var j = (this.currentPageFullSpec-1) * this.pageSize; j < (this.fullSpecList.length > this.currentPageFullSpec * this.pageSize? this.currentPageFullSpec * this.pageSize : this.fullSpecList.length); j++) {
        this.fullSpecListPaged.push(this.fullSpecList[j]);
      }
    },

    renderListShortSpec(index) {
      this.currentPageShortSpec = index;
      this.shortSpecListPaged = [];
      for(var j = (this.currentPageShortSpec-1) * this.pageSize; j < (this.shortSpecList.length > this.currentPageShortSpec * this.pageSize? this.currentPageShortSpec * this.pageSize : this.shortSpecList.length); j++) {
        this.shortSpecListPaged.push(this.shortSpecList[j]);
      }
    },

    setPagingFullSpec() {
      this.numOfPageFullSpec = Math.ceil(this.fullSpecList.length / this.pageSize);
      this.pageQueueFullSpec = [];
      if(this.currentPageFullSpec < 11) {
        for(var i = 1; i <= (this.numOfPageFullSpec > 10 ? 10 : this.numOfPageFullSpec) ; i++) {
          this.pageQueueFullSpec.push(i);
        }
      }
      else {
        for(i = this.currentPageFullSpec - 9; i <= this.currentPageFullSpec; i++) {
          this.pageQueueFullSpec.push(i);
        }
      }
    },

    setPagingShortSpec() {
      this.numOfPageShortSpec = Math.ceil(this.shortSpecList.length / this.pageSize);
      this.pageQueueShortSpec = [];
      if(this.currentPageShortSpec < 11) {
        for(var i = 1; i <= (this.numOfPageShortSpec > 10 ? 10 : this.numOfPageShortSpec) ; i++) {
          this.pageQueueShortSpec.push(i);
        }
      }
      else {
        for(i = this.currentPageShortSpec - 9; i <= this.currentPageShortSpec; i++) {
          this.pageQueueShortSpec.push(i);
        }
      }
    },

    async switchPageFullSpec(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPageFullSpec > 1) {
                    this.currentPageFullSpec = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPageFullSpec > 1) {
                    this.currentPageFullSpec--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPageFullSpec < this.numOfPageFullSpec) {
                    this.currentPageFullSpec++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPageFullSpec < this.numOfPageFullSpec) {
                    this.currentPageFullSpec = this.numOfPageFullSpec;
                    render = true;
                  } 
      }
      if(render) {
        this.setPagingFullSpec();
        this.renderListFullSpec(this.currentPageFullSpec);
      }
    },

    async switchPageShortSpec(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPageShortSpec > 1) {
                    this.currentPageShortSpec = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPageShortSpec > 1) {
                    this.currentPageShortSpec--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPageShortSpec < this.numOfPageShortSpec) {
                    this.currentPageShortSpec++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPageShortSpec < this.numOfPageShortSpec) {
                    this.currentPageShortSpec = this.numOfPageShortSpec;
                    render = true;
                  } 
      }
      if(render) {
        this.setPagingShortSpec();
        this.renderListShortSpec(this.currentPageShortSpec);
      }
    },

    async switchPageSize() {
      if(this.editingOrder) {
        alert('노출순서 변경중에는 페이지크기를 변경할 수 없습니다.');
        return;
      }

      this.setPagingFullSpec();
      this.renderListFullSpec(1);
      this.setPagingShortSpec();
      this.renderListShortSpec(1);
    },

    async saveModification(option) {
      if(option) {
        this.fetching = true;
        const returnvalue = await Api.post('/api/v1/member/update/orders', this.fullSpecList.concat(this.shortSpecList));
        if(!returnvalue.result) {
            alert(returnvalue.errormsg);
            this.fullSpecList = this.fullSpecListOrig;
            this.shortSpecList = this.shortSpecListOrig;
        }
        this.fetching = false;
      }
      this.editingOrder = false;
      this.setPagingFullSpec();
      this.renderListFullSpec(this.currentPageFullSpec);
      this.setPagingShortSpec();
      this.renderListShortSpec(this.currentPageShortSpec);
    },

    async editProfile(memberId) {
      location.href = '/admin/site/member-edit/' + memberId;
    }
  }
}
</script>

