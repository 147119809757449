<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">계약서 관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="py-4">

            <div class="x_div_end d-flex">
              <select class="custom-select ff-eng1 fs-085" v-model="searchType" style="width:200px; height:37px; margin-right: 20px">
                <option value="EM">건축주명 또는 이메일 검색</option>
                <option value="PJ">프로젝트명 검색</option>
              </select>
              <div class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" @keydown.enter="findPrjList()" v-model="searchKeyword"  class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem" placeholder="프로젝트 A" ></div>
                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findPrjList()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              </div>
            </div>
            <div class="x_div_end">
              <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; top:235px; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                <div v-for="(item, index) in prjList" :key="index" class="x_div_center ls_bg1">
                  <div class="w-100 text-start py-1 px-2">
                    <i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i><i v-if="!item.prjId" class="fa fa-spinner fa-spin" style="color:#333"></i>
                    <a v-else href="javascript:void(0)" @click="callFindPrjWrttnCntrct(item.prjId)">{{item.prjNm.split(';')[0]}} <br>&nbsp;&nbsp;&nbsp;&nbsp; - {{ item.crrntStts }}</a>
                  </div>
                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-3">

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>프로젝트명</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>건축주</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>사업 파트너</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>설계사</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>제조사</strong></span></td>
                  <td class="text-center br_0" ><span class="ff-kor1" style="font-size:12pt"><strong>시공사</strong></span></td>
                  <td class="text-center" ><span class="ff-kor1" style="font-size:12pt"><strong>인테리어사</strong></span></td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{prjNm ? prjNm.split(';')[0] : ''}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{ownrNm}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" style="font-size:12pt">{{bsnssPrtnrNm}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" :style="'font-size:12pt;' + (dsgnrNm? '' : 'color: lightgrey')">{{dsgnrNm? dsgnrNm : findWrttnDsgnDto.partnerName}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" :style="'font-size:12pt;' + (mnfctrNm? '' : 'color: lightgrey')">{{mnfctrNm? mnfctrNm : findWrttnMnfctDto.partnerName}}</span></td>
                  <td class="text-center br_0" ><span class="ff-kor2" :style="'font-size:12pt;' + (cntrctrNm? '' : 'color: lightgrey')">{{cntrctrNm? cntrctrNm : findWrttnCntrctDto.partnerName}}</span></td>
                  <td class="text-center" ><span class="ff-kor2" :style="'font-size:12pt;' + (intrrcpNm? '' : 'color: lightgrey')">{{intrrcpNm? intrrcpNm : findWrttnIntrrDto.partnerName}}</span></td>
                </tr>
              </table>
            </div>
          </div>
          <div style="height:50px"></div>

          <!-- 설계 -->
          <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
            <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">설계</span></div>
          </div>

          <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" width="25%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
                  <td class="text-center" width="75%">
                    <div class="x_div_center">
                      <span v-if="dsgnCntrctDt" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ dsgnCntrctDt.split('T')[0] }}</span>
                    </div>
                  </td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약금</strong></span></td>
                  <td class="text-center">
                    <div class="x_div_center">
                      <span v-if="dsgnFnlPath" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ Number(dsgnTotalAmounts).toLocaleString() }} 원</span>
                      <input v-else type="text" :disabled="!(findWrttnDsgnDto.partnerName || dsgnrNm)" v-model="dsgnTotalAmountsInput" class="form-control ff-eng1 text-center" style="width:180px; height:36px">
                    </div>
                  </td>
                </tr>

                <tr style="height:55px;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약서</strong></span></td>
                  <td class="text-center">
                    <div v-if="dsgnFnlPath" class="x_div_center">
                      <form method="post" action="/api/v1/common/filedownload">
                        <input type="hidden" name="fileName" :value="dsgnFnlPath" />
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ dsgnFnlOrgNm }} </span></a>
                      </form>
                    </div>
                    <div v-else class="x_div_center"><input type="file" :disabled="!(findWrttnDsgnDto.partnerName || dsgnrNm)" @change="fileHandling($event, 0)" class="form-control ff-eng1 text-center" style="width:240px; height:36px"></div>
                  </td>
                </tr>

              </table>
            </div>

          </div>

          <div style="height:20px"></div>

          <!-- 제조 -->
          <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
            <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">제조</span></div>
          </div>

          <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" width="25%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
                  <td class="text-center" width="75%">
                    <div class="x_div_center">
                      <span v-if="mnfctCntrctDt" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ mnfctCntrctDt.split('T')[0] }}</span>
                    </div>
                  </td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약금</strong></span></td>
                  <td class="text-center">
                    <div class="x_div_center">
                      <span v-if="mnfctFnlPath" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ Number(mnfctTotalAmounts).toLocaleString() }} 원</span>
                      <input v-else type="text" :disabled="!(findWrttnMnfctDto.partnerName || mnfctrNm)" v-model="mnfctTotalAmountsInput" class="form-control ff-eng1 text-center" style="width:180px; height:36px">
                    </div>
                  </td>
                </tr>

                <tr style="height:55px;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약서</strong></span></td>
                  <td class="text-center">
                    <div v-if="mnfctFnlPath" class="x_div_center">
                      <form method="post" action="/api/v1/common/filedownload">
                        <input type="hidden" name="fileName" :value="mnfctFnlPath" />
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ mnfctFnlOrgNm }} </span></a>
                      </form>
                    </div>
                    <div v-else class="x_div_center"><input type="file" :disabled="!(findWrttnMnfctDto.partnerName || mnfctrNm)" @change="fileHandling($event, 1)" class="form-control ff-eng1 text-center" style="width:240px; height:36px"></div>
                  </td>
                </tr>

              </table>
            </div>

          </div>

          <div style="height:20px"></div>

          <!-- 시공 -->
          <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
            <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">시공</span></div>
          </div>

          <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" width="25%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
                  <td class="text-center" width="75%">
                    <div class="x_div_center">
                      <span v-if="cntrctCntrctDt" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ cntrctCntrctDt.split('T')[0] }}</span>
                    </div>
                  </td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약금</strong></span></td>
                  <td class="text-center">
                    <div class="x_div_center">
                      <span v-if="cntrctFnlPath" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ Number(cntrctTotalAmounts).toLocaleString() }} 원</span>
                      <input v-else type="text" :disabled="!(findWrttnCntrctDto.partnerName || cntrctrNm)" v-model="cntrctTotalAmountsInput" class="form-control ff-eng1 text-center" style="width:180px; height:36px">
                    </div>
                  </td>
                </tr>

                <tr style="height:55px;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약서</strong></span></td>
                  <td class="text-center">
                    <div v-if="cntrctFnlPath" class="x_div_center">
                      <form method="post" action="/api/v1/common/filedownload">
                        <input type="hidden" name="fileName" :value="cntrctFnlPath" />
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ cntrctFnlOrgNm }} </span></a>
                      </form>
                    </div>
                    <div v-else class="x_div_center"><input type="file" :disabled="!(findWrttnCntrctDto.partnerName || cntrctrNm)" @change="fileHandling($event, 2)" class="form-control ff-eng1 text-center" style="width:240px; height:36px"></div>
                  </td>
                </tr>

              </table>
            </div>

          </div>

          <div style="height:20px"></div>

          <!-- 인테리어 -->
          <div class="d-flex" style="border-bottom:1px solid #d9d9d9">
            <div class="px-2 py-2"><span class="fa-Kor-Noto" style="font-weight:700; font-size:16pt; color:#333333">인테리어</span></div>
          </div>

          <div class="pg_m_set" style="margin-top: 20px" v-if="fetching">
						<div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
					</div>

          <div class="pt-3" v-else>

            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
              <table class="table-borderless w-100">

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" width="25%" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약일</strong></span></td>
                  <td class="text-center" width="75%">
                    <div class="x_div_center">
                      <span v-if="intrrCntrctDt" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ intrrCntrctDt.split('T')[0] }}</span>
                    </div>
                  </td>
                </tr>

                <tr style="height:55px; border-bottom:1px solid #c9c9c9;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약금</strong></span></td>
                  <td class="text-center">
                    <div class="x_div_center">
                      <span v-if="intrrFnlPath" class="fa-kor-Noto fw-bold" style="font-size:13pt">{{ Number(intrrTotalAmounts).toLocaleString() }} 원</span>
                      <input v-else type="text" :disabled="!(findWrttnIntrrDto.partnerName || intrrcpNm)" v-model="intrrTotalAmountsInput" class="form-control ff-eng1 text-center" style="width:180px; height:36px">
                    </div>
                  </td>
                </tr>

                <tr style="height:55px;">
                  <td class="text-center br_0" style="background-color:#eaeaea"><span class="ff-kor1" style="font-size:12pt"><strong>계약서</strong></span></td>
                  <td class="text-center">
                    <div v-if="intrrFnlPath" class="x_div_center">
                      <form method="post" action="/api/v1/common/filedownload">
                        <input type="hidden" name="fileName" :value="intrrFnlPath" />
                        <a href="javascript:void(0)" onclick="window.$(this).parent().submit()"><span class="fen-set1 fc-w6"> {{ intrrFnlOrgNm }} </span></a>
                      </form>
                    </div>
                    <div v-else class="x_div_center"><input type="file" :disabled="!(findWrttnIntrrDto.partnerName || intrrcpNm)" @change="fileHandling($event, 3)" class="form-control ff-eng1 text-center" style="width:240px; height:36px"></div>
                  </td>
                </tr>

              </table>
            </div>

          </div>

          <div style="height:50px"></div>

          <div class="x_div_end">
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveWrttnCntrct()" style="width:150px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">저장</button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from "@/api";

export default {
  name: "WrttnCntrctMng",
  components:{
    AdminLNB,
  },
  data(){
    return {
      isSearch:false,
      prjList:[],
      searchKeyword : '', // 검색 키워드
      searchType:'PJ',
      dsgnTotalAmounts:0,
      dsgnTotalAmountsInput:'',
      dsgnCntrctDt:'',
      selectDsgnCntrctDt:'',
      dsgnFnlPath:'',
      dsgnFnlOrgNm:'',
      mnfctTotalAmounts:0,
      mnfctTotalAmountsInput:'',
      mnfctCntrctDt:'',
      selectMnfctCntrctDt:'',
      mnfctFnlPath:'',
      mnfctFnlOrgNm:'',
      cntrctTotalAmounts:0,
      cntrctTotalAmountsInput:'',
      cntrctCntrctDt:'',
      selectCntrctCntrctDt:'',
      cntrctFnlPath:'',
      cntrctFnlOrgNm:'',
      intrrTotalAmounts:0,
      intrrTotalAmountsInput:'',
      intrrCntrctDt:'',
      selectIntrrCntrctDt:'',
      intrrFnlPath:'',
      intrrFnlOrgNm:'',
      findWrttnDsgnDto : { fnlPath : '', fnlOrgNm : '', cntrctDt : null, totalAmounts : null, partnerName: null, partnerId: null, prcId: null, prcCd: null, bddngRqst: '' },
      findWrttnMnfctDto:{ fnlPath : '', fnlOrgNm : '', cntrctDt : null, totalAmounts : null, partnerName: null, partnerId: null, prcId: null, prcCd: null, bddngRqst: '' },
      findWrttnCntrctDto:{ fnlPath : '', fnlOrgNm : '', cntrctDt : null, totalAmounts : null, partnerName: null, partnerId: null, prcId: null, prcCd: null, bddngRqst: '' },
      findWrttnIntrrDto: { fnlPath : '', fnlOrgNm : '', cntrctDt : null, totalAmounts : null, partnerName: null, partnerId: null, prcId: null, prcCd: null, bddngRqst: '' },
      prjId:0,
      dsgnFnlAttchmnt:null,
      mnfctFnlAttchmnt:null,
      cntrctFnlAttchmnt:null,
      intrrFnlAttchmnt:null,
      fetching: true,
      prjNm:'',
      ownrNm:'',
      bsnssPrtnrNm:'',
      dsgnrNm:'',
      mnfctrNm:'',
      cntrctrNm:'',
      intrrcpNm:'',
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }
    //await this.checkAdmin();
    this.fetching = false;
    var searchedPrjId = sessionStorage.getItem('searchedPrjId');
    if(searchedPrjId) {
      sessionStorage.removeItem('searchedPrjId');
      await this.findPrjWrttnCntrct(searchedPrjId);
    }
  },

  methods:{

    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    fileHandling(e, index) {
      if(index == 0) {
        this.dsgnFnlAttchmnt = e.target.files[0];
        this.dsgnFnlOrgNm = e.target.files[0].name;
      }else if(index == 1) {
        this.mnfctFnlAttchmnt = e.target.files[0];
        this.mnfctFnlOrgNm = e.target.files[0].name;
      }else if(index == 2) {
        this.cntrctFnlAttchmnt = e.target.files[0];
        this.cntrctFnlOrgNm = e.target.files[0].name;
      }else if(index == 3) {
        this.intrrFnlAttchmnt = e.target.files[0];
        this.intrrFnlOrgNm = e.target.files[0].name;
      }

    },

    // bindDsgnTotalAmounts(event){
    //   this.dsgnTotalAmounts = event.target.value;
    // },

    // bindMnfctTotalAmounts(event){
    //   this.mnfctTotalAmounts = event.target.value;
    // },

    // bindCntrctTotalAmounts(event){
    //   this.cntrctTotalAmounts = event.target.value;
    // },

    // bindIntrrTotalAmounts(event){
    //   this.intrrTotalAmounts = event.target.value;
    // },

    async findPrjList(){

      if(!this.searchKeyword){
        alert('프로젝트 검색어를 입력해주세요.');
        return;
      }

      this.isSearch = true;
      this.prjList = [{prjId:null}];
      const rtnval = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: false})
      if(rtnval.result) {
          this.prjList = rtnval.data.findPrjDtoList.sort((a, b) => {
                  return Number(Boolean(b.lastUpdatedPrcInfo.totalAmounts)) - Number(Boolean(a.lastUpdatedPrcInfo.totalAmounts))
                });
          const rtnvalue = await Api.post('/api/v1/project/find/prj/list', {searchType: this.searchType, searchWord: this.searchKeyword, completionYn: true})
          if(rtnvalue.result) {
            this.prjList = this.prjList.concat(rtnvalue.data.findPrjDtoList);
          }
      }
    },

    async findPrjWrttnCntrct(prjId){
      this.prjId = prjId;
      this.fetching = true;
      let returnvalue = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + prjId);
      if(returnvalue.result) {
        this.prjNm = returnvalue.data.prj.prjNm;
        this.bsnssPrtnrNm = returnvalue.data.prj.bsnssPrtnrEmail;
      }
      else {
        alert(returnvalue.errormsg)
      }

      let rtnval = await Api.get('/api/v1/admin/wrttn/cntrct/mng/find/wrttn/cntrct/list?prjId=' + prjId);
      if(rtnval.result) {
        rtnval.data.findWrttnList.forEach(async (item) => {
          this.fetching = true;
          let rtnvalue = await Api.post('/api/v1/process/get', {prjId: prjId, prcCd: item.prcCd});
          if(rtnvalue.result) {
            this.ownrNm = rtnvalue.data.ownrInfo.fullName;
          }

          switch(item.prcCd) {
            case 'DS' :
              this.findWrttnDsgnDto = item;
              if(this.findWrttnDsgnDto.cntrctDt){
                this.dsgnCntrctDt = this.findWrttnDsgnDto.cntrctDt.substr(0, 10);
                this.selectDsgnCntrctDt = this.findWrttnDsgnDto.cntrctDt;
              }else{
                this.dsgnCntrctDt = '';
                this.selectDsgnCntrctDt = '';
              }
              if(this.findWrttnDsgnDto.totalAmounts){
                this.dsgnTotalAmounts = (this.findWrttnDsgnDto.totalAmounts);
                this.dsgnTotalAmountsInput = String(this.dsgnTotalAmounts);
              }else{
                this.dsgnTotalAmounts = 0;
              }
              this.dsgnFnlPath = this.findWrttnDsgnDto.fnlPath;
              this.dsgnFnlOrgNm = this.findWrttnDsgnDto.fnlOrgNm;
              this.dsgnrNm = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
              break;

            case 'MA' :
              this.findWrttnMnfctDto = item;
              if(this.findWrttnMnfctDto.cntrctDt){
                this.mnfctCntrctDt = this.findWrttnMnfctDto.cntrctDt.substr(0, 10);
                this.selectMnfctCntrctDt = this.findWrttnMnfctDto.cntrctDt;
              }else{
                this.mnfctCntrctDt = '';
                this.selectMnfctCntrctDt = '';
              }
              if(this.findWrttnMnfctDto.totalAmounts) {
                this.mnfctTotalAmounts = (this.findWrttnMnfctDto.totalAmounts);
                this.mnfctTotalAmountsInput = String(this.mnfctTotalAmounts);
              }else{
                this.mnfctTotalAmounts = 0;
              }
              this.mnfctFnlPath = (this.findWrttnMnfctDto.fnlPath);
              this.mnfctFnlOrgNm = (this.findWrttnMnfctDto.fnlOrgNm);              
              this.mnfctrNm = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
              break;

            case 'CN' :
              this.findWrttnCntrctDto = item;
              if(this.findWrttnCntrctDto.cntrctDt){
                this.cntrctCntrctDt = this.findWrttnCntrctDto.cntrctDt.substr(0, 10);
                this.selectCntrctCntrctDt = this.findWrttnCntrctDto.cntrctDt;
              }else{
                this.cntrctCntrctDt = '';
                this.selectCntrctCntrctDt = '';
              }
              if(this.findWrttnCntrctDto.totalAmounts) {
                this.cntrctTotalAmounts = (this.findWrttnCntrctDto.totalAmounts);
                this.cntrctTotalAmountsInput = String(this.cntrctTotalAmounts);
              }else{
                this.cntrctTotalAmounts = 0;
              }
              this.cntrctFnlPath = (this.findWrttnCntrctDto.fnlPath);
              this.cntrctFnlOrgNm = (this.findWrttnCntrctDto.fnlOrgNm);              
              this.cntrctrNm = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
              break;

            case 'IN' :
              this.findWrttnIntrrDto = item;
              if(this.findWrttnIntrrDto.cntrctDt){
                this.intrrCntrctDt = this.findWrttnIntrrDto.cntrctDt.substr(0, 10);
                this.selectIntrrCntrctDt = this.findWrttnIntrrDto.cntrctDt;
              }else{
                this.intrrCntrctDt = '';
                this.selectIntrrCntrctDt = '';
              }
              if(this.findWrttnIntrrDto.totalAmounts) {
                this.intrrTotalAmounts = (this.findWrttnIntrrDto.totalAmounts);
                this.intrrTotalAmountsInput = String(this.intrrTotalAmounts);
              }else{
                this.intrrTotalAmounts = 0;
              }
              this.intrrFnlPath = (this.findWrttnIntrrDto.fnlPath);
              this.intrrFnlOrgNm = (this.findWrttnIntrrDto.fnlOrgNm);
              this.intrrcpNm = rtnvalue.data.prtnrInfo? rtnvalue.data.prtnrInfo.fullName : '';
              break;
          }
          this.fetching = false;
        })
      }
      else {
        alert(rtnval.errormsg)
        this.fetching = false;
      }

      this.isSearch = false;
    },

    callFindPrjWrttnCntrct(prjId) {
      sessionStorage.setItem('searchedPrjId', prjId);
      location.reload()
    },

    async saveWrttnCntrct(){

      var setPrcRqst = [];
      
      var request = {
        parentId: [],
        dirNmList: [],
        attchOrgNmList: [],
        attchFlCtgry: []
      }

      const formData = new FormData();

      if(this.dsgnFnlAttchmnt) {
        request.parentId.push(this.findWrttnDsgnDto.prcId)
        request.dirNmList.push('platform/contract/' + this.findWrttnDsgnDto.prcCd + '/fnl')
        request.attchOrgNmList.push(this.dsgnFnlOrgNm)
        request.attchFlCtgry.push('CT')

        formData.append("attchmntList", this.dsgnFnlAttchmnt);

        setPrcRqst.push({
          prcId: this.findWrttnDsgnDto.prcId,
          bddngRqst: this.findWrttnDsgnDto.bddngRqst,
          prtnrId: this.findWrttnDsgnDto.partnerId,
          totalAmounts: Number(this.dsgnTotalAmountsInput.replace(/[^\d]+/g, '')),
          startBid: false,
          bddngTyp: 0
        })
      }

      if(this.mnfctFnlAttchmnt) {
        request.parentId.push(this.findWrttnMnfctDto.prcId)
        request.dirNmList.push('platform/contract/' + this.findWrttnMnfctDto.prcCd + '/fnl')
        request.attchOrgNmList.push(this.mnfctFnlOrgNm)
        request.attchFlCtgry.push('CT')

        formData.append("attchmntList", this.mnfctFnlAttchmnt);

        setPrcRqst.push({
          prcId: this.findWrttnMnfctDto.prcId,
          bddngRqst: this.findWrttnMnfctDto.bddngRqst,
          prtnrId: this.findWrttnMnfctDto.partnerId,
          totalAmounts: Number(this.mnfctTotalAmountsInput.replace(/[^\d]+/g, '')),
          startBid: false,
          bddngTyp: 0
        })
      }

      if(this.cntrctFnlAttchmnt) {
        request.parentId.push(this.findWrttnCntrctDto.prcId)
        request.dirNmList.push('platform/contract/' + this.findWrttnCntrctDto.prcCd + '/fnl')
        request.attchOrgNmList.push(this.cntrctFnlOrgNm)
        request.attchFlCtgry.push('CT')

        formData.append("attchmntList", this.cntrctFnlAttchmnt);

        setPrcRqst.push({
          prcId: this.findWrttnCntrctDto.prcId,
          bddngRqst: this.findWrttnCntrctDto.bddngRqst,
          prtnrId: this.findWrttnCntrctDto.partnerId,
          totalAmounts: Number(this.cntrctTotalAmountsInput.replace(/[^\d]+/g, '')),
          startBid: false,
          bddngTyp: 0
        })
      }

      if(this.intrrFnlAttchmnt) {
        request.parentId.push(this.findWrttnIntrrDto.prcId)
        request.dirNmList.push('platform/contract/' + this.findWrttnIntrrDto.prcCd + '/fnl')
        request.attchOrgNmList.push(this.intrrFnlOrgNm)
        request.attchFlCtgry.push('CT')

        formData.append("attchmntList", this.intrrFnlAttchmnt);

        setPrcRqst.push({
          prcId: this.findWrttnIntrrDto.prcId,
          bddngRqst: this.findWrttnIntrrDto.bddngRqst,
          prtnrId: this.findWrttnIntrrDto.partnerId,
          totalAmounts: Number(this.intrrTotalAmountsInput.replace(/[^\d]+/g, '')),
          startBid: false,
          bddngTyp: 0
        })
      }

      if(request.parentId.length == 0) {
        alert('저장할 첨부 파일이 없습니다.');
        return;
      }

      formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );

			var rtnval = null;
			rtnval = await Api.post('/api/v1/common/set/attachments', formData);
			if(rtnval.result) {
        setPrcRqst.forEach(async (rqst) => {
          var rtnvalue = await Api.post('/api/v1/process/set', rqst);
          if(!rtnvalue.result) {
            alert(rtnvalue.errormsg)
          }
        })
        this.findPrjWrttnCntrct(this.prjId);
			}
			else {
				alert(rtnval.errormsg);
			}
    }
  },

  watch:{
    dsgnTotalAmountsInput(val){
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(val)!==null) val = val.replace(/[^0-9]/g,'');
      this.dsgnTotalAmountsInput = val.replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(val))) this.dsgnTotalAmountsInput = '';
    },

    mnfctTotalAmountsInput(val){
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(val)!==null) val = val.replace(/[^0-9]/g,'');
      this.mnfctTotalAmountsInput = val.replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(val))) this.mnfctTotalAmountsInput = '';
    },

    cntrctTotalAmountsInput(val){
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(val)!==null) val = val.replace(/[^0-9]/g,'');
      this.cntrctTotalAmountsInput = val.replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(val))) this.cntrctTotalAmountsInput = '';
    },

    intrrTotalAmountsInput(val){
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;

      // 한글, 영문 체크
      if(reg.exec(val)!==null) val = val.replace(/[^0-9]/g,'');
      this.intrrTotalAmountsInput = val.replace(/[^\d]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      // .... 만 입력하게 될 경우 체크
      if(isNaN(parseFloat(val))) this.intrrTotalAmountsInput = '';
    },
  }
}
</script>

<style scoped>

</style>