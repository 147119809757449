<template>
    <div id="modalForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">

                <div class="w-100">
                    <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                        <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13">{{ attchType }} 등록</span></div>
                        <div class="text-end pe-4" style="width:100px"><a href="#@" style="color:#4e0000" @click="closeModal()" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                    </div>

                    <div class="x_div_center py-2">
                        <div class="x_div_center px-4 w-100" style="display:block !important">

                            <div class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                <table class="table-borderless w-100">
                                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                    <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>{{ attchCtgry }} 파일 (회사명 반드시 제거)</strong></span></td>
                                </tr>
                                <tr style="height:55px;">
                                    <td class="text-center br_0">
                                        <div class="x_div_center" v-if="isModifiy && fileToDisplay.orgNm">
                                            <div class="x_div_center"><a :href="fileToDisplay.fileLink" :download="fileToDisplay.orgNm"><span class="fkr-set2">{{fileToDisplay.orgNm}}</span></a></div>
                                            <div class="x_div_center px-2"><a href="javascript:void(0)" @click="removeFile()" class="rm_frm"><i class="fa-solid fa-circle-minus fs-10" style="color:#C00"></i></a></div>
                                        </div>
                                        <div class="x_div_center" v-else><input type="file" id="file0" @change="fileHandling" class="form-control" style="width:90%; color:#4e0000"></div>
                                    </td>
                                </tr>
                                </table>
                            </div>

                            <div style="height:10px"></div>

                            <div v-if="proposalComments.length" class="table-responsive" style="border:1px solid #c9c9c9; border-radius:8px">
                                <table class="table-borderless w-100">
                                <tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
                                    <td class="text-center br_0" width="50%"><span class="fkr-set1"><strong>심사내용</strong></span></td>
                                </tr>
                                <tr v-for="(item, index) in proposalComments" :key="index">
                                    <td class="br_0" style="border-bottom: 1px solid rgb(201, 201, 201); padding: 10px" v-html="item"></td>
                                </tr>
                                <tr style="height:55px;">
                                    <td class="text-center br_0">
                                        <div class="x_div_center"><span class="fkr-set2">문의사항은 support@m3.co.kr 또는 {{companyInfo? companyInfo.phoneNumber : ''}} 으로 <br v-if="isMobile">연락부탁드립니다.</span></div>
                                    </td>
                                </tr>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div class="x_div_center py-4">
                        <div class="px-2"><button class="x_div_center" @click="closeModal()" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set2">닫기</span></button></div>
                        <div class="px-2"><button class="x_div_center" @click="submitAttch()" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">등록<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                    </div>

                    <div style="height:20px"></div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'BiddingAttchFlUploaderSingle',
    data() {
        return {
            attchType: this.step == 2? '제안' : '영수증',
            attchCtgry: this.step == 3? '영수증' : this.prcCd == 'DS'? '제안서' : '내역서',
            fileToDisplay: {
				bddngId: null,
				fildId: null,
				orgNm: null,
				fileLink: null
			},
            proposalComments: [],
			file: null,
			fileName: '',
			isModifiy: false,
            submitting: false
        }
    },
    props: {
        isMobile: {
            type: Boolean,
            default: false
        },
        companyInfo: {
            type: Object,
            default: null
        },
        prcCd: {
            type: String,
            default: 'DS'
        },
        step: {
            type: Number,
            default: 2
        }
    },
    async created() {
    },
    methods: {
        renderPrjList(prjList) {
            var renderList = [];
			prjList.forEach((prj) => {
				if(prj.nominatedDt) {
					prj.fileLink = null;
                    const fileNm = this.step == 2? prj.proposalNm : prj.dsgnRcptNm;
                    const fileData = this.step == 2? prj.proposalData : prj.dsgnRcptData;
					if(fileNm){
						var tokens = fileNm.split('.');
						var mimeType = tokens[tokens.length-1].toLowerCase();
						var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
	
						const byteCharacters = atob(fileData);
						const byteNumbers = new Array(byteCharacters.length);
						for (let i = 0; i < byteCharacters.length; i++) {
							byteNumbers[i] = byteCharacters.charCodeAt(i);
						}
						const byteArray = new Uint8Array(byteNumbers);
						const blob = new Blob([byteArray], {type: fileType});
						prj.fileLink = URL.createObjectURL(blob);
					}
					renderList.push(prj);		
				}
			})
            this.$emit('prjList', renderList);
        },
        showFileUploadForm(prj, editmode) {
			this.fileToDisplay.bddngId = prj.bddngId;
			this.fileToDisplay.fildId = this.step == 2? prj.proposalFlId : prj.dsgnRcptFlId;
			this.fileToDisplay.orgNm = this.step == 2? prj.proposalNm : prj.dsgnRcptNm;
			this.fileToDisplay.fileLink = prj.fileLink;
            this.proposalComments = prj.proposalComment? (prj.proposalComment.indexOf(';') >= 0? prj.proposalComment.split(';') : [prj.proposalComment]) : [];
			this.file = null;
			this.fileName = '';
			this.isModifiy = editmode;
			window.$('#file0').val('');
			window.$('#modalForm').modal('show');
		},
		removeFile() {
			this.fileToDisplay.orgNm = null;
			this.fileToDisplay.fileLink = null;
		},
		fileHandling(e) {
            var tokens = e.target.files[0].name.split('.');
            var fileType = tokens[tokens.length-1].toLowerCase();
            var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
            if(allowed.indexOf(fileType) < 0){
                alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                window.$('#file0').val('')
                return 1;
            }

            this.file = e.target.files[0];
			this.fileName = e.target.files[0].name;
        },
		async submitAttch() {
			var request = {
				parentId: [this.fileToDisplay.bddngId],
				deleteFlIdList: this.isModifiy && this.step != 2? [this.fileToDisplay.fildId] : null,
				attchOrgNmList: [this.fileName],
				attchFlCtgry: [this.step == 2? 'PP' : 'RC']
			}

			const formData = new FormData();
            if(this.file) {
                formData.append("attchmntList", this.file);
            }
            formData.append(
                "request",
                new Blob([JSON.stringify(request)], { type: "application/json" })
            );

			var rtnval = null;
			this.submitting = true;
			rtnval = await Api.post('/api/v1/common/set/attachments', formData);
			this.submitting = false;
			if(rtnval.result) {
				alert(this.attchType + ' 등록이 정상적으로 처리되었습니다.' + (this.step == 2? '\n등록하신 ' + this.attchType + '은 관리자의 심사 후 건축주에게 공개됩니다.' : ''))
                this.$emit('fileUploaded');
				this.closeModal();
			}
			else {
				alert(rtnval.errormsg);
			}
		},
        closeModal() {
			window.$('#modalForm').modal('hide');
		}
    }
}
</script>