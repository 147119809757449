<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>파트너목록</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">{{ currentCtgry }} 수: {{ partnerList.length }} 기업</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="switchCtgry()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in ctgryList" :key="i" :value="item.id">{{ item.ctgryName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="addCtgry()" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="editCtgry()" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeCtgry()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div class="ps-2" :style="'margin-left: auto; padding-left: ' + (ctgryFilter? '10px' : '150px') + ' !important;'"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/partnership-setcategory'" style="width:90px; height:35px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">담당자 관리</button></div>
            <div class="ps-2" style="margin-left: auto; padding-left: 10px !important;"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/partnership-edit/0'" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">파트너기관 등록</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findPartner()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findPartner()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="파트너기관검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">파트너기관명</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">파트너기관명(영문)</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">홈페이지</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">분류</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in partnerListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.companyName}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.companyNameEn}}</span></div>
                  <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9"><a :href="item.hmpg" target="_blank">{{item.hmpg}}</a></span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.ctgry.ctgryName}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a :href="'/admin/site/partnership-edit/' + item.id"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "PartnershipList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        searchWord: '',
        currentCtgry: '전체 파트너기관',
        ctgryList: [],
        partnerList:[],
        partnerListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        ctgryFilter: ''
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    //await this.checkAdmin();

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    const rtnval = await Api.get('/api/v1/partners/ctgry/list');
    if(rtnval.result) {
        this.ctgryList = rtnval.data;
        this.ctgryList = [{id: '', ctgryName: '전체', ctgryNameEn: 'All', crtDt: null}].concat(this.ctgryList);
        await this.fetchPartners(null, null);
        this.setPaging();
        this.renderList();
    }
    else {
        alert(rtnval.errormsg);
    }
  },
  methods:{
    async checkAdmin(){
      var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
      if(rtnval.result){
        let usrId = rtnval.data;

        rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
        if(rtnval.result){
          let adminYn = rtnval.data.adminYn;
          if(!adminYn || adminYn == 'E'){
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
          }
        }
      }
    },

    async fetchPartners(ctgryId, partnerName) {
      this.fetching = true;
      this.partnerList = [];
      const returnvalue = await Api.post('/api/v1/partners/partner/list', {asc: false, ctgryId: ctgryId, partnerName: partnerName});
      if(returnvalue.result) {
          this.partnerList = returnvalue.data;
      }
      else {
          alert(returnvalue.errormsg);
      }
      this.fetching = false;
    },

    async findPartner() {
      await this.fetchPartners(null, this.searchWord);
      this.setPaging();
      this.renderList();
    },

    renderList() {
      this.partnerListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.partnerList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.partnerList.length); j++) {
        this.partnerListPaged.push(this.partnerList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.partnerList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    async switchCtgry() {
      this.currentPage = 1;
      if(this.ctgryFilter) {
        this.ctgryList.forEach((ctgry) => {
          if(ctgry.id == this.ctgryFilter) {
            this.currentCtgry = ctgry.ctgryName;
          }
        })
      }
      else {
        this.currentCtgry = '전체 파트너기관';
      }

      await this.fetchPartners(this.ctgryFilter, null);
      this.setPaging();
      this.renderList();
    },

    async addCtgry() {
      var ctgryName = prompt('등록할 카테고리명을 입력하세요.');
      var ctgryNameEn = prompt('등록할 카테고리명(영문)을 입력하세요.');
      if(!ctgryName || !ctgryNameEn) {
        alert('카테고리명 및 카테고리 영문명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/partners/ctgry/create', {
        ctgryName: ctgryName,
        ctgryNameEn: ctgryNameEn
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async editCtgry() {
      var ctgryName = prompt('변경할 카테고리명을 입력하세요.');
      var ctgryNameEn = prompt('변경할 카테고리명(영문)을 입력하세요.');
      if(!ctgryName || !ctgryNameEn) {
        alert('카테고리명 및 카테고리 영문명은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/partners/ctgry/update', {
        ctgryName: ctgryName,
        ctgryNameEn: ctgryNameEn,
        id: this.ctgryFilter
      })
      if(rtnvalue.result) {
        alert('정상적으로 수정되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeCtgry() {
      if(this.partnerList.length > 0) {
        alert('해당 카테고리에 속한 파트너기관 정보가 존재합니다.\n카테고리를 삭제할 수 없습니다.')
        return;
      }

      const rtnvalue = await Api.get('/api/v1/partners/ctgry/delete?ctgryId=' + this.ctgryFilter)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    }
  }
}
</script>

