<template>
    <div class="m3_contetns" style="margin-top: 50px">
        <div class="mib_mod">
            <div class="w-100">
                <div v-if="!fetching" class="x_div_center pb-3" style="text-align: justify; display: block !important;">
                    <select class="custom-select ff-eng1 fs-085" v-model="ctgry" @change="switchCtgry()" style="width:120px; height:27px; padding: 1px 0 1px 10px; margin:0x 10px 0 0">
                        <option value="0">전체</option>
                        <option value="1">내 판매내역</option>
                        <option value="2">구매요청내역</option>
                    </select>
                    <span class="fkr-set2 fs-10" style="position:relative; float: right; padding-right: 10px;"><button onclick="location.href = '/usdmtrl/edit'" class="fkr-set1" style="width:90px; height:30px; background-color:#000; border:0; border-radius:4px; color:#fff;">매물 등록</button></span>
                    <!-- <div class="x_div_center" style="position:relative; float: right; bottom:-35px; width:170px; height:34px; border:1px solid #d6d6d6; border-radius:4px;">
                        <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="searchPrj()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                        <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="searchPrj()" style="border:0; width:100px; height:30px; padding:0 .3rem; opacity: 0.7;"></div>
                    </div> -->
                </div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else class="pid_2">
                    <div class="mib_box_c">
                        <div class="x_div_end" v-if="isMobile"><span class="fkr-set2 fs-085" style="margin-bottom: 5px;">※ 금액은 1000원 단위입니다.</span></div>
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color: #000;">
                            <div class="x_div_center" :style="'width:' + (isMobile? '15%' : '10%')"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" v-html="'위치'"></span></div>
                            <div class="x_div_center" :style="'width:' + (isMobile? '15%' : '5%')"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(1)" v-html="'개수' + (sortOption[1] == 0? sortMark : (sortOption[1] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" style="width:20%" v-if="!isMobile"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(2)" v-html="'총 사용기간' + (sortOption[2] == 0? sortMark : (sortOption[2] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" :style="'width:' + (isMobile? '25%' : '15%')"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(3)" v-html="'인계희망일자' + (sortOption[3] == 0? sortMark : (sortOption[3] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" style="width:20%" v-if="!isMobile"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(4)" v-html="'게시일자' + (sortOption[4] == 0? sortMark : (sortOption[4] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" :style="'width:' + (isMobile? '25%' : '15%')"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(5)" v-html="'총 가격' + (sortOption[5] == 0? sortMark : (sortOption[5] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" :style="'width:' + (isMobile? '20%' : '15%')"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(6)" v-html="'상태' + (sortOption[6] == 0? sortMark : (sortOption[6] == 1? sortDown : sortUp))"></span></div>
                        </div>
                        
                        <div class="x_div_center ls_bg1" :style="'height:45px; cursor: pointer;' + store.color" v-for="(store, index) in storeList" :key="index" @click="moveToStore(store.usdMtrlId)">
                            <div class="x_div_center px-1" :style="'width:' + (isMobile? '15%' : '10%')"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ store.addr }} </span></div>
                            <div class="x_div_center px-1" :style="'width:' + (isMobile? '15%' : '5%')"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ store.totalAmnt }} </span></div>
                            <div class="x_div_center px-1" style="width:20%" v-if="!isMobile"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ store.period }}년 </span></div>
                            <div class="x_div_center px-1" :style="'width:' + (isMobile? '25%' : '15%')"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ renderDateTimeFormat(store.handoverDt) }} </span></div>
                            <div class="x_div_center px-1" style="width:20%" v-if="!isMobile"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ renderDateTimeFormat(store.postDt) }} </span></div>
                            <div class="x_div_center px-1" :style="'width:' + (isMobile? '25%' : '15%')"><span class="fkr-set2 fs-085" style="text-align: center;"> {{  isMobile? parseInt(store.price/1000).toLocaleString() : store.price.toLocaleString() }} </span></div>
                            <div class="x_div_center px-1" :style="'width:' + (isMobile? '20%' : '15%')"><span class="fkr-set2 fs-085" style="text-align: center;" v-html="store.status"></span></div>
                        </div>

                        <div class="x_div_center py-2" style="margin-top:5px">
                            <a class="px-2 fs-10" @click="switchPage(-1)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angles-left"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(0)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angle-left"></i></a>
                            <a v-show="!isMobile" v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; saveSessionData(); fetchData();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                            <span v-if="!isMobile && pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                            <a class="px-2 fs-10" @click="switchPage(1)" style="cursor:pointer; margin-right: 20px; margin-left: 20px;"><i class="fa-solid fa-angle-right"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(2)" style="cursor:pointer;"><i class="fa-solid fa-angles-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'MtrlList',
    data() {
        return {
            fetching: true,
            isMobile: false,
            numOfStore: 0,
            storeList: [],
            sortOption: [0,0,0,0,0,0,0],
            pageQueue: [],
            currentPage: 1,
            numOfPage: 0,
            pageSize: 10,
            ctgry: 0,
            searchWord: null,
            sortMark: '<i class="fas fa-sort" style="color:#795c5c; margin-left:5px"></i>',
            sortUp: '<i class="fas fa-sort-up" style="margin-left:5px"></i>',
            sortDown: '<i class="fas fa-sort-down" style="margin-left:5px"></i>',
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    async mounted() {

        this.isMobile = window.innerWidth < 768;

        if(sessionStorage.getItem('ctgry')) {
            this.ctgry = parseInt(sessionStorage.getItem('ctgry'));
            this.pageSize = parseInt(sessionStorage.getItem('pageSize'));
            this.currentPage = parseInt(sessionStorage.getItem('currentPage'));
            this.searchWord = sessionStorage.getItem('searchWord') == 'null'? null : sessionStorage.getItem('searchWord');
            var sortOption = sessionStorage.getItem('sortOption').split(',');
            for(var i=0; i<4; i++) {
                this.sortOption[i] = parseInt(sortOption[i]);
            }
        }

        await this.fetchData();
        this.fetching = false;

        this.setPaging();
    },
    methods: {
        async fetchData() {
            this.storeList = [];
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/usdmtrl/list/usdmtrl', {
                sortColumn: this.sortOption.indexOf(1) >0? this.sortOption.indexOf(1) : (this.sortOption.indexOf(2) >0? this.sortOption.indexOf(2) : 0),
                asc: this.sortOption.indexOf(1) >0? false : (this.sortOption.indexOf(2) >0? true : false),
                usrId: this.user? this.user.id : null,
                ctgry: this.ctgry,
                stts: this.ctgry == 1? [0,1,2,3] : [1,2,3],
                pageSize: this.pageSize,
                page: this.currentPage,
                searchWord: this.searchWord
            });
            if(rtnvalue.result){
                this.numOfStore = rtnvalue.data.count; 
                this.storeList = rtnvalue.data.findUsdMtrlDtoList;
                this.storeList.forEach((store) => {
                    switch(store.stts) {
                        case 0:
                            store.status = "<span style='color:blue'>승인대기</span>";
                            store.color = "color: gray; background-color: aliceblue";
                            break;
                        case 2: 
                            store.status = "<span style='color:coral !important'>예약중</span>";
                            store.color = "color: gray; background-color: lightyellow";
                            break;
                        case 3: 
                            store.status = "<span style='color:red !important'>판매완료</span>";
                            store.color = "color: lightgray;";
                            break;
                        default:
                            store.status = "";
                            store.color = "";
                            
                    }
                })
            }
            else {
                alert(rtnvalue.errormsg);
            }
            this.fetching = false;
        },

        setPaging() {
            this.numOfPage = Math.ceil(this.numOfStore / this.pageSize);
            this.pageQueue = [];
            if(this.currentPage < 11) {
                for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
                this.pageQueue.push(i);
                }
            }
            else {
                for(i = this.currentPage - 9; i <= this.currentPage; i++) {
                this.pageQueue.push(i);
                }
            }
        },

        async switchPage(mode) {
            var render = false;
            switch(mode) {
                case -1 : if(this.currentPage > 1) {
                            this.currentPage = 1; 
                            render = true;
                        }
                break;
                case 0 : if(this.currentPage > 1) {
                            this.currentPage--;
                            render = true;
                        }
                break;
                case 1 : if(this.currentPage < this.numOfPage) {
                            this.currentPage++;
                            render = true;
                        }
                break;
                case 2 : if(this.currentPage < this.numOfPage) {
                            this.currentPage = this.numOfPage;
                            render = true;
                        } 
            }
            if(render) {
                this.saveSessionData();
                await this.fetchData();
                this.setPaging();
            }
        },

        async switchCtgry() {
            if(this.ctgry > 0 && !this.user) {
                this.numOfStore = 0;
                this.numOfPage = 1;
                this.storeList = [];
                return;
            }
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async searchPrj() {
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async sortTable(col) {
            for(var i=0; i < this.sortOption.length; i++) {
                if(i != col) {
                    this.$set(this.sortOption, i, 0);
                }
                else {
                    this.$set(this.sortOption, i, (this.sortOption[i]+1)%3);
                    this.currentPage = 1;
                    this.saveSessionData();
                    await this.fetchData();
                    this.setPaging();
                }
            }
        },

        moveToStore(usdMtrlId) {
            location.href = '/usdmtrl/store/' + usdMtrlId;
        },
        
        saveSessionData() {
            sessionStorage.setItem('sortOption', this.sortOption);
            sessionStorage.setItem('ctgry', this.ctgry);
            sessionStorage.setItem('pageSize', this.pageSize);
            sessionStorage.setItem('currentPage', this.currentPage);
            sessionStorage.setItem('searchWord', this.searchWord);
        },

        renderDateTimeFormat(dateTime) {
            if(dateTime) {
                if(this.isMobile) {
                    var date = dateTime.split('T')[0];
                    return date.split('-')[1] + '-' + date.split('-')[2];
                }
                else {
                    return dateTime.split('T')[0];
                }
            }
            else {
                return null
            }
        }
    }
}
</script>

<style scoped>
img {
    left:39%;
}
#search_tooltip {
    right:25%;
}
@media screen and (max-width: 768px) {
    img {
        left:55%;
    }   
    #search_tooltip {
        right:55%;
    }
}

</style>