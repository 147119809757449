<template>
    <div class="inquiry-page">
        <div class="bg">
            <div class="overlap">
                <img class="purchase" src="@/assets/img/inquiry/purchase-3347053-1.png" />
                <div class="purchase-2"></div>
                <div class="group">
                    <div class="text-wrapper">문의하기</div>
                    <div class="text-wrapper-2">최선을 다해 도와드리겠습니다</div>
                </div>
            </div>
        </div>
        <div class="contents">
            <div class="register">
                <form class="gform" method="POST" data-email="info@m3.co.kr" action="https://script.google.com/macros/s/AKfycbxUFsL8qxk65Ri-yGbmmsOlMb6_9ewkvpxjFdNLQ7ue4vB122zbCLGZ3Q3RHyJ_qU-gSA/exec" target='blankifr' id="frm" ref="form">
                    <div class="subject" style="display:flex">
                        <div>
                            <div class="title">상담문의</div>
                            <img class="img" src="@/assets/img/inquiry/line.svg" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="column">
                            <div class="th">
                                <span class="name">이름</span>
                            </div>
                            <td class="td">
                                <input v-model="state.name" type="text" name="name" required placeholder="이름을 입력해주세요"/>
                            </td>
                        </div>
                        <div class="column">
                            <div class="th">
                                <span class="name">연락처</span>
                            </div>
                            <div class="td">
                                <input v-model="state.phone" type="text" name="phone" required placeholder="전화번호를 입력해주세요(기호, 공백 제외)"/>
                            </div>
                        </div>
                    </div>
                    <div class="row full">
                        <div class="th">
                            <span class="name">이메일</span>
                        </div>
                        <div class="td">
                            <input v-model="state.email" type="text" name="email" required placeholder="이메일을 입력해주세요"/>
                        </div>
                    </div>
                    <div class="row line">
                        <div class="th">
                            <span class="name">건축용도</span>
                        </div>
                        <div class="td">
                            <label v-for="(ctgry, index) in ctgryList" :key="index">
                                <input v-model="category" type="radio" name="category" :value="ctgry.ctgryId"/>
                                <span class="radio">{{ ctgry.ctgryName }}</span>
                            </label>
                        </div>
                    </div>

                    <div v-for="(form, index) in formList" :key="index">
                        <div :class="'row ' + ([2,3].indexOf(form.formType) <0? 'full' : 'line')">
                            <div class="th">
                                <span class="name">{{ form.formName }}</span>
                            </div>
                            <div class="td">
                                <input v-if="[0,1].indexOf(form.formType) >=0" v-model="form.answer" :type="form.formType == 0? 'text' : 'number'" :name="'form' + form.id" :placeholder="form.placeHolder"/>
                                <textarea v-else-if="form.formType == 4" v-model="form.answer" :name="'form' + form.id" :placeholder="form.placeHolder"></textarea>
                                <template v-else>
                                    <label v-for="(answer, kintex) in form.answerOptionList" :key="kintex">
                                        <input v-model="form.answerList" :type="form.formType == 2? 'checkbox' : 'radio'" :name="'answer' + answer.id" :value="answer.id"/>
                                        <span class="radio">{{ answer.label }}</span>
                                    </label>        
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="th full block">
                            <span class="name">상담내용</span>
                            <textarea v-model="state.content" name="content" placeholder="상담내용을 자세하게 작성해주세요." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="privacy">
                            <label>
                                <input type="checkbox" v-model="state.privacyConsent" value="true" required >
                                <span class="checkbox">개인정보 수집 및 이용에 동의합니다.</span>
                            </label>
                            <div class="view">
                                <span @click="showPrivecyPolicy">개인정보활용 전문보기</span>
                                <img class="icon" src="@/assets/img/button/icon1.svg" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label>
                            <input :checked="state.receiveTextReply === 'Y'" type="checkbox" name="mobileMessage" v-model="state.receiveText" value="문자가능"/>
                            <span class="checkbox">문자로 답변을 받겠습니다.</span>
                        </label>
                    </div>
                    <div class="btn-area" style="width: 100%;">
                        <button class="submit" type="button" @click="onSubmit"><span>상담신청<i v-if="submitting" class='fa fa-spinner fa-spin' style="margin-left:10px"></i></span></button>
                    </div>
                </form>
            </div>
        </div>
        <privacy-policy v-if="isShowPrivecyPolicy" @close="closePrivecyPolicy"/>
        <iframe name='blankifr' style='display:none;'></iframe>
    </div>
</template>

<script>
import store from '@/store'
import Api from '@/api'
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

export default {
    name: 'Question',
    components: { PrivacyPolicy },
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    data() {
        return {
            ctgryList: [],
            formList: [],
            category: null,
            state: {
                name: '', // 이름
                phone: '', // 연락처
                email: '', // 이메일
                content: '', // 내용
                privacyConsent: false, // 개인정보 수집 및 이용 동의 여부
                receiveTextReply: 'N', // 문자 답변 여부
                receiveText: false
            },
            submitting: false,
            isShowPrivecyPolicy: false
        }
    },
    created() {
        store.commit('setMenuId', 'M502');
    },
    watch: {
        category(val) {
            this.ctgryList.forEach((ctgry) => {
                if(ctgry.ctgryId == val) {
                    this.formList = ctgry.questionForms;
                }
            })
        }
    },
    async mounted() {
        const rtnval = await Api.get('/api/v1/question/ctgry/list');
        if(rtnval.result) {
            this.ctgryList = rtnval.data.sort((a,b) => a.orderInPage - b.orderInPage);
            this.category = this.ctgryList[0].ctgryId;
        }
        else {
            alert(rtnval.errormsg);
        }
    },
    methods: {
        showPrivecyPolicy() {
            this.isShowPrivecyPolicy = true;
        },
        closePrivecyPolicy() {
            this.isShowPrivecyPolicy = false;
        },
        async onSubmit() {
            if(this.validation()) {
                this.formList.forEach((form) => {
                    if(form.formType == 2) {
                        form.answerList.forEach((answerId) => {
                            answerId = Number(answerId);
                        })
                        form.answerOptionList = form.answerOptionList.filter((v) => form.answerList.indexOf(v.id) >=0);
                    }
                    else if(form.formType == 3) {
                        form.answerOptionList = form.answerOptionList.filter((v) => v.id == Number(form.answerList));
                        form.answerList = Array.isArray(form.answerList)? form.answerList : [form.answerList];
                    }
                })
                const content = this.state.content.replace(/\n/g, '<br/>') + (this.state.receiveText? '<br><br>※ 문자로 답변받기를 원하시는 고객입니다.' : '')
                var request = {
                    email: this.state.email,
                    fullName: this.state.name,
                    phone: this.state.phone,
                    desc: content,
                    ctgry: this.category,
                    formAnswerList: this.formList
                };
                this.submitting = true;
                var rtnval = await Api.post('/api/v1/admin/usr/mng/send/question', request);

                if(rtnval.result) {
                    alert("제출되었습니다. 빠른 시일 내에 " + (!this.state.receiveText? "메일 혹은 전화로 " : "") + "답변 드리겠습니다.");
                    store.commit('setMenuId', null);
                    location.href = '/';
                }
                else {
                    alert(rtnval.errormsg);
                }
                this.submitting = false;
            }
        },
        validation() {
            if (!this.state.name) {
                alert('이름은 필수 입력사항입니다.');
                return false;
            }
            if (!this.state.phone){
                alert('연락처는 필수 입력사항입니다.');
                return false;
            }
            if (!this.state.email){
                alert('이메일은 필수 입력사항입니다.');
                return false;
            }
            if (this.state.content == ''){
                alert('문의 내용을 입력해주세요.');
                return false;
            }
            if(!this.state.privacyConsent) {
                alert('이용약관 및 개인정보처리방침은 필수 동의사항입니다.');
                return false;
            }
            return true;
        }
    }
}
</script>

<style scoped>
.title {
    display: unset;
    justify-content: unset;
    align-items: unset;
    height: unset;
}
.row > * {
    width: unset;
}
</style>