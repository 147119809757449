<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>문의양식 관리</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">항목 수: {{ formList.length }} 개</span>
            <select class="custom-select ff-eng1 fs-085" v-model="pageSize" @change="switchPageSize()" style="width:120px; height:37px; margin-left: 20px">
                <option value="10">페이지당 10개</option>
                <option value="20">페이지당 20개</option>
            </select>
            <select class="custom-select ff-eng1 fs-085" v-model="ctgryFilter" @change="switchCtgry()" style="width:120px; height:37px; margin-left: 20px">
              <option v-for="(item, i) in ctgryList" :key="i" :value="item.ctgryId">{{ item.ctgryName }}</option>
            </select>
            <div class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(0)" style="width:60px; height:32px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">추가</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="setCtgry(1)" style="width:60px; height:32px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">수정</button></div>
            <div v-if="ctgryFilter" class="ps-2" style="margin:3px 0 0 10px;"><button class="fkr-set2 fs-095" @click="removeCtgry()" style="width:60px; height:32px; border:0; background-color:#c00; color:#fff; border-radius:4px">삭제</button></div>
            <div v-if="ctgryFilter && !editingOrder" class="ps-2" style="margin-left: 20px;"><button class="fkr-set2 fs-095" @click="editingOrder = true;" :disabled="searchWord != ''" :style="'width:120px; height:35px; cursor:pointer; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000; ' + (searchWord? 'opacity:0.5' : '')">노출순서 변경</button></div>
            <div v-if="ctgryFilter && editingOrder" class="ps-2" style="margin-left: 20px;">
              <i class="fa fa-undo" style="cursor:pointer; margin-right:10px" @click="saveModification(false)"/><button class="fkr-set2 fs-095" @click="saveModification(true)" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">변경사항 저장</button>
            </div>
            <div v-if="!ctgryFilter" class="ps-2" style="margin-left: auto; padding-left: 150px !important;"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/question-setcategory'" style="width:90px; height:35px; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">담당자 관리</button></div>
            <div class="ps-2" style="margin-left: auto; padding-left: 10px !important;"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/questionform-edit/0'" style="width:120px; height:35px; border:0; background-color:#4e0000; color:#fff; border-radius:4px;">문의항목 등록</button></div>
            <div class="x_div_center" style="margin-left: auto; margin-right: 20px">
              <div class="x_div_center" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="findForm()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="findForm()" style="border:0; width:170px; height:30px; padding:0 .3rem" placeholder="문의항목검색"></div>
              </div>
            </div>
          </div>
          


            <div class="mib_box_c w-100 pt-3">
              <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">문의항목명</span></div>
                <div class="x_div_center" style="width:30%"><span class="fkr-set1 fs-9">문의항목설명</span></div>
                <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">문의항목타입</span></div>
                <div class="x_div_center" style="width:5%"><span class="fkr-set1 fs-9">문항수</span></div>
                <div class="x_div_center" style="width:12%"><span class="fkr-set1 fs-9">노출순서</span></div>
                <div class="x_div_center" style="width:13%"><span class="fkr-set1 fs-9">등록일</span></div>
                <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">편집</span></div>
              </div>

              <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else>
                <div v-for="(item, index) in formListPaged" :key="index" class="x_div_center m_line_ov" style="height:55px; border-bottom:1px solid #d9d9d9">
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{item.formName}}</span></div>
                  <div class="x_div_center m_line" style="width:30%"><span class="fkr-set2 fs-9">{{item.formDesc}}</span></div>
                  <div class="x_div_center m_line" style="width:15%"><span class="fkr-set2 fs-9">{{formTypeResolver(item.formType)}}</span></div>
                  <div class="x_div_center m_line" style="width:5%"><span class="fkr-set2 fs-9">{{item.answerOptionList.length}}</span></div>
                  <div v-if="!editingOrder" class="x_div_center" style="width:12%"><span class="fkr-set2 fs-9">{{ctgryFilter? (currentPage-1) * pageSize + index + 1 : ''}}</span></div>
                  <div v-if="editingOrder" class="x_div_center" style="width:12%; display: flex;">
                    <i class="fas fa-arrow-up" style="cursor:pointer" 
                      @click="var absltIdx = (currentPage-1) * pageSize + index; 
                              if(absltIdx-1 >=0) [formList[absltIdx-1], formList[absltIdx]] = [formList[absltIdx], formList[absltIdx-1]]; 
                              renderList()"/>
                    &nbsp;
                    <i class="fas fa-arrow-down" style="cursor:pointer" 
                      @click="var absltIdx = (currentPage-1) * pageSize + index; 
                              if(absltIdx+1 < formList.length) [formList[absltIdx+1], formList[absltIdx]] = [formList[absltIdx], formList[absltIdx+1]]; 
                              renderList()"/>
                  </div>
                  <div class="x_div_center m_line" style="width:13%"><span class="fkr-set2 fs-9">{{item.crtDt? item.crtDt.split('T')[0] : ''}}</span></div>
                  <div class="x_div_center m_line" style="width:10%"><span class="fkr-set2 fs-9"><a :href="'/admin/site/questionform-edit/' + item.id"><i class="fa fa-edit"/></a></span></div>
                </div>
              </div>
            </div>

            <!-- 페이징 -->
            <div class="x_div_center py-3" v-if="!fetching">
                <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
            </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "QuestionFormList",
  components: {
		AdminLNB
	},
  data(){
    return {
        fetching: true,
        searchWord: '',
        currentCtgry: '전체',
        ctgryList: [],
        formList:[],
        formListOrig:[],
        formListPaged: [],
        pageQueue: [],
        currentPage: 1,
        numOfPage: 0,
        pageSize: 10,
        editingOrder: false,
        ctgryFilter: ''
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    this.currentPage = this.$route.query.p? this.$route.query.p : 1;
    this.pageSize = this.$route.query.w? this.$route.query.w : 10;
    history.replaceState({}, null, location.pathname);

    const rtnval = await Api.get('/api/v1/question/ctgry/list');
    if(rtnval.result) {
        this.ctgryList = rtnval.data.sort((a,b) => a.orderInPage - b.orderInPage);
        this.ctgryList = [{ctgryId: '', ctgryName: '전체', ctgryLabel: 'All', crtDt: null}].concat(this.ctgryList);
        await this.fetchForms(null);
        this.setPaging();
        this.renderList();
    }
    else {
        alert(rtnval.errormsg);
    }
  },
  methods:{
    async fetchForms(keyword) {
      this.fetching = true;
      if(keyword) {
        this.formList = this.ctgryFilter? this.formList.filter((item) => item.formName.indexOf(keyword) >= 0 ) : this.formListOrig.filter((item) => item.formName.indexOf(keyword) >= 0 );
      }
      else {
        this.formList = [];
        const returnvalue = await Api.get('/api/v1/question/form/list');
        if(returnvalue.result) {
            this.formList = returnvalue.data.sort((a,b) => b.id - a.id);
            this.formListOrig = this.formList;
        }
        else {
            alert(returnvalue.errormsg);
        }
      }
      this.fetching = false;
    },

    async findForm() {
      if(this.editingOrder) {
        alert('노출순서 변경중에는 검색을 수행할 수 없습니다.');
        return;
      }

      await this.fetchForms(this.searchWord);
      this.setPaging();
      this.renderList();
    },

    renderList() {
      this.formListPaged = [];
      for(var j = (this.currentPage-1) * this.pageSize; j < (this.formList.length > this.currentPage * this.pageSize? this.currentPage * this.pageSize : this.formList.length); j++) {
        this.formListPaged.push(this.formList[j]);
      }
    },

    setPaging() {
      this.numOfPage = Math.ceil(this.formList.length / this.pageSize);
      this.pageQueue = [];
      if(this.currentPage < 11) {
        for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
          this.pageQueue.push(i);
        }
      }
      else {
        for(i = this.currentPage - 9; i <= this.currentPage; i++) {
          this.pageQueue.push(i);
        }
      }
    },

    async switchPage(mode) {
      var render = false;
      switch(mode) {
        case -1 : if(this.currentPage > 1) {
                    this.currentPage = 1; 
                    render = true;
                  }
          break;
        case 0 : if(this.currentPage > 1) {
                    this.currentPage--;
                    render = true;
                  }
          break;
        case 1 : if(this.currentPage < this.numOfPage) {
                    this.currentPage++;
                    render = true;
                  }
          break;
        case 2 : if(this.currentPage < this.numOfPage) {
                    this.currentPage = this.numOfPage;
                    render = true;
                  } 
      }
      if(render) {
        this.setPaging();
        this.renderList();
      }
    },

    async switchPageSize() {
      if(this.editingOrder) {
        alert('노출순서 변경중에는 페이지크기를 변경할 수 없습니다.');
        return;
      }

      this.currentPage = 1;
      this.setPaging();
      this.renderList();
    },

    async saveModification(option) {
      if(option) {
        this.fetching = true;
        const returnvalue = await Api.post('/api/v1/question/form/update/orders?ctgryId=' + this.ctgryFilter, this.formList);
        if(!returnvalue.result) {
            alert(returnvalue.errormsg);
            location.reload();
        }
        this.fetching = false;
      }
      else {
        this.ctgryList.forEach((ctgry) => {
          if(ctgry.ctgryId == this.ctgryFilter) {
            this.formList = ctgry.questionForms.sort((a,b) => a.orderInPage - b.orderInPage);
          }
        })
      }
      this.editingOrder = false;
      this.setPaging();
      this.renderList();
    },

    async switchCtgry() {
      this.currentPage = 1;
      if(this.ctgryFilter) {
        this.ctgryList.forEach((ctgry) => {
          if(ctgry.ctgryId == this.ctgryFilter) {
            this.currentCtgry = ctgry.ctgryName;
            this.formList = ctgry.questionForms.sort((a,b) => a.orderInPage - b.orderInPage);
          }
        })
      }
      else {
        this.currentCtgry = '전체';
        this.formList = this.formListOrig;
      }

      this.setPaging();
      this.renderList();
    },

    async setCtgry(option) {
      var ctgryNameAsIs = '';
      var ctgryLabelAsIs = '';
      var ctgryOrderAsIs = null;
      if(option) {
        this.ctgryList.forEach((ctgry) => {
          if(ctgry.ctgryId == this.ctgryFilter) {
            ctgryNameAsIs = ctgry.ctgryName;
            ctgryLabelAsIs = ctgry.ctgryLabel;
            ctgryOrderAsIs = ctgry.orderInPage;
          }
        })
      }
      var ctgryName = prompt((option? '수정할' : '등록할') + ' 카테고리명을 입력하세요.', ctgryNameAsIs);
      var ctgryLabel = prompt((option? '수정할' : '등록할 카테고리의') + ' 분류용 라벨을 입력하세요.', ctgryLabelAsIs);
      var ctgryOrder = prompt((option? '수정할' : '등록할 카테고리의') + ' 노출순서를 입력하세요.', ctgryOrderAsIs);
      if(!ctgryName || !ctgryLabel) {
        alert('카테고리명 및 라벨은 필수입니다.')
        return;
      }

      const rtnvalue = await Api.post('/api/v1/question/ctgry/set', {
        id: this.ctgryFilter? this.ctgryFilter : null,
        ctgryName: ctgryName,
        ctgryLabel: ctgryLabel,
        orderInPage: ctgryOrder
      })
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    async removeCtgry() {
      const rtnvalue = await Api.get('/api/v1/question/ctgry/delete?ctgryId=' + this.ctgryFilter)
      if(rtnvalue.result) {
        alert('정상적으로 삭제되었습니다.')
        location.reload();  
      }
      else {
        alert(rtnvalue.errormsg)
      }
    },

    formTypeResolver(type) {
      var rtn = '';
      switch(type) {
        case 0 : rtn = '텍스트입력';
          break;
        case 1 : rtn = '숫자입력';
          break;
        case 2 : rtn = '객관식 - 다중선택';
          break;
        case 3 : rtn = '객관식 - 단일선택';
          break;
        case 4 : rtn = '텍스트입력(장문)';
          break;
      }
      return rtn;
    }
  }
}
</script>

