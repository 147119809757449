<template>
  <div class="d-flex">
    <AdminLNB/>
    <div class="ca_main">
      <div class="x_div_center">
        <div class="pg_stage">

          <div class="d-flex pt-5">
            <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">사이트관리</span></div>
            <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span>담당자 설정</span></div></div>
          </div>

          <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

          <div style="height:20px"></div>

          <div class="w-100" style="display: flex;">
            <span class="fkr-set2 fs-10" style="padding: 5px">문의하기분류 개수: {{ ctgryList.length }} 개</span>
          </div>
          
          <div class="mib_box_c w-100 pt-3">
            <div class="x_div_center" style="height:55px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color:#eaeaea">
              <div class="x_div_center" style="width:15%"><span class="fkr-set1 fs-9">문의하기분류</span></div>
              <div class="x_div_center" style="width:35%"><span class="fkr-set1 fs-9">담당자</span></div>
              <div class="x_div_center" style="width:40%"><span class="fkr-set1 fs-9">부가설명</span></div>
              <div class="x_div_center" style="width:10%"><span class="fkr-set1 fs-9">삭제</span></div>
            </div>

            <div class="pg_m_set" v-if="fetching" style="padding: 20px">
              <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
            </div>
            <div v-else>
              <div v-for="(item, index) in ctgryListCustomed" :key="index" class="x_div_center" style="height:55px; border-bottom:1px solid #d9d9d9">
                <div class="x_div_center" style="width:15%">
                  <span class="fkr-set2 fs-9" v-if="item.ctgryId >= 0">{{item.ctgryName}}</span>
                  <select class="custom-select ff-eng1 fs-085" v-else v-model="item.selectedId" style="width:120px; height:30px;">
                    <option v-for="(ctgry, k) in ctgryList" :key="k" :value="ctgry.ctgryId">{{ ctgry.ctgryName }}</option>
                  </select>
                </div>
                <div class="x_div_center" style="width:40%; justify-content:left">
                  <span class="fkr-set2 fs-9" style="display:flex;">
                    <p v-for="(member, i) in item.memberInChargeList" :key="i" style="margin: 0 15px 0 0">{{ member.fullName }}&nbsp;
                      <a href="javascript:void(0)" @click="deleteMember(item, i, member)"><i class="fas fa-backspace"></i></a>
                    </p>
                    <a href="javascript:void(0)" @click="openMemberSearchModal(item)" style="margin-left: 10px"><i class="fas fa-plus-circle"></i></a>
                  </span>
                </div>
                <div class="x_div_center" style="width:35%; justify-content:left">
                  <span class="fkr-set2 fs-9" style="width:100%">
                    <input type="text" class="form-control fkr-set2" style="width:85%; height:36px; margin-left: 20px;" v-model="item.comment">
                  </span>
                </div>
                <div class="x_div_center" style="width:10%">
                  <span class="fkr-set2 fs-9" v-if="item.ctgryId < 0">
                    <a href="javascript:void(0)" @click="ctgryListCustomed = ctgryListCustomed.filter((v, i) => i != index)"><i class="fa fa-trash"></i></a>
                  </span>
                </div>
              </div>
              <div style="height:15px"></div>
              <div class="x_div_center"><span class="fkr-set1 fs-15"><a href="javascript:void(0)" @click="addRow()"><i class="fas fa-plus-circle"></i></a></span></div>
            </div>
          </div>

          <div class="x_div_end py-4">
            <div class="py-2"><button class="fkr-set2 fs-095" onclick="location.href = '/admin/site/questionform-list'" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#eee7e7; color:#4e0000; border-radius:4px; border:1px solid #4e0000;">목록</button></div>
            <div class="py-2"><button class="fkr-set2 fs-095" @click="saveAllSetting()" style="width:120px; height:37px; margin-left: 20px; border:0; background-color:#4e0000; color:#fff; border-radius:4px; border:0">일괄 저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
          </div>

          <div style="height:50px"></div>

        </div>
      </div>
    </div>
    <FindUsrModal @selectedUsrId="enlistMember"/>
  </div>
</template>

<script>
import FindUsrModal from '@/components/FindUsrModal.vue'
import AdminLNB from '@/components/AdminLNB.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: "QuestionSetCtgry",
  components: {
		AdminLNB, FindUsrModal
	},
  data(){
    return {
        fetching: true,
        submitting: false,
        ctgryList: [],
        ctgryListCustomed: [],
        editingCtgryId: null,
        commentCommon: '',
        newList: [],
        setList: [],
        delList: []
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    const rtnval = await Api.get('/api/v1/question/ctgry/list');
    if(rtnval.result) {
        var general = false;
        this.ctgryList = rtnval.data.sort((a,b) => a.orderInPage - b.orderInPage);
        this.ctgryList.forEach((ctgry) => {
          var customed = false;
          var memberInChargeList = [];
          ctgry.memberInChargeList = ctgry.memberInChargeList.sort((a,b) => a.orderInCharge - b.orderInCharge);
          ctgry.memberInChargeList.forEach((member) => {
            memberInChargeList.push(member);
            if(member.customed){
              customed = true;
            }
          })
          if(!customed && !general) {
            general = true;
            this.ctgryListCustomed.push({ctgryId: 0, ctgryName: '공통', memberInChargeList: memberInChargeList, comment: ctgry.comment});
            this.commentCommon = ctgry.comment;
          }
          else if(customed) {
            this.ctgryListCustomed.push({ctgryId: ctgry.ctgryId, ctgryName: ctgry.ctgryName, memberInChargeList: memberInChargeList, comment: ctgry.comment});
          }
        })
    }
    else {
        alert(rtnval.errormsg);
    }
    this.fetching = false;
  },
  methods:{
    addRow() {
      this.ctgryListCustomed.push({ctgryId: -1, selectedId: null, memberInChargeList: [], comment: null});
    },
    openMemberSearchModal(ctgry) {
      this.editingCtgryId = ctgry.ctgryId;
      window.$('#modalForm').modal('show');
    },
    enlistMember(member) {
      this.ctgryListCustomed.forEach((ctgry) => {
        if(ctgry.ctgryId == this.editingCtgryId) {
          ctgry.memberInChargeList.push({id: null, usrId: member.usrId, ctgryId: this.editingCtgryId, fullName: member.usrNm, customed: this.editingCtgryId? true : false, comment: ctgry.comment})
        }
      })
      this.editingCtgryId = null;
      this.$forceUpdate();
    },
    deleteMember(ctgry, index, member) {
      if(member.id) {
        if(ctgry.ctgryId) {
          this.delList.push({id: member.id});
        }
        else {
          this.ctgryList.forEach((ctg) => {
            ctg.memberInChargeList.forEach((mem) => {
              if(!mem.customed && mem.usrId == member.usrId) {
                this.delList.push({id: mem.id});
              }
            })
          })
        }
      }
      ctgry.memberInChargeList = ctgry.memberInChargeList.filter((v, i) => i != index);
      this.$forceUpdate();
    },
    async saveAllSetting() {
      var commons = [];
      this.ctgryListCustomed.forEach((ctgry) => {
        if(ctgry.ctgryId == 0) {
          var bucket = [];
          for(var k=0; k < ctgry.memberInChargeList.length; k++) {
            const member = ctgry.memberInChargeList[k];
            if(!member.id) {
              if(k == 0) {
                member.orderInCharge = 0;
              }
              else {
                member.orderInCharge = ctgry.memberInChargeList[k-1].orderInCharge +1;
              }
              bucket.push(member);
            }
            else {
              if(this.commentCommon != ctgry.comment) {
                bucket.push(member);
              }
            }
          }

          if(bucket.length) {
            this.ctgryList.forEach((ctg) => {
              commons[ctg.ctgryId] = [];
              bucket.forEach((b) => {
                if(b.id) {
                  ctg.memberInChargeList.forEach((m) => {
                    if(!m.customed) {
                      commons[ctg.ctgryId].push({id: m.id, usrId: b.usrId, ctgryId: ctg.ctgryId, orderInCharge: b.orderInCharge, customed: false, comment: ctgry.comment})    
                    }
                  })
                }
                else {
                  commons[ctg.ctgryId].push({id: null, usrId: b.usrId, ctgryId: ctg.ctgryId, orderInCharge: b.orderInCharge, customed: false, comment: ctgry.comment})
                }
              })
            })
          }
        }
      })

      for(var j=0; j < this.ctgryListCustomed.length; j++) {
        const ctgry = this.ctgryListCustomed[j];
        if(ctgry.ctgryId >0) {
          commons[ctgry.ctgryId] = null;
        }
        else if(ctgry.ctgryId == -1) {
          if(ctgry.selectedId) {
            commons[ctgry.selectedId] = null;
          }
          else {
            alert('문의하기 분류를 선택하세요.');
            return;
          }
        }
        else {
          continue;
        }
        
        for(var k=0; k < ctgry.memberInChargeList.length; k++) {
          const member = ctgry.memberInChargeList[k];
          member.comment = ctgry.comment;
          if(!member.id) {
            if(member.ctgryId == -1) {
              member.ctgryId = ctgry.selectedId;
            }

            if(k == 0) {
              member.orderInCharge = 0;
            }
            else {
              member.orderInCharge = ctgry.memberInChargeList[k-1].orderInCharge +1;
            }
            this.newList.push(member);
          }
          else {
            this.setList.push(member);
          }
        }
      }

      if(commons.length) {
        commons.forEach((c) => {
          if(c) {
            c.forEach((mem) => {
              if(mem.id) {
                this.setList.push(mem);
              }
              else {
                this.newList.push(mem);
              }
            })
          }
        })
      }

      if(this.newList.length || this.setList.length || this.delList.length) {
        this.submitting = true;
        const rtnvalue = await Api.post('/api/v1/question/ctgry/set/charge', {
          newList: this.newList.length? this.newList : null,
          setList: this.setList.length? this.setList : null,
          delList: this.delList.length? this.delList : null
        });
        if(rtnvalue.result) {
          alert('정상적으로 등록되었습니다.')
          location.reload();
        }
        else {
          alert(rtnvalue.errormsg)
        }
        this.submitting = false;
      }
      else {
        alert('변경사항이 없습니다.');
        return;
      }
    }
  }
}
</script>

