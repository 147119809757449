<template>
  <div id="modelList">
    <div class="container" id="header" style="display:flex; justify-content:center; z-index: 1;">
      <!-- <img src="@/assets/img/model/estimation.jpeg" /> -->
      <div class="content-title">
        <label class="name">주택견적내기</label>
      </div>
      <div class="x_div_center">
        <div class="pg_stage" style="position: relative; top: 60%; z-index: 1;">
          <div class="py-4" style="padding: 1.5rem !important;">
            <div class="x_div d-flex" style="justify-content: space-between;">
              <div v-if="user.info.usrType == 'MA'" class="d-flex" style="width:245px; height:34px; border:1px solid #d6d6d6; border-radius:4px">
                <div style="padding:0 10px 0 10px"><input type="text" id="ser_project" name="ser_project" @keyup.enter="findEstmtList()" v-model="searchKeyword" placeholder="고객명 또는 제목 검색" class="form-control fa-kor-Noto" style="border:0; width:180px; height:30px; padding:0 .1rem"></div>
                <div style="padding:5px 0 0 14px; border-left:1px solid #d6d6d6" @click="findEstmtList()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
              </div>
              <select v-if="(this.user.info.estmtList && this.user.info.estmtList.length > 0)" class="custom-select ff-eng1 fs-085" style="width: 200px" v-model="loadedEstmt">
                <option v-for="(myEstmt, index) in myEstmtList" :key="index" :value="myEstmt.estmtId">{{ new Date(myEstmt.estmtDt).toLocaleString() }}</option>
              </select>
            </div>
            <div class="x_div">
              <div id="auto_frm" v-show="isSearch" class="p-2" style="display:none; position:absolute; width:245px; background-color:#fff; border:1px solid #d6d6d6; border-radius:0 0 8px 8px;">
                <div v-for="(item, index) in estmtSearchList" :key="index" class="x_div_center ls_bg1">
                  <div class="w-100 text-start py-1 px-2"><i class="fa-sharp fa-solid fa-magnifying-glass pe-2" style="color:#ddd"></i> <a href="javascript:void(0)" @click="loadedEstmt = item.estmtId">{{item.customerNm + (item.sbjct? ' - ' + item.sbjct : '') + ' - ' + item.estmtDt.split('T')[0]}}</a></div>
                  <div class="text-center" style="width:40px"><i class="fa-solid fa-arrow-pointer" style="color:#ccc"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="display:grid; justify-content:center">
      <div class="content" style="position:relative; top:unset; left:unset; width:unset; max-height: none; transform: unset; margin: 100px 0 50px 0;">
        <div class="content-item">
          <div class="pg_m_set" v-if="fetching" style="padding: 20px">
            <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
          </div>
          <div class="table" v-else style="padding:0 !important">
            <div class="row" style="background-color:#000; border-top-left-radius: 17px; border-top-right-radius: 17px;">
              <span class="title" style="color:#fff; margin-bottom: 20px;">건축사양 | Specification</span>
              <div style="display:flex">
                <div style="display:flex" v-for="(bucket, index) in mtrlAmntBuckets" :key="index">
                  <div v-for="(mtrlAmnt, kintex) in bucket" :key="kintex" style="max-width:160px">
                    <span class="title" style="color:#fff;">{{ kintex? '' : mtrlAmnt.archiSpcNm }}</span>
                    <img :style="'width:100px; height: auto; min-height:100px; object-fit:contain; ' + (kintex? 'opacity: 0' : '')" :src="mtrlAmnt.imgLink">
                    <div :style="'display:flex; padding-top: 5px; ' + (kintex? 'opacity: 0' : '')">
                      <input :style="'width:60% !important; min-width: unset !important; font-weight: 600 !important; ' + (mtrlAmnt.unit? '' : 'opacity: 0; ') + (user.info.usrType != 'MA'? 'color:#fff' : '')" v-model="mtrlAmnt.amnt" @keyup="calculatePrice()" @change="calculatePrice()" type="number" :disabled="user.info.usrType != 'MA'">
                      <span class="title" style="color:#fff; margin: 10px; padding: 0 !important">{{ mtrlAmnt.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <span class="title" style="margin-bottom: 20px;">선택옵션 | Options</span>
              <div style="display:flex">
                <div :style="'display:flex; flex:' + (index? bucket.length : '0')" v-for="(bucket, index) in mtrlAmntBuckets" :key="index">
                  <div class="item-area" v-for="(mtrlAmnt, kintex) in bucket" :key="kintex" :style="'max-width:160px; padding-left: 10px; flex:1; ' + (index >1? 'border-left:1px solid #d6d6d6;' : '')">
                    <span class="title">{{ mtrlAmnt.optionNm }}</span>
                    <label v-for="(mtrl, syntax) in mtrlAmnt.mtrlList" :key="syntax" style="display:flex; margin:0 0 5px 0;">
                      <input v-model="mtrlAmnt.stdMtrlId" type="radio" :value="mtrl.stdMtrlId" @click="showDesc(mtrl, mtrlAmnt)" :disabled="user.info.usrType != 'MA'">
                      <span class="radio"></span>
                      <span style="width:80%; padding:2px 0 0 5px; white-space: break-spaces;">{{ mtrl.stdMtrlNm }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="specDesc">
              <div class="pg_m_set" v-if="loading" style="padding: 20px">
                <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
              </div>
              <div v-else style="display:flex">
                <img v-if="logoPath" style="width:300px; height:auto; object-fit:contain;" :src="logoPath">
                <span style="font-size: 18px; padding: 10px;" v-html="specDesc"></span>
              </div>
            </div>
            <div class="row">
              <span class="title" style="margin-bottom: 20px;">비고 | etc</span>
              <textarea class="form-control fkr-set2" style="margin: 10px; width: 96%; height: 150px;" v-model="etc" :disabled="user.info.usrType != 'MA'"></textarea>
            </div>
          </div>
        </div>      
      </div>
      <div class="x_div_center">
        <span style="font-size: 26px; font-weight:600; margin:20px 0 50px 0">견적가 : {{ estmtPrice? Number(estmtPrice.toFixed(0)).toLocaleString() : '' }} 원</span>
      </div>
      <div class="x_div_center" v-if="user.info.usrType == 'MA'">
        <div class="save-btn" onclick="window.$('#modalForm').modal('show')">
          <span style="color:#fff; font-size:26px; margin:0">내역 저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span>
        </div>
      </div>
    </div>
    <!-- 맞춤 주택 추천받기 -->
    <custom-home-recommendation />

    <FindUsrModal @selectedUsrId="saveEstimation"/>

  </div>
</template>
<script> 
import Auth from '@/api/auth'
import Api from '@/api'
import FindUsrModal from '@/components/FindUsrModal.vue'
import CustomHomeRecommendation from '@/components/CustomHomeRecommendation.vue';

export default {
  name: 'RealtimeEstimation',
  components: {
    CustomHomeRecommendation, FindUsrModal
  },
  props: {
    user: {
        type: Object,
        default: null
    },
  },
  data() {
    return {
      fetching: true,
      loading: false,
      submitting: false,
      estmtList: [],
      estmtSearchList: [],
      isSearch: false,
      searchKeyword: null,
      customerId: null,
      loadedEstmt: null,
      myEstmtList: [],
      customerNm: '',
      sbjct: '',
      estmtDt: null,
      framePrice: 0,
      estmtPrice: 0,
      etc: '',
      etcClssfd: '',
      specDesc: '',
      logoPath: null,
      mtrlAmntBuckets: [],
      mtrlCtgryTypesMarker: [],
    }
  },
  watch: {
    async loadedEstmt(val) {
      this.loadEstimation(val);
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      location.href = '/member/login'
    }

    if(this.user.info.usrType == 'MA') {
      const returnvalue = await Api.get('/api/v1/estmt/list/estmt');
      if(returnvalue.result) {
            this.estmtList = returnvalue.data.findEstmtDtoList.sort((a,b) => b.estmtId - a.estmtId);
        }
        else {
            alert(returnvalue.errormsg);
        }
    }

    const returnval = await Api.get('/api/v1/mtrl/list/mtrlbyarchispc');
    if(returnval.result) {
      returnval.data.findArchiSpcMtrlsDtoList.forEach((spec) => {
        if(spec.imgFileNm) {
          var tokens = spec.imgFileNm.split('.');
          var mimeType = tokens[tokens.length-1].toLowerCase();
          var fileType = 'image/' + mimeType;

          const byteCharacters = atob(spec.img);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: fileType});
          spec.imgLink = URL.createObjectURL(blob);
        }
        spec.findStdMtrlDtoList.forEach((mtrl) => {
          mtrl.listMtrlCtgryList = mtrl.listMtrlCtgryList.sort((a,b) => a.ctgryOrder - b.ctgryOrder);
          if(!this.mtrlCtgryTypesMarker[mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId]){
            this.mtrlCtgryTypesMarker[mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId] = true;
            if(!this.mtrlAmntBuckets[spec.archiSpcId]) {
              this.mtrlAmntBuckets[spec.archiSpcId] = [];
            }
            var lastMtrlCtgryTypeId = mtrl.listMtrlCtgryList[mtrl.listMtrlCtgryList.length-1].mtrlCtgryTypeId;
            var optionNm = ''
            for(var i = mtrl.listMtrlCtgryList.length -1; i >= 0; i--) {
              optionNm += mtrl.listMtrlCtgryList[i].mtrlCtgryTypeNm;
            }
            this.mtrlAmntBuckets[spec.archiSpcId].push({
              mtrlAmntId: null,
              estmtId: null, 
              archiSpcId: spec.archiSpcId, 
              archiSpcNm: spec.archiSpcNm, 
              unit: spec.unit,
              imgLink: spec.imgLink,
              stdMtrlId: null, 
              stdMtrlNm: '', 
              lastMtrlCtgryTypeId: lastMtrlCtgryTypeId,
              amnt: 0, 
              mtrlList: spec.findStdMtrlDtoList.filter((m) => m.listMtrlCtgryList[m.listMtrlCtgryList.length-1].mtrlCtgryTypeId == lastMtrlCtgryTypeId),
              optionNm: optionNm
            });
          }
        })
      });
      this.myEstmtList = this.user.info.estmtList;
      if(this.myEstmtList.length >0) {
        this.loadedEstmt = this.myEstmtList[0].estmtId;
      }
    }
    else {
        alert(returnval.errormsg);
    }
    
    this.fetching = false;
  },
  methods: {
    findEstmtList() {
      this.estmtSearchList = [];
      this.estmtSearchList = this.estmtList.filter((estmt) => (estmt.customerNm.indexOf(this.searchKeyword) >= 0 || estmt.sbjct.indexOf(this.searchKeyword) >= 0) );
      this.isSearch = true;
    },
    async loadEstimation(estmtId) {
      this.fetching = true;
      const returnvalue = await Api.get('/api/v1/estmt/find/estmt?estmtId=' + estmtId);
      if(returnvalue.result) {
        this.customerNm = returnvalue.data.customerNm;
        this.sbjct = returnvalue.data.sbjct;
        this.estmtDt = returnvalue.data.estmtDt;
        this.framePrice = returnvalue.data.framePrice;
        this.estmtPrice = returnvalue.data.estmtPrice;
        this.etc = returnvalue.data.etc;
        this.etcClssfd = returnvalue.data.etcClssfd;

        returnvalue.data.mtrlAmntDtoList.forEach((item) => {
          this.mtrlAmntBuckets[item.archiSpcId].forEach((bucket) => {
            if(bucket.lastMtrlCtgryTypeId == item.lastMtrlCtgryTypeId) {
              bucket.mtrlAmntId = item.mtrlAmntId;
              bucket.estmtId = estmtId;
              bucket.stdMtrlId = item.stdMtrlId;
              bucket.stdMtrlNm = item.stdMtrlNm;
              bucket.amnt = item.amnt;
              bucket.stdMtrlPrice = item.stdMtrlPrice;
            }
          })
        })
      }
      else {
        alert(returnvalue.errormsg)
      }
      this.calculatePrice();
      this.fetching = false;
      this.isSearch = false;
    },
    calculatePrice() {
      this.estmtPrice = 0;
      this.mtrlAmntBuckets.forEach((bucket) => {
        if(bucket) {
          bucket.forEach((mtrlAmnt) => {
            if(mtrlAmnt.amnt && mtrlAmnt.stdMtrlPrice) {
              this.estmtPrice += mtrlAmnt.amnt * mtrlAmnt.stdMtrlPrice;
            }
          })
        }
      })
      if(this.estmtPrice > 0) {
        this.estmtPrice += this.framePrice;
      }
    },
    async showDesc(mtrl, mtrlAmnt) {
      this.loading = true;
      const returnvalue = await Api.get('/api/v1/mtrl/find/stdmtrl?stdMtrlId=' + mtrl.stdMtrlId);
      if(returnvalue.result) {
        this.specDesc = returnvalue.data.specDesc.replace(/\n/g, '<br/>');

        if(returnvalue.data.imgFileNm) {
          var tokens = returnvalue.data.imgFileNm.split('.');
          var mimeType = tokens[tokens.length-1].toLowerCase();
          var fileType = 'image/' + mimeType;

          const byteCharacters = atob(returnvalue.data.img);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: fileType});
          this.logoPath = URL.createObjectURL(blob);
        }
        else {
          this.logoPath = null;
        }
      }
      else {
        alert(returnvalue.errormsg)
      }
      this.loading = false;

      mtrlAmnt.stdMtrlPrice = mtrl.price;
      this.calculatePrice();
    },
    async saveEstimation(customer) {
      this.customerId = customer.usrId;
      var mtrlAmntAddList = [];
      var mtrlAmntUpdateList = [];
      this.mtrlAmntBuckets.forEach((bucket) => {
        if(bucket) {
          var bucketAmnt = 0;
          bucket.forEach((mtrlAmnt, index) => {
            if(index == 0) {
              bucketAmnt = mtrlAmnt.amnt;
            }
            else {
              mtrlAmnt.amnt = bucketAmnt;
            }

            if(mtrlAmnt.mtrlAmntId) {
              mtrlAmntUpdateList.push(mtrlAmnt)
            }
            else {
              mtrlAmntAddList.push(mtrlAmnt)
            }
          })
        }
      })

      var update = confirm('기존 견적내역을 업데이트합니다.\n신규 견적내역으로 사본을 만드시려면 \'취소\'를 누르세요.');

      this.submitting = true;

      const rtnvalue = await Api.post('/api/v1/estmt/' + (update? 'update' : 'set') + '/estmt', {
        estmtId: update? this.loadedEstmt : null,
        customerNm: this.customerNm,
        sbjct: this.sbjct,
        customerId: this.customerId,
        framePrice: this.framePrice,
        estmtPrice: this.estmtPrice,
        etc: this.etc,
        etcClssfd: this.etcClssfd,
        mtrlAmntAddList: update? mtrlAmntAddList : mtrlAmntUpdateList.concat(mtrlAmntAddList),
        mtrlAmntUpdateList: update? mtrlAmntUpdateList : null
      });
      if(rtnvalue.result) {
        alert('정상적으로 등록되었습니다.')
      }
      else {
        alert(rtnvalue.errormsg)
      }
      this.submitting = false;
    },
  }
}
</script>

<style scoped>
#header {
  height: 470px;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/m3.company.com/platform/plan/house/CWK/CWK-13-00-00/_viewmore7.jpg');
}
.title {
  height: unset;
}
.table > :not(caption) > * > * {
  padding: unset;
}
.table .row {
  padding: 16px !important;
  width: 100% !important;
  border-bottom: 0 !important;
}
.save-btn {
  width: 226px;
  height: 61px;
  border-radius: 30.5px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 100px;
}
@media (min-width:576px) { .container-sm, .container { max-width:unset } }
@media (min-width:768px) { .container-md, .container-sm, .container { max-width:unset } }
@media (min-width:992px) { .container-lg, .container-md, .container-sm, .container { max-width:unset } }
@media (min-width:1200px) { .container-xl, .container-lg, .container-md, .container-sm, .container { max-width:unset } }
@media (min-width:1400px) { .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container { max-width:unset } }
</style>