<template>
    <div class="m3_contetns" style="margin-top: 50px">

        <div class="m_ipt_box">

            <div class="mib_mod">
                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-show="!fetching" class="w-100">
                    <div class="x_div pb-3" style="border-bottom:1px solid #d9d9d9; display:flex;">
                        <div class="h3 ff-kor1 fc-w6">{{prjNm}} 프로젝트</div>
                        <button class="fkr-set1 fc-w1" :style="'width:60px; height:26px; border:0; padding-top:0; margin-left:15px; background-color:' + sttsColor" disabled>TASK{{taskOrder}}</button>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">고객명</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ customerNm }} </span></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">연락처</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ phoneDashed }} </span></div>
                    </div>
                    <div class="mib_box_a py-3" v-if="email">
                        <div class="mib_a1 text-end"><span class="fkr-set1">E-Mail</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ email }} </span></div>
                    </div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">용도</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ usage }} </span></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">건축예정지</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><a :href="maplink" target="_blank"><span class="fen-set2"><u> {{ addr }} </u></span></a></div>
                    </div>
                    <div class="mib_box_a py-3" :style="this.$route.params.tskId? 'margin-bottom:10px' : ''">
                        <div class="mib_a1 text-end"><span class="fkr-set1">연락선호시간</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ preferredContactTime }} </span></div>
                    </div>

                    <a v-if="this.$route.params.tskId" @click="moveToPrj()" style="margin-left:10px; cursor: pointer;"><span style="font-size: smaller;"><u>후보프로젝트 바로가기</u></span></a>
                    <div v-else class="y_div_blank1rem"></div>
                    
                    <div class="x_div_end pt-4" style="display:flex; padding-top: 0 !important;">
                        <div class="px-2" v-if="recallYn"><span class="fkr-set1" style="color:orange"><i class="fas fa-phone-volume"></i></span></div>
                        <div class="px-2"><button class="fkr-set1" @click="recallYn = !recallYn" :disabled="($route.params.tskId && user.id != cnslrId) || sttsColor == 'green'" :style="'width:100px; height:27px; border-radius:4px; ' + (recallYn? 'background-color:#4e0000; border:0; color:#fff;' : 'background-color:#eee7e7; border:1px solid #4e0000; color:#4e0000;')">재연락 필요<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>

                    <div v-if="this.$route.params.tskId" class="y_div_blank1rem"></div>

                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">상담자</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"><span class="fen-set2"> {{ cnslrNm }} </span></div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">상담일시</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <Datepicker :disabled="(user.id != cnslrId) || recallYn || sttsColor == 'green'" :setDate="recentContactDate" @update-date="setContactDate" class="form-control fkr-set2 fc-w5 text-center" style="width:120px; height:27px; border-radius:4px; border:1px solid #c9c9c9"/>
                            &nbsp;&nbsp;
                            <select class="custom-select ff-eng1 fs-085" :disabled="(user.id != cnslrId) || recallYn || sttsColor == 'green'" v-model="recentContactTime" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option :value="time" v-for="(time, index) in selectTimeSet" :key="index">{{renderTime(time)}}</option>
                            </select>
                        </div>
                    </div>
                    <span v-if="recallYnClicked" :style="'color: red; font-size: smaller;' + (isMobile? '' : 'margin-left: 100px')"> 화면에 날짜가 남아있더라도 실제 상담일시 데이터는 빈 값으로 저장되니 신경쓰지 마세요 :)</span>
                    
                    <div class="mib_box_a py-3" style="border: 0">
                        <div class="mib_a1 text-end"><span class="fkr-set1">상담내용</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3"></div>
                    </div>
                    <div class="mib_box_a py-3" style="border: 0; padding: 0 0 16px 30px !important">
                        <textarea class="form-control fkr-set2" :readonly="(user.id != cnslrId) || this.recallYn" style="height:150px; max-height:300px" v-model="cnslCntnt"></textarea>
                    </div>
                    
                    <div class="mib_box_a py-3" style="border: 0; display: block !important;">
                        <div v-for="(bucket, i) in attachmentsFetchedList" :key="i" class="pg_a_set3" style="margin-top: 15px">
                            <div class="pg_a_set3a">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px;">
                                    <div :class="'x_div_center' + (bucket[0].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[0].mimeType) < 0" :style="'width: 100%; border:0px solid #c9c9c9; border-radius:8px;' + (bucket[0].deleted? 'opacity: 0.5;' : '')" :src="bucket[0].fileLink">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[0].fileLink" :download="bucket[0].prcAttchOrgNm">{{ bucket[0].prcAttchOrgNm }}</a></span>
                                        <div class="ig_d" v-show="user.id == cnslrId" style="left:90%"><a href="javascript:void(0)" @click="bucket[0].deleted = true; deleteFlIdList.push(bucket[0].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                    </div>
                                </div>
                            </div>
                            <div class="pg_a_set3c"></div>
                            <div class="pg_a_set3b">
                                <div class="card x_div_center" style="border:0px solid #c9c9c9; border-radius:8px">
                                    <div :class="'x_div_center' + (bucket[1].deleted? ' diagonal' : '')">
                                        <img v-if="['pdf', 'zip'].indexOf(bucket[1].mimeType) < 0" :style="'border:0px solid #c9c9c9; border-radius:8px;' + (bucket[1].deleted? 'opacity: 0.5;' : '')" :src="bucket[1].fileLink" width="100%">
                                        <span v-else class="fen-set2 fs-10"><a :href="bucket[1].fileLink" :download="bucket[1].prcAttchOrgNm">{{ bucket[1].prcAttchOrgNm }}</a></span>
                                        <div class="ig_d" v-show="user.id == cnslrId && bucket[1].id" style="left:90%"><a href="javascript:void(0)" @click="bucket[1].deleted = true; deleteFlIdList.push(bucket[1].id)"><img src="@/assets/img_legacy/r_del.png"></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="x_div_end py-4" style="padding:0 !important; margin-top: 20px;">
                        <button v-if="!$route.params.tskId || user.id == cnslrId" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;">
                            <label for="imgUpload" class="fa-kor-Noto fs-085 s-upload">파일 첨부</label>
                        </button>
                        <input type="file" style="display:none" id="imgUpload" name="imgUpload" @change="fileHandling" multiple>
                    </div>
                    
                    <div class="x_div_end py-4" style="padding:0 0 10px 0 !important; border-bottom: 1px solid #d9d9d9;">
                        <div style="display:block; margin-top: 10px;">
                            <span v-for="(file, i) in attachmentsNewList" :key="i" class="fen-set2 fs-10">{{file.orgNm}}&nbsp;<a href="javascript:void(0)" @click="removeFile(i)"><i class="fas fa-times-circle"></i></a>&nbsp;&nbsp;</span>
                        </div>
                    </div>
                    <div class="mib_box_a py-3">
                        <div class="mib_a1 text-end"><span class="fkr-set1">다음 담당자</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3">
                            <select class="custom-select ff-eng1 fs-085" :disabled="(user.id != cnslrId) || recallYn || sttsColor == 'green'" v-model="nextCnslrId" style="width:120px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option :value="cnslr.id" v-for="(cnslr, index) in cnslrLv1s" :key="index">{{cnslr.fullName}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="mib_box_a py-3" :style="'display: ' + ((user.id == cnslrId) && (user.id == nextCnslrId)? 'flex' : 'none') + ' !important'">
                        <div class="mib_a1 text-end"><span class="fkr-set1">다음 상담<br v-if="isMobile"> 예정일시</span></div>
                        <div class="mib_a2 x_div_center"></div>
                        <div class="mib_a3" style="display:flex;">
                            <Datepicker :disabled="(user.id != cnslrId) || recallYn" :setDate="scheduledContactDate" @update-date="setSchduledDate" class="form-control fkr-set2 fc-w5 text-center" style="width:120px; height:27px; border-radius:4px; border:1px solid #c9c9c9"/>
                            &nbsp;&nbsp;
                            <select class="custom-select ff-eng1 fs-085" :disabled="(user.id != cnslrId) || recallYn" v-model="scheduledContactTime" style="width:80px; height:27px; padding: 1px 0 1px 10px; margin:0 10px 0 0">
                                <option :value="time" v-for="(time, index) in selectTimeSet" :key="index">{{renderTime(time)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="x_div_center pt-4">
                        <div class="px-2" v-if="!this.$route.params.tskId"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="cancelCreation()">취소</button></div>
                        <div class="px-2" v-else><button class="fkr-set1" style="width:90px; height:37px; background-color:#eee7e7; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="moveToPrj()">목록</button></div>
                        <div class="px-2" v-if="user.id == cnslrId"><button class="fkr-set1" style="width:90px; height:37px; background-color:#4e0000; border:0; border-radius:4px; color:#fff;" @click="saveTaskAndPrj()">저장<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                    </div>
                    <div class="x_div_center pt-4" v-if="this.$route.params.tskId">
                        <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000;" @click="moveToTask(0)">이전</button></div>
                        <div class="px-2"><button class="fkr-set1" style="width:90px; height:37px; background-color:#4e0000; border:0; border-radius:4px; color:#fff;" @click="moveToTask(1)">다음</button></div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'TaskPage',
    components: {
        Datepicker
	},
    props: {
        user: {
            type: Object,
            default: null
        },
        isMobileDevice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fetching: true,
            submitting: false,
            isMobile: false,
            prjId: null,
            prjNm: null,
            sttsColor: '#337ab7',
            customerNm: '',
            phone: '',
            phoneDashed: '',
            email: '',
            usage: '',
            addr: '',
            maplink: '',
            visitChannel: null,
            totalGroundArea: null,
            floors: null,
            buildScheduleYear: null,
            buildScheduleMonth: null,
            structure: null,
            expectedCost: null,
            preferredContactTime: null,
            etc: null,
            taskOrder: 1,
            recallYn: null,
            recallYnClicked: false,
            cnslrId: null,
            cnslrNm: '',
            recentContactDate: null,
            recentContactTime: null,
            scheduledContactDateTime: null,
            scheduledContactDate: null,
            scheduledContactTime: null,
            cnslCntnt: '',
            nextCnslrId: null,
            editing: false,
            selectTimeSet: [],
            cnslrList: [],
            cnslrLv1s: [],
            attachmentsNewList: [],
            attachmentsFetchedList: [],
            deleteFlIdList: [],
            eventId: null,
            insertEvent: false,
            modifyEvent: false,
            tokenClient: null,
            taskIdList: [],
            apiKey: process.env.VUE_APP_GOOGLEAPI_APIKEY,
            clientId: process.env.VUE_APP_GOOGLEAPI_CLIENTID
        }
    },
    watch: {
        cnslCntnt(newVal, oldVal) {
            if(oldVal && !this.editing) {
                this.editing = true;
            }
        },
        recallYn(val, oldVal) {
            if(val) {
                if(oldVal == false) {
                    this.recentContactDate = null;
                    this.recentContactTime = null;
                    this.nextCnslrId = null
                    this.recallYnClicked = true;
                    this.submitting = true;
                    this.saveTaskAndPrj();
                }
            }
            else {
                if(!this.$route.params.tskId || oldVal != null) {
                    this.recentContactDate = new Date().toISOString();
                    var recentContactTime = new Date(new Date().getTime() + 9*3600*1000).toISOString().split('T')[1];
                    this.recentContactTime = parseInt(recentContactTime.split(':')[0])*60 + parseInt(recentContactTime.split(':')[1]) - parseInt(recentContactTime.split(':')[1])%15;
                    this.recallYnClicked = false;
                }
            }
        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = this.$route.params.tskId? '/member/login?task=' + this.$route.params.tskId + '&order=' + this.$route.query.order : '/member/login';
        }

        if(!this.user.info.cnslrLv) {
            alert('접근권한이 없습니다.');
            location.href = '/';
        }

        this.isMobile = window.innerWidth < 768;

        for(var i=0; i < 24*4; i++) {
            this.selectTimeSet.push(i*15);
        }

        const rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/all?option=1');
        if(rtnval.result){
            this.cnslrList = rtnval.data.findAllUsrDtoList;
            this.cnslrLv1s = this.cnslrList.filter((val) => val.cnslrLv == 1).sort((a,b) => a.id - b.id);
            this.cnslrLv1s = this.cnslrLv1s.concat({id: -999, cnslrLv: 1, fullName: '프로젝트종료'})
            this.fetching = false;
        }
        else {
            alert(rtnval.errormsg);
        }

        if(this.$route.params.tskId) {
            this.fetching = true;
            const rtnval = await Api.post('/api/v1/task/find/task', {taskId: this.$route.params.tskId});
            if(rtnval.result){
                this.prjId = rtnval.data.prjId;
                this.recallYn = rtnval.data.recallYn;
                this.cnslrId = rtnval.data.cnslr;
                this.cnslrNm = rtnval.data.cnslrNm;
                this.recentContactDate = rtnval.data.recentContactTime;
                var recentContactTime = this.recentContactDate? this.recentContactDate.split('T')[1] : null;
                this.recentContactTime = recentContactTime? parseInt(recentContactTime.split(':')[0])*60 + parseInt(recentContactTime.split(':')[1]) : null;
                this.scheduledContactDate = rtnval.data.scheduledContactTime;
                this.scheduledContactDateTime = rtnval.data.scheduledContactTime;
                var scheduledContactTime = this.scheduledContactDate? this.scheduledContactDate.split('T')[1] : null;
                this.scheduledContactTime = scheduledContactTime? parseInt(scheduledContactTime.split(':')[0])*60 + parseInt(scheduledContactTime.split(':')[1]) : null;
                this.cnslCntnt = rtnval.data.cnslCntnt;
                this.nextCnslrId = rtnval.data.nextCnslr == null? null : rtnval.data.nextCnslr;
                this.eventId = rtnval.data.eventId;
                this.taskOrder = this.$route.query.order;
                const rtnvalue = await Api.post('/api/v1/primprj/find/primprj', {primPrjId: this.prjId});
                if(rtnvalue.result){
                    this.customerNm = rtnvalue.data.customerNm;
                    this.phone = rtnvalue.data.phone;
                    this.email = rtnvalue.data.email;
                    this.usage = rtnvalue.data.usage;
                    this.addr = rtnvalue.data.addr;
                    this.preferredContactTime = rtnvalue.data.preferredContactTime;
                }
                else {
                    alert(rtnvalue.errormsg);
                }

                var biddingAttchFiles = [];
                if(rtnval.data.attachFiles) {
                    for(i=0; i<rtnval.data.attachFiles.length; i++) {
                        var taskAttchFlCtgry = rtnval.data.attachFiles[i].taskAttchFlCtgry.code;
                        if( taskAttchFlCtgry == "TASKBS" ) {
                            biddingAttchFiles.push(rtnval.data.attachFiles[i])
                        }
                    }
                }
                var tempBucket = [];
                var k=0;
                if(biddingAttchFiles) {
                    for(i=0; i<biddingAttchFiles.length; i++) {
                        var tokens = biddingAttchFiles[i].attchNm.split('.');
                        var mimeType = tokens[tokens.length-1].toLowerCase();
                        var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
    
                        const byteCharacters = atob(biddingAttchFiles[i].attchData);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let j = 0; j < byteCharacters.length; j++) {
                            byteNumbers[j] = byteCharacters.charCodeAt(j);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], {type: fileType});
                        const fileLink = URL.createObjectURL(blob);

                        tempBucket.push({id: biddingAttchFiles[i].id, prcAttchOrgNm: biddingAttchFiles[i].attchNm, fileLink: fileLink, mimeType: mimeType, deleted: false})
                        if((k++)%2 == 1) {
                            this.attachmentsFetchedList.push(tempBucket);
                            tempBucket = [];
                        }
                        else {
                            if(i == biddingAttchFiles.length -1) {
                                tempBucket.push({id: null, prcAttchOrgNm: null, fileLink: "/./files/img_legacy/nofileimage.jpg", deleted: false});
                                this.attachmentsFetchedList.push(tempBucket);
                            }
                        }
                    }
                }
            }
            else {
                alert(rtnval.errormsg);
            }

            this.fetching = false;

            this.taskIdList = sessionStorage.getItem('taskIdList').split(',');

            var prjNmHeadArray = this.addr? this.addr.split(' ').filter((token) => token[token.length-1] == '시' || token[token.length-1] == '군') : null
            this.prjNm = prjNmHeadArray? prjNmHeadArray[0] + ' ' + this.customerNm : this.customerNm;

            const phone = this.phone;
            if(phone) {
                if(phone.length == 10) {
                    this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
                }
                else{
                    this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
                }
            }

            this.maplink = this.isMobileDevice? 'https://m.map.naver.com/search2/search.naver?query=' + this.addr + '#/map' : 'https://map.naver.com/search?query=' + this.addr

            this.sttsColor = this.recallYn? 'orange' : (this.recentContactDate? 'green' : '#337ab7')
        }
        else {
            const newPrimPrjObj = JSON.parse(sessionStorage.getItem('newPrimPrjObj'));
            this.prjId = newPrimPrjObj.primPrjId;
            this.customerNm = newPrimPrjObj.customerNm;
            this.phone = newPrimPrjObj.phone;
            this.email = newPrimPrjObj.email;
            this.usage = newPrimPrjObj.usage;
            this.addr = newPrimPrjObj.addr;
            this.visitChannel = newPrimPrjObj.visitChannel;
            this.totalGroundArea = newPrimPrjObj.totalGroundArea;
            this.floors = newPrimPrjObj.floors;
            this.buildScheduleYear = newPrimPrjObj.buildScheduleYear;
            this.buildScheduleMonth = newPrimPrjObj.buildScheduleMonth;
            this.structure = newPrimPrjObj.structure;
            this.expectedCost = newPrimPrjObj.expectedCost;
            this.preferredContactTime = newPrimPrjObj.preferredContactTime;
            this.etc = newPrimPrjObj.etc;
            this.recallYn = false;
            this.taskOrder = this.prjId? this.$route.query.order : 1;
            prjNmHeadArray = this.addr? this.addr.split(' ').filter((token) => token[token.length-1] == '시' || token[token.length-1] == '군') : null
            this.prjNm = prjNmHeadArray? prjNmHeadArray[0] + ' ' + this.customerNm : this.customerNm;

            const phone = this.phone;
            if(phone) {
                if(phone.length == 10) {
                    this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
                }
                else{
                    this.phoneDashed = phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
                }
            }

            this.maplink = this.isMobileDevice? 'https://m.map.naver.com/search2/search.naver?query=' + this.addr + '#/map' : 'https://map.naver.com/search?query=' + this.addr
        }
        
        this.cnslrId = this.cnslrId? this.cnslrId : this.user.id;
        this.cnslrNm = this.cnslrNm? this.cnslrNm : this.user.info.fullName;
        if(!this.recentContactDate && !this.recallYn) {
            this.recentContactDate = this.user.id == this.cnslrId? new Date().toISOString() : null
            recentContactTime = this.user.id == this.cnslrId? new Date(new Date().getTime() + 9*3600*1000).toISOString().split('T')[1] : null;
            this.recentContactTime = this.user.id == this.cnslrId? parseInt(recentContactTime.split(':')[0])*60 + parseInt(recentContactTime.split(':')[1]) - parseInt(recentContactTime.split(':')[1])%15 : null;
        }

        const CLIENT_ID = this.clientId;
        const API_KEY = this.apiKey;

        // Discovery doc URL for APIs used by the quickstart
        const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';

        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

        window.gapi.load('client', initializeGapiClient);

        // eslint-disable-next-line
        async function initializeGapiClient() {
            await window.gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
            });
        }

        this.tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: '',
        });

    },
    methods: {
        renderTime(time) {
            return String(Math.floor(time / 60)).padStart(2, "0") + ':' + String(time % 60).padStart(2, "0")
        },
        setContactDate(date) {
            this.recentContactDate = new Date(date).toISOString();
        },
        setSchduledDate(date) {
            this.scheduledContactDate = new Date(date).toISOString();
        },
        fileHandling(e) {
            for(var i=0; i<e.target.files.length; i++) {
                var tokens = e.target.files[i].name.split('.');
                var fileType = tokens[tokens.length-1].toLowerCase();
                var allowed = ['jpg', 'jpeg', 'png', 'pdf', 'zip']
                if(allowed.indexOf(fileType) < 0){
                    alert('이미지파일 또는 pdf형식 외의 파일은\nzip 형태로 압축하여 업로드 해 주시기 바랍니다.');
                    return 1;
                }
            }

            for(i=0; i<e.target.files.length; i++) {
                this.attachmentsNewList.push({file: e.target.files[i], orgNm: e.target.files[i].name})
            }
        },
        removeFile(i) {
            this.attachmentsNewList.splice(i,1);
        },
        async saveTaskAndPrj() {
            if(this.recentContactDate && (!this.cnslCntnt || !this.nextCnslrId)) {
                alert('상담내용을 기록하고 다음 담당자를 지정해주세요.\n프로젝트를 종료할 경우 다음 담당자 목록에서 \'프로젝트종료\'를 선택하세요.')
                return;
            }

            var recentContactTime = this.recentContactDate? (this.recentContactDate.split('T')[0] + 'T' + this.renderTime(this.recentContactTime) + ':00') : null;
            var scheduledContactTime = this.scheduledContactDate? (this.scheduledContactDate.split('T')[0] + 'T' + this.renderTime(this.scheduledContactTime) + ':00') : null;
            if(scheduledContactTime && scheduledContactTime != this.scheduledContactDateTime) {
                this.insertEvent = true;
                if(this.eventId) {
                    this.modifyEvent = true;
                }
            }
            
            this.submitting = true;
            if(!this.$route.params.tskId) {
                const rtnval = await Api.post('/api/v1/primprj/set/primprj', {
                    prjId: this.prjId,
                    addr: this.addr,
                    buildScheduleMonth: this.buildScheduleMonth,
                    buildScheduleYear: this.buildScheduleYear,
                    cnslCntnt: this.cnslCntnt,
                    customerNm: this.customerNm,
                    email: this.email,
                    etc: this.etc,
                    expectedCost: this.expectedCost,
                    floors: this.floors,
                    phone: this.phone,
                    preferredContactTime: this.preferredContactTime,
                    recallYn: this.recallYn,
                    recentContactTime: recentContactTime,
                    scheduledContactTime: scheduledContactTime,
                    structure: this.structure,
                    task2CnslrId: this.nextCnslrId,
                    totalGroundArea: this.totalGroundArea,
                    usage: this.usage,
                    visitChannel: this.visitChannel
                });
                if(rtnval.result){
                    const taskId = rtnval.data.taskId;
                    if(this.attachmentsNewList.length > 0 || this.deleteFlIdList.length > 0) {
                        var request = {
                            parentId: [],
                            attchFlCtgry: [],
                            deleteFlIdList: this.deleteFlIdList,
                            attchOrgNmList: []
                        }
                        const formData = new FormData();
                        this.attachmentsNewList.forEach((file) => {
                            request.attchOrgNmList.push(file.orgNm);
                            request.attchFlCtgry.push('TASKBS');
                            request.parentId.push(taskId);
                            formData.append("attchmntList", file.file);
                        })
                        formData.append(
                            "request",
                            new Blob([JSON.stringify(request)], { type: "application/json" })
                        );
                        const rtnvalue = await Api.post('/api/v1/common/set/attachments', formData);
                        if(rtnvalue.result) {
                            alert(this.prjId? '프로젝트가 재개시되었습니다.' : '후보 프로젝트 생성 및 TASK1이 완료되었습니다.');
                            sessionStorage.removeItem('newPrimPrjObj')
                            this.callGoogleOauth(scheduledContactTime, '/panda/prj/' + rtnval.data.prjId + '?nextCnslrId=' + this.nextCnslrId);
                        }
                        else {
                            alert(rtnvalue.errormsg + "\n첨부파일을 다시 업로드하신 후 저장하세요.")
                            sessionStorage.removeItem('newPrimPrjObj')
                            location.href = '/panda/task/' + taskId + '?order=1';
                        }
                    }
                    else {
                        alert(this.prjId? '프로젝트가 재개시되었습니다.' : '후보 프로젝트 생성 및 TASK1이 완료되었습니다.');
                        sessionStorage.removeItem('newPrimPrjObj')
                        this.callGoogleOauth(scheduledContactTime, '/panda/prj/' + rtnval.data.prjId + '?nextCnslrId=' + this.nextCnslrId);
                    }
                }
                else {
                    alert(rtnval.errormsg);
                    location.href = '/panda/prj-edit'
                }
            }
            else {
                if(this.attachmentsNewList.length > 0 || this.deleteFlIdList.length > 0) {
                    request = {
                        parentId: [],
                        attchFlCtgry: [],
                        deleteFlIdList: this.deleteFlIdList,
                        attchOrgNmList: []
                    }
                    const formData = new FormData();
                    this.attachmentsNewList.forEach((file) => {
                        request.attchOrgNmList.push(file.orgNm);
                        request.attchFlCtgry.push('TASKBS');
                        request.parentId.push(this.$route.params.tskId);
                        formData.append("attchmntList", file.file);
                    })
                    formData.append(
                        "request",
                        new Blob([JSON.stringify(request)], { type: "application/json" })
                    );
                    const rtn = await Api.post('/api/v1/common/set/attachments', formData);
                    if(!rtn.result) {
                        alert(rtn.errormsg)
                    }
                }

                const rtnvalue = await Api.post('/api/v1/task/update/task', {
                    cnslCntnt: this.cnslCntnt,
                    nextCnslr: this.nextCnslrId,
                    recallYn: this.recallYn,
                    recentContactTime: recentContactTime,
                    scheduledContactTime: scheduledContactTime,
                    taskId: this.$route.params.tskId
                });
                if(rtnvalue.result){
                    if(this.sttsColor != 'green') {
                        const returnvalue = await Api.post('/api/v1/primprj/update/primprj', {
                            primPrjId: this.prjId,
                            phone: this.phone,
                            email: this.email,
                            recentContactTime: recentContactTime,
                            recentCnslrId: this.nextCnslrId == -999 || this.recallYn? this.user.id : this.nextCnslrId,
                            stts: this.recallYn? 2 : 1
                        });
                        if(returnvalue.result){
                            alert('저장되었습니다.');
                            this.callGoogleOauth(scheduledContactTime, '/panda/prj/' + this.prjId + '?nextCnslrId=' + this.nextCnslrId);
                        }
                        else {
                            alert(returnvalue.errormsg);
                        }
                    }
                    else {
                        alert('저장되었습니다.');
                        this.callGoogleOauth(scheduledContactTime, '/panda/prj/' + this.prjId);
                    }
                }
                else {
                    alert(rtnvalue.errormsg);
                }
                this.submitting = false;
            }
        },
        callGoogleOauth(scheduledContactTime, rtnUrl) {
            if(!this.insertEvent) {
                location.href = rtnUrl;
            }
            else {
                alert((this.modifyEvent? '구글 캘린더의 예약일정을 수정합니다.' : '다음 상담 예약일정을 구글 캘린더에 등록합니다.') + '\nM3 구글계정 인증을 진행해주세요.')
                this.handleAuthTrigger(scheduledContactTime, rtnUrl);
            }
        },
        handleAuthTrigger(dateTime, rtnUrl) {
            this.tokenClient.callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }
                await this.insertCalendarEvent(dateTime, rtnUrl)
            };

            this.tokenClient.error_callback = async (resp) => {
                if (resp.error !== undefined) {
                    throw (resp);
                }
                location.href = rtnUrl;
            };

            if (window.gapi.client.getToken() === null) {
                // Prompt the user to select a Google Account and ask for consent to share their data
                // when establishing a new session.
                this.tokenClient.requestAccessToken({prompt: 'consent'});
            } else {
                // Skip display of account chooser and consent dialog for an existing session.
                this.tokenClient.requestAccessToken({prompt: ''});
            }
        },
        async insertCalendarEvent(dateTime, rtnUrl) {
            try {
                var request = {
                    calendarId: 'primary',
                    summary: this.prjNm + ' ' + (parseInt(this.taskOrder)+1) + '차 상담',
                    start: {
                        dateTime: dateTime,
                        timeZone: "Asia/Seoul"
                    },
                    end: {
                        dateTime: new Date(new Date(dateTime).getTime() + 3600000).toISOString(),
                        timeZone: "Asia/Seoul"
                    },
                };
                const rtnval = await window.gapi.client.calendar.events.insert(request);
                if(rtnval.status == 200) {
                    await Api.post('/api/v1/task/update/task', {
                        eventId: JSON.parse(rtnval.body).id,
                        scheduledContactTime: dateTime,
                        nextCnslr: this.nextCnslrId,
                        taskId: this.$route.params.tskId
                    });
                    if(this.modifyEvent) {
                        request = {
                            calendarId: 'primary',
                            eventId: this.eventId
                        }
                        await window.gapi.client.calendar.events.delete(request);
                    }
                    alert('구글 캘린더에 일정이 등록되었습니다.')
                }
                else {
                    alert(rtnval.status + ' Error')
                }
            } catch (err) {
                alert(err)
            }
            location.href = rtnUrl;
        },
        cancelCreation() {
            sessionStorage.removeItem('newPrimPrjObj');
            location.href = '/panda';
        },
        moveToPrj() {
            if(this.editing) {
                if(confirm('저장하지않은 변경사항이 있습니다.\n내용을 저장하지않고 되돌아가시겠습니까?')) {
                    location.href = '/panda/prj/' + this.prjId;
                }
            }
            else {
                location.href = '/panda/prj/' + this.prjId;
            }
        },
        moveToTask(next) {
            var index = Number(this.$route.query.order) -1;
            if(next) {
                if(index == this.taskIdList.length -1) {
                    alert('목록의 마지막 TASK입니다.');
                }
                else {
                    location.href = '/panda/task/' + this.taskIdList[index+1] + '?order=' + (index+2);
                }
            }
            else {
                if(index == 0) {
                    alert('목록의 첫번째 TASK입니다.');
                }
                else {
                    location.href = '/panda/task/' + this.taskIdList[index] + '?order=' + index;
                }
            }
        }
    }
}
</script>

<style scoped>
li {
    margin: 5px 15px 5px 0;
}
.diagonal {
    width: 100%;
    height: 100%;
    background: linear-gradient(
    to top right,
    #fff calc(50% - 1px),
    red,
    #fff calc(50% + 1px)
    );
}
</style>