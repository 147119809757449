<template>
    <div class="m3_contetns" style="margin-top: 50px">
        <div class="mib_mod">
            <div class="w-100">
                <div v-if="!fetching" class="x_div_center pb-3" style="text-align: justify; display: block !important;">
                    <span v-if="user.info.cnslrLv == 1" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" onclick="location.href='/panda/prj-edit'"><i class="fa fa-pen"></i></span>
                    <select v-else-if="!isMobile" class="custom-select ff-eng1 fs-085" v-model="ctgry" @change="switchCtgry()" style="position:absolute; width:120px; height:27px; padding: 1px 0 1px 10px; margin:40px 10px 0 0">
                        <option value="0">전체</option>
                        <option value="1">M House</option>
                        <option value="2">M Office</option>
                        <option value="3">M School</option>
                    </select>
                    <span v-else-if="ctgry == 0" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 1; switchCtgry()"><b>ALL</b></span>
                    <span v-else-if="ctgry == 1" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 2; switchCtgry()"><i class="fas fa-home"></i></span>
                    <span v-else-if="ctgry == 2" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 3; switchCtgry()"><i class="fas fa-building"></i></span>
                    <span v-else-if="ctgry == 3" class="fkr-set2 fs-10" :style="'position:relative; padding-left: 10px; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="ctgry = 0; switchCtgry()"><i class="fas fa-school"></i></span>
                    <span v-if="user.info.cnslrLv == 1" class="fkr-set2 fs-10" :style="'position:relative; left: 10%; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" @click="toggleMyList()"><b>{{ myList? 'ALL' : 'MY' }}</b></span>
                    <span class="fkr-set2 fs-10" :style="'position:relative; left: 20%; cursor:pointer; ' + (buildMode == 'production'? 'top:20px' : '')" onclick="location.href='/panda/prj-dashboard'"><i class="fas fa-newspaper"></i></span>
                    <img :style="'position:relative; width:100px;' + (user.info.cnslrLv != 1 && !isMobile? 'margin-left:39px' : '')" src="@/assets/img_legacy/panda_logo.jpg">
                    <span class="fkr-set2 fs-10" id="search_tooltip" style="position:relative; float: right; bottom:-40px; cursor:pointer"><i v-if="!searchWord" onclick="alert('주소, 고객명 및 지금까지의 상담에 참여한 담당자들의 이름을 대상으로 프로젝트를 검색합니다.')" class="far fa-question-circle"></i><i v-else class="fa fa-undo" @click="searchWord = null; searchPrj()"></i></span>
                    <div class="x_div_center" style="position:relative; float: right; bottom:-35px; width:170px; height:34px; border:1px solid #d6d6d6; border-radius:4px;">
                        <div style="padding:3px 15px 0 0; border-right:1px solid #d6d6d6" @click="searchPrj()"><i class="fa-solid fa-magnifying-glass" style="cursor:pointer"></i></div>
                        <div style="padding:0 0 0 5px"><input type="text" v-model="searchWord" class="form-control fa-kor-Noto" @keyup.enter="searchPrj()" style="border:0; width:100px; height:30px; padding:0 .3rem; opacity: 0.7;"></div>
                    </div>
                </div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else class="pid_2">
                    <div class="mib_box_c">
                        <div class="x_div_center" style="height:36px; border-top-left-radius:.3rem; border-top-right-radius:.3rem; background-color: #4e0000;">
                            <div class="x_div_center" style="width:40%"><span class="fkr-set2 fc-w1 fs-075">프로젝트</span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(1)" v-html="'상태' + (sortOption[1] == 0? sortMark : (sortOption[1] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(2)" v-html="'최근 연락' + (sortOption[2] == 0? sortMark : (sortOption[2] == 1? sortDown : sortUp))"></span></div>
                            <div class="x_div_center" style="width:20%"><span class="fkr-set2 fc-w1 fs-075" style="cursor:pointer" @click="sortTable(3)" v-html="'현재 담당자' + (sortOption[3] == 0? sortMark : (sortOption[3] == 1? sortDown : sortUp))"></span></div>
                        </div>
                        
                        <div class="x_div_center ls_bg1" style="height:45px; cursor: pointer;" v-for="(prj, index) in prjList" :key="index" @click="moveToPrj(prj.primPrjId)">
                            <div class="x_div px-1" style="width:40%"><span class="fkr-set2 fs-085" style="text-align: left; margin-left: 10px;"> {{ prj.prjNm }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;" v-html="prj.status"></span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ renderDateTimeFormat(prj.recentContactTime) }} </span></div>
                            <div class="x_div_center px-1" style="width:20%"><span class="fkr-set2 fs-085" style="text-align: center;"> {{ prj.currentCnslr? prj.currentCnslr : '' }}</span></div>
                        </div>

                        <div class="x_div_center py-2" style="margin-top:5px">
                            <a class="px-2 fs-10" @click="switchPage(-1)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angles-left"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(0)" style="cursor:pointer; margin-right: 20px;"><i class="fa-solid fa-angle-left"></i></a>
                            <a v-show="!isMobile" v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; saveSessionData(); fetchData();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                            <span v-if="!isMobile && pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                            <a class="px-2 fs-10" @click="switchPage(1)" style="cursor:pointer; margin-right: 20px; margin-left: 20px;"><i class="fa-solid fa-angle-right"></i></a>
                            <a class="px-2 fs-10" @click="switchPage(2)" style="cursor:pointer;"><i class="fa-solid fa-angles-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'
export default {
    name: 'PrjList',
    data() {
        return {
            fetching: true,
            isMobile: false,
            myList: false,
            numOfPrj: 0,
            prjList: [],
            sortOption: [0,0,0,0],
            pageQueue: [],
            currentPage: 1,
            numOfPage: 0,
            pageSize: 10,
            ctgry: 0,
            searchWord: null,
            sortMark: '<i class="fas fa-sort" style="color:#795c5c; margin-left:5px"></i>',
            sortUp: '<i class="fas fa-sort-up" style="margin-left:5px"></i>',
            sortDown: '<i class="fas fa-sort-down" style="margin-left:5px"></i>',
            buildMode: process.env.NODE_ENV
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            location.href = '/member/login'
        }

        if(!this.user.info.cnslrLv) {
            alert('접근권한이 없습니다.');
            location.href = '/';
        }

        this.isMobile = window.innerWidth < 768;

        if(sessionStorage.getItem('ctgry')) {
            this.ctgry = parseInt(sessionStorage.getItem('ctgry'));
            // this.pageSize = parseInt(sessionStorage.getItem('pageSize'));
            this.currentPage = sessionStorage.getItem('currentPage')? parseInt(sessionStorage.getItem('currentPage')) : 1;
            this.searchWord = sessionStorage.getItem('searchWord') == 'null'? null : sessionStorage.getItem('searchWord');
            this.myList = sessionStorage.getItem('myList') == 'true'? true : false;
            var sortOption = sessionStorage.getItem('sortOption')? sessionStorage.getItem('sortOption').split(',') : [0,0,0,0];
            for(var i=0; i<4; i++) {
                this.sortOption[i] = parseInt(sortOption[i]);
            }
        }

        await this.fetchData();
        this.setPaging();
    },
    methods: {
        async fetchData() {
            this.prjList = [];
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/primprj/list/primprj', {
                sortColumn: this.sortOption.indexOf(1) >0? this.sortOption.indexOf(1) : (this.sortOption.indexOf(2) >0? this.sortOption.indexOf(2) : 0),
                asc: this.sortOption.indexOf(1) >0? false : (this.sortOption.indexOf(2) >0? true : false),
                usrId: this.myList? this.user.id : null,
                ctgry: this.ctgry,
                pageSize: this.pageSize,
                page: this.currentPage,
                searchWord: this.searchWord
            });
            if(rtnvalue.result){
                this.numOfPrj = rtnvalue.data.count; 
                this.prjList = rtnvalue.data.findPrimPrjDtoList;
                this.prjList.forEach((prj) => {
                    var prjNmHeadArray = prj.addr? prj.addr.split(' ').filter((token) => token[token.length-1] == '시' || token[token.length-1] == '군') : null
                    var status = '';
                    switch(prj.stts) {
                        case 1 : status = '<i class="fa fa-phone" style="color:#337ab7"></i>&nbsp;&nbsp;상담중'
                            break;
                        case 2 : status = '<i class="fa fa-phone" style="color:orange"></i>&nbsp;&nbsp;상담중'
                            break;
                        case 3 : status = '<i class="fa fa-stamp" style="color:green"></i>&nbsp;&nbsp;<b>계&nbsp;&nbsp;&nbsp;&nbsp;약</b>'
                            break;
                        case 4 : status = '<i class="fas fa-power-off" style="color:lightsalmon"></i>&nbsp;&nbsp;<span style="color:lightgrey">종&nbsp;&nbsp;&nbsp;&nbsp;료</span>'
                            break;
                    }
                    prj.prjNm = prjNmHeadArray? prjNmHeadArray[0].replace('특별', '').replace('광역', '').replace('자치', '') + ' ' + prj.customerNm : prj.customerNm;
                    prj.currentCnslr = prj.recentCnslrNm;
                    prj.status = status;
                })
            }
            else {
                alert(rtnvalue.errormsg);
            }
            this.fetching = false;
        },

        setPaging() {
            this.numOfPage = Math.ceil(this.numOfPrj / this.pageSize);
            this.pageQueue = [];
            if(this.currentPage < 11) {
                for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
                this.pageQueue.push(i);
                }
            }
            else {
                for(i = this.currentPage - 9; i <= this.currentPage; i++) {
                this.pageQueue.push(i);
                }
            }
        },

        async switchPage(mode) {
            var render = false;
            switch(mode) {
                case -1 : if(this.currentPage > 1) {
                            this.currentPage = 1; 
                            render = true;
                        }
                break;
                case 0 : if(this.currentPage > 1) {
                            this.currentPage--;
                            render = true;
                        }
                break;
                case 1 : if(this.currentPage < this.numOfPage) {
                            this.currentPage++;
                            render = true;
                        }
                break;
                case 2 : if(this.currentPage < this.numOfPage) {
                            this.currentPage = this.numOfPage;
                            render = true;
                        } 
            }
            if(render) {
                this.saveSessionData();
                await this.fetchData();
                this.setPaging();
            }
        },

        async toggleMyList() {
            this.myList = !this.myList;
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async switchCtgry() {
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async searchPrj() {
            this.currentPage = 1;
            this.saveSessionData();
            await this.fetchData();
            this.setPaging();
        },

        async sortTable(col) {
            for(var i=0; i < this.sortOption.length; i++) {
                if(i != col) {
                    this.$set(this.sortOption, i, 0);
                }
                else {
                    this.$set(this.sortOption, i, (this.sortOption[i]+1)%3);
                    this.currentPage = 1;
                    this.saveSessionData();
                    await this.fetchData();
                    this.setPaging();
                }
            }
        },

        moveToPrj(prjId) {
            location.href = '/panda/prj/' + prjId;
        },
        
        saveSessionData() {
            sessionStorage.setItem('sortOption', this.sortOption);
            sessionStorage.setItem('myList', this.myList);
            sessionStorage.setItem('ctgry', this.ctgry);
            // sessionStorage.setItem('pageSize', this.pageSize);
            sessionStorage.setItem('currentPage', this.currentPage);
            sessionStorage.setItem('searchWord', this.searchWord);
        },

        renderDateTimeFormat(dateTime) {
            if(dateTime) {
                if(this.isMobile) {
                    var date = dateTime.split('T')[0];
                    return date.split('-')[1] + '-' + date.split('-')[2];
                }
                else {
                    return dateTime.split('T')[0] + ' | ' + dateTime.split('T')[1];
                }
            }
            else {
                return null
            }
        }
    }
}
</script>

<style scoped>
img {
    left:39%;
}
#search_tooltip {
    right:25%;
}
@media screen and (max-width: 768px) {
    img {
        left:55%;
    }   
    #search_tooltip {
        right:55%;
    }
}

</style>
