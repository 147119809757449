<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="sw-1">
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-generate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-generate'"><span class="fkr-set1 fs-095">1. 참여 의향 요청</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-candidate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-candidate'"><span class="fkr-set1 fs-095">2. 후보 업체 선정</span></div>
                        </div>
                    </div>
                    <div class="sw-2" style="border-bottom:1px solid #c9c9c9">
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/bidding-evaluate') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/bidding-evaluate'"><span class="fkr-set1 fs-095">3. 후보 업체 평가 및 선정</span></div>
                        </div>
                        <div class="sw-4">
                            <div :class="'x_div_center pg_tab' + (url.indexOf('/customer/contract') >= 0 ? '_on' : '')" style="height:50px" onclick="location.href='/project/bidding/customer/contract'"><span class="fkr-set1 fs-095">4. 계약</span></div>
                        </div>
                    </div>
				</div>

				<div class="x_div_center py-5">
                    <div class="pg_m_set" v-if="fetching">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
					<div class="text-center" v-else>
                        <span class="fkr-set2 fs-10" v-if="isRankFixed"></span>
                        <span class="fkr-set2 fs-10" v-else>
                            선정된 후보 파트너기관들이 <span class="fw-bold" style="color:#4e0000">{{ bddngProposalDdln? bddngProposalDdln.split('T')[0] : '' }}일</span> 까지 <br v-if="isMobile"/> 제안을 제출할 예정입니다.<br>
                            제안 제출 마감기간 전 까지 본 페이지에서 <br v-if="isMobile"/> 각 파트너기관들의 제안제출내역을 <br v-if="isMobile"/>실시간으로 확인하실 수 있습니다.<br>
                            선정의 공정성을 위해 파트너기관 이름은 노출되지 않습니다. <br v-if="isMobile"/> 제출된 제안들을 검토하시고 각 제안의 순위를 결정해주세요.<br>
                            선정 후 파트너기관의 이름, 연락처, 홈페이지가 오픈됩니다. <br v-if="isMobile"/> 1위로 선정하신 파트너기관은 계약을 위한 우선협상대상자로 지정됩니다.<br>
						</span>
					</div>
				</div>

				<div>
					<div class="bs_tab">
						<div class="bs_tab1" v-for="(cand, i) in candidatesList" :key="i" style="border:1px solid #c9c9c9; margin-bottom: 15px;">
							<table class="table-borderless w-100" style="table-layout:fixed;">
							<tr style="height:55px; border-bottom:1px solid #c9c9c9; background-color:#eaeaea">
								<td colspan="2" class="text-center" width="32%"><span class="ff-kor1" style="font-size:12pt"><strong>{{cand.candidateName}}</strong></span></td>
							</tr>
							<tr style="border-bottom:1px solid #c9c9c9">
								<td colspan="2" class="text-center"><div class="py-3 px-2"><a class="picture" :href="cand.imgPath" target="_blank"></a></div></td>
							</tr>
							<tr style="height:55px; border-bottom:1px solid #c9c9c9;">
								<td colspan="2" class="text-center"><a :href="cand.fileLink" :download="cand.attchNm"><span class="ff-kor1">{{cand.attchNm}}</span></a></td>
							</tr>
							<tr style="height:70px; border-bottom:1px solid #c9c9c9;">
								<td class="text-center br_0"><span class="ff-kor1 fw-bold">연락처</span><br><span class="fen-set2">{{ phoneNumberFormatter(cand.candidatePhone) }}</span></td>
								<td class="text-center"><span class="ff-kor1 fw-bold">홈페이지</span><br><a :href="cand.candidateHmpg" target="_blank"><span class="fen-set2">{{ cand.candidateHmpg }}</span></a></td>
							</tr>
							<tr style="height:55px; border-bottom:1px solid #c9c9c9;">
								<td colspan="2" class="text-center">
                                    <span :class="'ff-kor1' + (cand.evalRank == 1? ' fw-bold' : '')" v-if="isRankFixed">{{ cand.evalRank + '순위' }}</span>
									<div v-else class="x_div_center custom-control custom-radio">
										<div class="px-3" v-for="(c, index) in candidatesList" :key="index">
											<div class="radio_box">
                                                <input type="radio" :disabled="isRankConfirmed" :id="'radio_' + cand.bddngId + '_' + index" :name="'radio_' + cand.bddngId" class="custom-control-input" :value="index+1" v-model="cand.evalRank">
                                                <label class="custom-control-label" :for="'radio_' + cand.bddngId + '_' + index">
                                                    <span class="fkr-set2">{{(index+1)}}순위</span>
                                                </label>
                                            </div>
										</div>
									</div>
								</td>
							</tr>
                            <tr style="height:55px" v-if="isRankFixed">
								<td colspan="2" class="text-center">
									<div class="x_div_center custom-control custom-radio">
										<div class="px-3" v-for="(c, index) in candidatesList" :key="index">
											<div class="radio_box">
                                                <input type="radio" :disabled="isPriorityConfirmed" :id="'radio_' + cand.bddngId + '_' + index" :name="'radio_' + cand.bddngId" class="custom-control-input" :value="index+1" v-model="cand.cntrctPriority">
                                                <label class="custom-control-label" :for="'radio_' + cand.bddngId + '_' + index">
                                                    <span class="fkr-set2">{{(index == 0? '계약대상' : index+1 + '순위')}}</span>
                                                </label>
                                            </div>
										</div>
									</div>
								</td>
							</tr>
							</table>
						</div>
					</div>
<!--					<div style="height:50px"></div>-->
				</div>

                <div class="x_div_center py-5">
                    <div class="pg_m_set" v-if="fetching">
                        <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                    </div>
                    <div class="text-center" v-else>
						<span class="fkr-set2 fs-10" v-if="isRankFixed">
                            1순위로 선정하신 파트너 기관부터 연락하셔서 협상을 시작하세요.<br>
                            <span class="fw-bold" style="color:#4e0000">협상 시 비용, 기간 등 중요한 내용들까지 <br v-if="isMobile"/>반드시 확인하세요.</span><br>
                            협상에 어려움이 있을 경우 M3 Systems에 문의해주세요.(메일 : info@m3.co.kr, 전화 : {{ companyInfo? companyInfo.phoneNumber : '' }})<br><br>

                            파트너기관들과 자유롭게 협상하시고 <br v-if="isMobile"/>계약대상을 지정해주세요.<br>
                            만약 제안제출 당시 1위로 선정하신 기관과 계약대상이 다르다면 순위를 변경해주세요.<br>
                            <span class="fw-bold" style="color:#4e0000">반드시 계약하실 파트너기관이 정해진 뒤 <br v-if="isMobile"/>최종선정 버튼을 눌러주세요.</span><br>
						</span>
                    </div>
                </div>

                <div v-if="(!fetching && candidatesList.length > 0)">
                    <div class="x_div_end">
                        <div class="py-4"><button class="x_div_center" @click="confirmRank()" :disabled="isRankConfirmed" :style="'width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px' + (isRankConfirmed? '; opacity: 0.5' : '')"><span class="fa-kor-Noto fs-085 fc-w1">확정<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                        <div class="py-4" v-if="isRankFixed" style="margin-left: 15px"><button class="x_div_center" @click="confirmPriority()" :disabled="isPriorityConfirmed" :style="'width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px' + (isPriorityConfirmed? '; opacity: 0.5' : '')"><span class="fa-kor-Noto fs-085 fc-w1">최종선정<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></span></button></div>
                    </div>

                    <div style="height:50px"></div>

                    <div>
                        <div class="x_div_center py-1"><i class="fa-solid fa-circle-info fs-13" style="color:#4e0000"></i></div>
                        <div class="x_div_center py-1"><span class="fkr-set1 fs-095 fw-bold" style="color:#4e0000">파트너기관 계약 주의 안내</span></div>
                        <div class="x_div_center py-1"><span class="fkr-set1 fs-095 fw-bold" style="color:#4e0000">파트너기관이 할인 등을 조건으로 직접 계약을 유도하는 경우 M3Systems 로 알려주시기 바랍니다.</span></div>
                    </div>
                </div>

				<div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'BiddingCustomerEvaluate',
    data() {
        return {
            url : '',
            fetching : true,
            submitting: false,
            isMobile : false,
            prjId : sessionStorage.getItem('prjId'),
            prcCd : sessionStorage.getItem('prcCd'),
            prcId : sessionStorage.getItem('prcId'),
            candidatesList: [],
            isRankConfirmed: false,
            isRankFixed: false,
            isPriorityConfirmed: false,
            dsgnRprsnttvImgPath : '',
            bddngProposalDdln: '',
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            buildMode: process.env.NODE_ENV
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        },
        companyInfo: {
            type: Object,
            default: null
        }
    },
    created() {
        this.url = location.href;
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
            if(this.$route.params.prjId) {
                this.$router.push({ path: '/member/login', query: { fromTalk: encodeURIComponent('/project/bidding/customer/bidding-evaluate/' + this.$route.params.prjId) } });
            }
            else {
                location.href = '/member/login'
            }
        }
        else if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        else if(!this.prjId || this.prjId == 'null') {
            if(this.$route.params.prjId) {
                var returnValue = await Api.post('/api/v1/common/get/map/talk', {usrId: this.user.id, url: '/project/bidding/customer/bidding-evaluate/' + this.$route.params.prjId});
                if(returnValue.result && returnValue.data.id) {
                    this.prjId = returnValue.data.prjId;
                    this.prcCd = returnValue.data.prcCd;
                    this.prcId = returnValue.data.prcId;
                    sessionStorage.setItem('prjId', this.prjId);
                    sessionStorage.setItem('prcCd', this.prcCd);
                    sessionStorage.setItem('prcId', this.prcId);
                }
                else {
                    alert('잘못된 접근입니다.');
                    location.href = '/project/dashboard'    
                }
            }
            else {
                alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
                location.href = '/project/dashboard'
            }
        }
        else if(this.prcCd == 'null') {
            alert('프로젝트 단계를 선택하셔야 합니다.');
            location.href = '/project/summary/' + this.prjId;
        }
        this.isMobile = window.innerWidth < 768;
        const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        if(rtnval.result) {
            this.dsgnRprsnttvImgPath = rtnval.data.dsgnRprsnttvImgPath[0];
        }

        this.fetchProposalInfo();
    },
    methods: {
        async fetchProposalInfo() {
            this.fetching = true;
            const rtnvalue = await Api.post('/api/v1/process/get', {prjId: this.prjId, prcCd: this.prcCd});
            this.fetching = false;
            if(rtnvalue.result) {
                const curruntDate = new Date();
                const selectionDueDate = new Date(rtnvalue.data.bddngNominateDdln);
                if(this.buildMode == 'production' && this.prcCd != 'DS' && rtnvalue.data.bddngNominateDdln && curruntDate < selectionDueDate) {
                    alert('후보 업체 선정기간이 완료된 이후 조회할 수 있습니다.')
                    location.href = '/project/bidding/customer/bidding-candidate'
                }

                if(rtnvalue.data.attachFiles && this.prcCd == 'DS') {
                    var rcptUploaded = false;
                    rtnvalue.data.attachFiles.forEach((file) => {
                        if(file.prcAttchFlCtgry.code == 'RT') {
                            rcptUploaded = true;
                        }
                    })
                    if(!rcptUploaded) {
                        alert('제안 제출 요청비용 납부 및 M3에서 확인 후 해당 영수증 업로드 완료 이후 진행하실 수 있습니다.')
                        location.href = '/project/bidding/customer/bidding-candidate'
                    }
                }

                this.bddngProposalDdln = rtnvalue.data.bddngProposalDdln;
                this.candidatesList = []
                var participates = rtnvalue.data.bddngs? rtnvalue.data.bddngs.sort((a,b) => { return new Date(a.proposalDt) - new Date(b.proposalDt) }) : null;
                if(participates) {
                    var imgPath= [];
                    participates.forEach((item) => {
                        if(item.nominatedDt) {
                            if(item.evalRank > 0) {
                                this.isRankConfirmed = true;
                            }
                            if(item.evaluatedDt) {
                                this.isRankFixed = true;
                            }
                            if(item.cntrctPriority && item.cntrctPriority > 0) {
                                this.isPriorityConfirmed = true;
                            }
                            if(item.attchNm) {
                                var tokens = item.attchNm.split('.');
                                var mimeType = tokens[tokens.length-1].toLowerCase();
                                var fileType = mimeType == 'pdf' || mimeType == 'zip'? 'application/' + mimeType : 'image/' + mimeType;
        
                                var byteCharacters = atob(item.attchData);
                                var byteNumbers = new Array(byteCharacters.length);
                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                var byteArray = new Uint8Array(byteNumbers);
                                var blob = new Blob([byteArray], {type: fileType});
                                item.fileLink = URL.createObjectURL(blob);
                                item.imgPath = 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + this.fixedEncodeURIComponent(this.prcCd == 'DS'? item.webPath : this.dsgnRprsnttvImgPath);
                                this.candidatesList.push(item);
                                imgPath.push(item.imgPath);
                            }
                            item.cntrctPriority = item.cntrctPriority? item.cntrctPriority : item.evalRank;
                        }
                    })
                    const intervalId = window.setInterval(() => {
                        console.log(window.$('.picture').html());
                        if(window.$('.picture').html() != undefined) {
                            imgPath.forEach((item, index) => {
                                window.$('.picture:eq(' + index + ')').css('background-image', 'url(' + item + ')');
                            })
                            window.clearInterval(intervalId);
                        }
                    }, 100)
                }
            }
            else {
                alert(rtnvalue.errormsg)
            }
        },
        async confirmRank() {
            var rankFilter = [];
            var colision = false;
            this.candidatesList.forEach((item) => {
                if(item.evalRank == 0 || rankFilter.indexOf(item.evalRank) >= 0) {
                    colision = true;
                    return 1;
                }
                rankFilter.push(item.evalRank);
            })
            if(colision) {
                alert('순위를 정확하게 지정해주세요.');
                return 1;
            }

            if(confirm('순위를 확정하시겠습니까?')) {
                var candidateList = [];
                this.candidatesList.forEach((item) => {
                    candidateList.push({bddngId: item.bddngId, evalRank: item.evalRank, nominatedDt: item.nominatedDt})
                })
                this.submitting = true;
                const rtnval = await Api.post('/api/v1/bidding/set/multi', candidateList);
                this.submitting = false;
                if(rtnval.result) {
                    alert('순위가 확정되었습니다.\n관리자의 확인 후 최종 계약대상 선정(공개심사)를 진행하실 수 있습니다.');
                    this.fetchProposalInfo();
                }
                else {
                    alert(rtnval.errormsg)
                }
            }
        },
        async confirmPriority() {
            var rankFilter = [];
            var colision = false;
            this.candidatesList.forEach((item) => {
                if(!item.cntrctPriority || rankFilter.indexOf(item.cntrctPriority) >= 0) {
                    colision = true;
                    return 1;
                }
                rankFilter.push(item.cntrctPriority);
            })
            if(colision) {
                alert('순위를 정확하게 지정해주세요.');
                return 1;
            }

            if(confirm('순위 확정 이후에는 재선정이 불가능합니다.\n확정하시겠습니까?')) {
                var candidateList = [];
                this.candidatesList.forEach((item) => {
                    candidateList.push({bddngId: item.bddngId, evalRank: item.evalRank, cntrctPriority: item.cntrctPriority, nominatedDt: item.nominatedDt, evaluatedDt: new Date().toISOString()})
                })
                this.submitting = true;
                const rtnval = await Api.post('/api/v1/bidding/set/multi', candidateList);
                this.submitting = false;
                if(rtnval.result) {
                    alert('순위가 확정되었습니다.\n계약대상 파트너기관과의 계약을 진행하기위한 연락을 곧 드리도록 하겠습니다.\n감사합니다.');
                    this.fetchProposalInfo();
                }
                else {
                    alert(rtnval.errormsg)
                }
            }
        },
        phoneNumberFormatter(phone) {
            if(phone.indexOf("공개") >= 0) {
                return phone;
            }

            if(phone.length == 10) {
                return phone.substring(0,3) + '-' + phone.substring(3,6) + '-' + phone.substring(6);
            }
            else{
                return phone.substring(0,3) + '-' + phone.substring(3,7) + '-' + phone.substring(7);
            }
        },
        fixedEncodeURIComponent(str) {
			return encodeURIComponent(str).replace(/[!'()]/g, function(c) {
					return '%' + c.charCodeAt(0).toString(16);
				});
		},
    }
}
</script>

<style scoped>
.picture {
    display: block;
    width:98%;
    height: 270px;
    border-radius:1rem;
    background-size: cover;
    background-position: 50% 50%;
}
</style>