<template>
    <div class="ca_main">
        <div class="x_div_center">
            <div class="pg_stage">

                <div class="d-flex pt-5">
                    <div style="flex:0 0 calc(100% - 200px) !important;"><span class="fa-Kor-Noto" style="font-weight:700; font-size:22pt; color:#333333">비딩 관리</span></div>
                    <div class="x_div_center"><div class="text-end" style="width:200px"><span class="fa-Kor-Noto" style="font-size:13pt; color:#484848"><span class="fw-bold"></span></span></div></div>
                </div>

                <div style="height:5px; background-color:#dccccc; border-radius:10rem"></div>

                <div style="height:14px"></div>

                <div class="pg_m_set" v-if="fetching">
                    <div class="x_div_center"><div><i class='h3 fa fa-spinner fa-spin'></i></div></div>
                </div>
                <div v-else>
                    <div class="x_div_center py-5">
                        <div class="text-center">
                            <span class="fkr-set2 fs-10" v-if="prcCd == 'DS'">
                                <span class="fw-bold" style="color:#4e0000">설계 비딩 절차 안내</span><br><br>
                                비딩은 건축주께서 가장 마음에 드시는 파트너기관을 <br v-if="isMobile"/>선정하기 위한 과정입니다.<br/>
                                참여 의향 요청, 후보 업체 선정, 후보 업체 평가 및 선정, 계약의 과정을 거치시게 되며 약 3주의 기간이 소요됩니다.<br><br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">1. 참여 의향 요청</span><br>
                                M3에 등록된 모든 파트너기관들에게 건축주님의 프로젝트 비딩에 참여할 의향이 있는지 물어보는 단계입니다.<br/>
                                건축주께서 요청사항을 제출하시면 파트너기관들이 읽고 각사의 판단에 따라 건축주님의 프로젝트에 참여 의향을 <br v-if="isMobile"/>표시합니다.<br><br/>

                                <span class="fw-bold">본 단계를 진행하기 위해서는 후보 파트너기관들의 기획설계에 필요한 비용 납부가 필요합니다(연면적 기준, 부가세 포함).<br/>
                                    1. 60평(198㎡) 이하 : 1,100,000원<br/>
                                    2. 60~150평(495㎡) : 1,650,000원<br/>
                                    3. 150~300평(990㎡) : 2,200,000원<br/>
                                추후 선정하시는 설계사와 설계 계약 시, 총 계약금액에서 본 단계의 기획설계 비용이 공제됩니다.<br/></span><br/>
                                <span class="fw-bold fs-12" style="color:#4e0000">2. 후보 업체 선정</span><br/>
                                1에서 참여 의향을 표시한 파트너기관들 중, 비딩에 참여시킬 파트너기관을 선정하시는 단계입니다.<br/>
                                파트너기관들의 대표자 인사, 포트폴리오, 기타 홍보자료를 검토하신 후 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">3. 후보 업체 평가 및 선정</span><br/>
                                2에서 선정하신 후보 파트너기관들 중 최종 계약으로 이어질 파트너기관을 선정하시는 단계입니다.<br/>
                                후보 파트너기관들이 제출한 기획설계들을 검토하시고 우선협상 대상 순위를 정하신 후<br/>
                                파트너기관 연락처와 홈페이지가 공개되면 자유롭게 상담하신 후 최종적으로 계약 대상을 정하실 수 있습니다.<br/><br/>
                                <span class="fw-bold" style="color:#CC0000">파트너기관들이 제출한 기획설계물은 전문 자격을 취득한 설계사들의 디자인 저작권을 가진 창작물로써, 제출받으신 후 유출하거나 다른 경로의 인허가 과정에 사용하실 수 <br v-if="isMobile"/>없습니다.</span><br/>
                                <div class="custom-control custom-checkbox x_div_center w-100">
                                    <input type="checkbox" class="custom-control-input" id="copyrightAgree" v-model="copyright" value="true" required >
                                    <label class="custom-control-label" for="copyrightAgree">동의합니다.</label>
                                </div>
                                <br/><br/>
                                <span class="fw-bold fs-12" style="color:#4e0000">4. 계약</span><br/>
                                3에서 선정된 파트너기관과 계약을 진행하시는 <br v-if="isMobile"/>단계입니다.
                            </span>
                            <span class="fkr-set2 fs-10" v-if="prcCd == 'MA'">
                                <span class="fw-bold" style="color:#4e0000">제조 비딩 절차 안내</span><br><br>
                                비딩은 건축주께서 가장 마음에 드시는 파트너기관을 <br v-if="isMobile"/>선정하기 위한 과정입니다.<br/>
                                의향 요청, 후보 업체 선정, 후보 업체 평가 및 선정, 계약의 과정을 거치시게 되며 약 3주의 기간이 소요됩니다.<br><br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">1. 참여 의향 요청</span><br>
                                M3에 등록된 모든 파트너기관들에게 건축주님의 프로젝트 비딩에 참여할 의향이 있는지 물어보는 단계입니다.<br/>
                                건축주께서 요청사항을 제출하시면 파트너기관들이 읽고 각사의 판단에 따라 건축주님의 프로젝트에 참여 의향을 <br v-if="isMobile"/>표시합니다.<br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">2. 후보 업체 선정</span><br/>
                                1에서 참여 의향을 표시한 파트너기관들 중, 비딩에 참여시킬 파트너기관을 선정하시는 단계입니다.<br/>
                                파트너기관들의 대표자 인사, 포트폴리오, 기타 홍보자료를 검토하신 후 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">3. 후보 업체 평가 및 선정</span><br/>
                                2에서 선정하신 후보 파트너기관들 중 최종 계약으로 이어질 파트너기관을 선정하시는 단계입니다.<br/>
                                후보 파트너기관들이 제출한 제안서를 검토하시고 파트너기관 연락처와 홈페이지를 통해 자유롭게 상담하신 후 계약 파트너기관을 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">4. 계약</span><br/>
                                3에서 선정된 파트너기관과 계약을 진행하시는 <br v-if="isMobile"/>단계입니다.
                            </span>
                            <span class="fkr-set2 fs-10" v-if="prcCd == 'CN'">
                                <span class="fw-bold" style="color:#4e0000">시공 비딩 절차 안내</span><br><br>

                                비딩은 건축주께서 가장 마음에 드시는 파트너기관을 <br v-if="isMobile"/>선정하기 위한 과정입니다.<br/>
                                참여 의향 요청, 후보 업체 선정, 후보 업체 평가 및 선정, 계약의 과정을 거치시게 되며 약 3주의 기간이 소요됩니다.<br><br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">1. 참여 의향 요청</span><br>
                                M3에 등록된 모든 파트너기관들에게 건축주님의 프로젝트 비딩에 참여할 의향이 있는지 물어보는 단계입니다.<br/>
                                건축주께서 요청사항을 제출하시면 파트너기관들이 읽고 각사의 판단에 따라 건축주님의 프로젝트에 참여 의향을 <br v-if="isMobile"/>표시합니다.<br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">2. 후보 업체 선정</span><br/>
                                1에서 참여 의향을 표시한 파트너기관들 중, 비딩에 참여시킬 파트너기관을 선정하시는 단계입니다.<br/>
                                파트너기관들의 대표자 인사, 포트폴리오, 기타 홍보자료를 검토하신 후 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">3. 후보 업체 평가 및 선정</span><br/>
                                2에서 선정하신 후보 파트너기관들 중 최종 계약으로 이어질 파트너기관을 선정하시는 단계입니다.<br/>
                                후보 파트너기관들이 제출한 제안서를 검토하시고 파트너기관 연락처와 홈페이지를 통해 자유롭게 상담하신 후 계약 파트너기관을 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">4. 계약</span><br/>
                                3에서 선정된 파트너기관과 계약을 진행하시는 <br v-if="isMobile"/>단계입니다.
                            </span>
                            <span class="fkr-set2 fs-10" v-if="prcCd == 'IN'">
                                <span class="fw-bold" style="color:#4e0000">시공 비딩 절차 안내</span><br><br>

                                비딩은 건축주께서 가장 마음에 드시는 파트너기관을 <br v-if="isMobile"/>선정하기 위한 과정입니다.<br/>
                                참여 의향 요청, 후보 업체 선정, 후보 업체 평가 및 선정, 계약의 과정을 거치시게 되며 약 3주의 기간이 소요됩니다.<br><br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">1. 참여 의향 요청</span><br>
                                M3에 등록된 모든 파트너기관들에게 건축주님의 프로젝트 비딩에 참여할 의향이 있는지 물어보는 단계입니다.<br/>
                                건축주께서 요청사항을 제출하시면 파트너기관들이 읽고 각사의 판단에 따라 건축주님의 프로젝트에 참여 의향을 <br v-if="isMobile"/>표시합니다.<br><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">2. 후보 업체 선정</span><br/>
                                1에서 참여 의향을 표시한 파트너기관들 중, 비딩에 참여시킬 파트너기관을 선정하시는 단계입니다.<br/>
                                파트너기관들의 대표자 인사, 포트폴리오, 기타 홍보자료를 검토하신 후 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">3. 후보 업체 평가 및 선정</span><br/>
                                2에서 선정하신 후보 파트너기관들 중 최종 계약으로 이어질 파트너기관을 선정하시는 단계입니다.<br/>
                                후보 파트너기관들이 제출한 제안서를 검토하시고 파트너기관 연락처와 홈페이지를 통해 자유롭게 상담하신 후 계약 파트너기관을 선정하실 수 있습니다.<br/><br/>

                                <span class="fw-bold fs-12" style="color:#4e0000">4. 계약</span><br/>
                                3에서 선정된 파트너기관과 계약을 진행하시는 <br v-if="isMobile"/>단계입니다.
                            </span>
                        </div>
                    </div>
                    
                    <div class="x_div_center"><div class="py-4"><button @click="generate()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#4e0000; border-radius:4px"><span class="fa-kor-Noto fs-085 fc-w1">비딩 시작</span></button></div></div>
                </div>
                    
                <div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'BiddingCustomerInstruction',
    data() {
        return {
            fetching : true,
            isMobile : false,
            prcCd : null,
            copyright: false
        }
    },
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    async mounted() {
        const prjId = sessionStorage.getItem('prjId');
        this.prcCd = sessionStorage.getItem('prcCd');
        
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
        else if(this.user.info.usrRole != 'OW') {
            alert('접근권한이 없습니다.');
            location.href = '/project/dashboard';
        }
        else if(prjId == 'null') {
            alert('My Projects 페이지에서 프로젝트를 선택하셔야 합니다.');
            location.href = '/project/dashboard'
        }
        else if(this.prcCd == 'null') {
            alert('프로젝트 단계를 선택하셔야 합니다.');
            location.href = '/project/summary/' + prjId;
        }
        this.isMobile = window.innerWidth < 768;
        const rtnval = await Api.post('/api/v1/process/get', {prjId: prjId, prcCd: this.prcCd});
        if(rtnval.result) {
            const curruntDate = new Date();
            const selectionDueDate = new Date(rtnval.data.bddngNominateDdln);
            if(rtnval.data.bddngNominateDdln && curruntDate > selectionDueDate) {
                if(rtnval.data.bddngs) {
                    var nominated = false;
                    rtnval.data.bddngs.forEach((candidate) => {
                        if(candidate.nominatedDt) {
                            nominated = true;
                            location.href = '/project/bidding/customer/bidding-evaluate';
                        }
                    })
                    if(!nominated) {
                        location.href = '/project/bidding/customer/bidding-candidate'    
                    }
                }
                else {
                    location.href = '/project/bidding/customer/bidding-candidate'
                }
            }
            else if(rtnval.data.bddngJoinDdln) {
                location.href = '/project/bidding/customer/bidding-candidate'
            }
            else if(rtnval.data.bddngCrtDt) {
                location.href = '/project/bidding/customer/bidding-generate'
            }
            else {
                this.fetching = false;
            }
        }
        else{
            alert(rtnval.errormsg)
        }
    },
    methods: {
        generate() {
            if (!this.copyright) {
                alert('저작권 보호정책에 동의해주세요.');
                return false;
            }
            else {
                location.href = '/project/bidding/customer/bidding-generate';
            }
        }
    }
}
</script>