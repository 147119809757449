<template>
    <div class="ca_menu" :style="'min-height:100%; padding-top:65px; border-right:1px solid #c9c9c9; width:' + (collapsed? '53px' : '100%')">

        <div class="accordion-item"> 

            <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <div><div :class="'ms_icon1' + ((url.slice(-5) == 'admin' || url.indexOf('/admin/usr/usr-mng') > 0 || url.indexOf('/admin/usr/usr-dtl') > 0 || url.indexOf('/admin/usr/usr-sttc') > 0 || url.indexOf('/admin/prtnr/bsnss-prtnr-mng') > 0 || url.indexOf('/admin/dsgnt/admin-mng') > 0 || url.indexOf('/admin/dsgnt/cnslr-mng') > 0) ? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">회원 관리</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-if="adminLv != 'E' && adminLv != 'R'" v-show="!collapsed" id="collapseOne" :class="'sm_ms2 accordion-collapse collapse' + ((url.slice(-5) == 'admin' || url.indexOf('/admin/usr/usr-mng') > 0 || url.indexOf('/admin/usr/usr-dtl') > 0 || url.indexOf('/admin/usr/usr-sttc') > 0 || url.indexOf('/admin/prtnr/bsnss-prtnr-mng') > 0 || url.indexOf('/admin/dsgnt/admin-mng') > 0 || url.indexOf('/admin/dsgnt/cnslr-mng') > 0) ? ' show' : '')" aria-labelledby="headingOne" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.slice(-5) == 'admin' || url.indexOf('/admin/usr/usr-mng') > 0 || url.indexOf('/admin/usr/usr-dtl') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/usr/usr-mng">회원정보 조회</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/usr/usr-sttc') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/usr/usr-sttc">회원가입 통계</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/prtnr/bsnss-prtnr-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/prtnr/bsnss-prtnr-mng">사업파트너 관리</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/dsgnt/admin-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/dsgnt/admin-mng">관리자 지정</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/dsgnt/cnslr-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/dsgnt/cnslr-mng">상담자 지정</a></div></div>
                </div>
            </div>

            <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne0" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne0" aria-expanded="false" aria-controls="collapseOne">
                    <div><div :class="'ms_icon3' + ((url.indexOf('/admin/dsgn/dsgn-mng') > 0 || url.indexOf('/admin/mnfct/mnfct-mng') > 0 || url.indexOf('/admin/cntrct/cntrct-mng') > 0 || url.indexOf('/admin/intrr/intrr-mng') > 0) ? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">매칭 관리</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-show="!collapsed" id="collapseOne0" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/mnfct/mnfct-mng') > 0 || url.indexOf('/admin/cntrct/cntrct-mng') > 0 || url.indexOf('/admin/intrr/intrr-mng') > 0) ? ' show' : '')" aria-labelledby="headingOne0" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/dsgn/dsgn-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/dsgn/dsgn-mng">설계</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/mnfct/mnfct-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/mnfct/mnfct-mng">제조</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/cntrct/cntrct-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/cntrct/cntrct-mng">시공</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/intrr/intrr-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/intrr/intrr-mng">인테리어</a></div></div>
                </div>
            </div>

            <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne1" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne">
                    <div><div :class="'ms_icon3' + (url.indexOf('/admin/bddng/') > 0 ? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">비딩 관리</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-show="!collapsed" id="collapseOne1" :class="'sm_ms2 accordion-collapse collapse' + (url.indexOf('/admin/bddng/') > 0 ? ' show' : '')" aria-labelledby="headingOne1" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/bddng/bddng-setting-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/bddng/bddng-setting-mng">비딩관련 설정</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/bddng/prj-bddng-prcss-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/bddng/prj-bddng-prcss-mng">비딩 현황 요약</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/bddng/bddng-list-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/bddng/bddng-list-mng">비딩 목록 조회</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/bddng/bddng-prcss-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/bddng/bddng-prcss-mng">프로젝트별 비딩 관리</a></div></div>
                </div>
            </div>

            <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne2" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
                <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne">
                    <div><div :class="'ms_icon2' + ((url.indexOf('/admin/expns/cntrct-expns-mng') > 0 || url.indexOf('/admin/wrttn/wrttn-cntrct-mng') > 0 || url.indexOf('/admin/pymnt/prj-pymnt-prcss-mng') > 0) ? '_on' : '')"></div></div>
                    <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">계약 관리</span></div>
                    <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
                </div>
            </div>

            <div v-show="!collapsed" id="collapseOne2" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/expns/cntrct-expns-mng') > 0 || url.indexOf('/admin/wrttn/wrttn-cntrct-mng') > 0 || url.indexOf('/admin/pymnt/prj-pymnt-prcss-mng') > 0) ? ' show' : '')" aria-labelledby="headingOne2" data-bs-parent="#m3_menu">
                <div class="accordion-body p-3">
                    <!-- <div :class="'side_menu_m w-100 x_div_center ms_bg2' + (url.indexOf('/admin/pymnt/prj-pymnt-prcss-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="javascript:void(0)">대금 지급현황 요약</a></div></div> -->
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/pymnt/prj-pymnt-prcss-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/pymnt/prj-pymnt-prcss-mng">대금 지급현황 요약</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/expns/cntrct-expns-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/expns/cntrct-expns-mng">계약금 관리</a></div></div>
                    <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/wrttn/wrttn-cntrct-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/wrttn/wrttn-cntrct-mng">계약서 관리</a></div></div>
                </div>
            </div>

          <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne3" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
            <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne2">
              <div><div :class="'ms_icon2' + ((url.indexOf('/admin/usr/prj-mng') > 0 || url.indexOf('/admin/usr/primprj-mng') > 0) ? '_on' : '')"></div></div>
              <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">프로젝트 관리</span></div>
              <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
            </div>
          </div>

          <div v-show="!collapsed" id="collapseOne3" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/usr/prj-mng') > 0 || url.indexOf('/admin/usr/primprj-mng') > 0) ? ' show' : '')" aria-labelledby="headingOne3" data-bs-parent="#m3_menu">
            <div class="accordion-body p-3">
              <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/usr/prj-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/usr/prj-mng">프로젝트 조회</a></div></div>
              <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/usr/primprj-mng') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/usr/primprj-mng">후보프로젝트 조회</a></div></div>
            </div>
          </div>

          <div v-if="adminLv != 'E' && adminLv != 'R'" id="headingOne5" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
            <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5" aria-expanded="false" aria-controls="collapseOne2">
              <div><div :class="'ms_icon2' + ((url.indexOf('/admin/usdmtrl/list') > 0) ? '_on' : '')"></div></div>
              <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">중고모듈거래 관리</span></div>
              <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
            </div>
          </div>

          <div v-show="!collapsed" id="collapseOne5" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/usdmtrl/list') > 0) ? ' show' : '')" aria-labelledby="headingOne5" data-bs-parent="#m3_menu">
            <div class="accordion-body p-3">
              <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/usdmtrl/list') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/usdmtrl/list">중고모듈거래 관리</a></div></div>
            </div>
          </div>

          <div v-if="adminLv != 'R'" id="headingOne6" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
            <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne6" aria-expanded="false" aria-controls="collapseOne2">
              <div><div :class="'ms_icon2' + (url.indexOf('/admin/estmt') > 0? '_on' : '')"></div></div>
              <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">실시간 견적산출</span></div>
              <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
            </div>
          </div>

          <div v-show="!collapsed" id="collapseOne6" :class="'sm_ms2 accordion-collapse collapse' + (url.indexOf('/admin/estmt') > 0? ' show' : '')" aria-labelledby="headingOne6" data-bs-parent="#m3_menu">
            <div class="accordion-body p-3">
              <div v-if="adminLv != 'E' && adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/estmt/unit-price') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/estmt/unit-price">자재정보 관리</a></div></div>
              <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/estmt/list') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/estmt/list">견적내역 관리</a></div></div>
            </div>
          </div>

          <div v-if="adminLv != 'E'" id="headingOne4" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
            <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4" aria-expanded="false" aria-controls="collapseOne2">
              <div><div :class="'ms_icon1' + ((url.indexOf('/admin/site/') > 0) ? '_on' : '')"></div></div>
              <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">사이트 관리</span></div>
              <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
            </div>
          </div>

          <div v-show="!collapsed" id="collapseOne4" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/site/') > 0) ? ' show' : '')" aria-labelledby="headingOne4" data-bs-parent="#m3_menu">
            <div class="accordion-body p-3">
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/company-info') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/company-info">회사정보</a></div></div>
              <div :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/recent') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/recent-list">사업현황</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/news') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/news-list">뉴스</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/faq') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/faq-list">FAQ</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/partnership') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/partnership-list">파트너목록</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/member') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/member-list">임원진 프로필</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/certificate') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/certificate-list">인증서 및 특허</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/download') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/download-list">다운로드 게시판</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/question') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/questionform-list">고객문의양식 설정</a></div></div>
              <!-- <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/event') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/event-result">이벤트</a></div></div> -->
              <!-- <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/site/template') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/site/template-list">PANDA</a></div></div> -->
            </div>
          </div>

          <div v-if="adminLv != 'E'" id="headingOne7" class="accordion-header x_div_center px-3" style="height:55px; border-bottom:1px solid #c9c9c9">
            <div class="accordion-button collapsed x_div_center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne7" aria-expanded="false" aria-controls="collapseOne2">
              <div><div :class="'ms_icon1' + ((url.indexOf('/admin/system/') > 0) ? '_on' : '')"></div></div>
              <div v-show="!collapsed" class="sm_ms2 w-100 ps-2"><span class="ms_fa1 fw-bold">시스템 관리</span></div>
              <div v-show="!collapsed" class="sm_ms2"><img src="@/assets/img_legacy/ms_arrow.png"></div>
            </div>
          </div>

          <div v-show="!collapsed" id="collapseOne7" :class="'sm_ms2 accordion-collapse collapse' + ((url.indexOf('/admin/system/') > 0) ? ' show' : '')" aria-labelledby="headingOne7" data-bs-parent="#m3_menu">
            <div class="accordion-body p-3">
              <!-- <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/system/party') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/system/party-list">식샤를 합시다</a></div></div> -->
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/system/schedule/keyword') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/system/schedule/keyword">출타현황 키워드</a></div></div>
              <div v-if="adminLv != 'R'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/system/smtp/history') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/system/smtp/history">고객문의 보관함</a></div></div>
              <div v-if="adminLv == 'S'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/system/auth') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/system/auth-list">접근권한관리</a></div></div>
              <div v-if="adminLv == 'S'" :class="'side_menu_m w-100 x_div_center ms_bg1' + (url.indexOf('/admin/system/code') > 0 ? '_on' : '')" style="height:36px; padding-left:40px"><div class="w-100 text-start"><a class="ms_fa1" href="/admin/system/code-list">코드목록</a></div></div>
            </div>
          </div>
          

        </div>

        <div class="x_div_end" style="padding:50px 8px 50px 0">
            <div @click="collapsed = !collapsed" :class="'x_div_end ar_btn' + (collapsed? '' : ' sec')"></div>
        </div>

    </div>
</template>

<script>
import Api from '@/api'
export default {
    name: 'LeftMenu',
    data() {
        return {
            url: '',
            collapsed: false,
            adminLv: null,
            buildMode: process.env.NODE_ENV
        }
    },
    async mounted() {
        this.url = location.href;
        var rtnval = await Api.get('/api/v1/admin/usr/mng/find/usr/id');
        if(rtnval.result){
            let usrId = rtnval.data;

            rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId='+usrId);
            if(rtnval.result){
                this.adminLv = rtnval.data.adminYn;
            }
        }
    }
}
</script>