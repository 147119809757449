import UsrMng from '@/views/admin/usr/UsrMng.vue'
import UsrDtl from '@/views/admin/usr/UsrDtl.vue'
import UsrSttc from '@/views/admin/usr/UsrSttc.vue'
import PrjMng from '@/views/admin/prj/PrjMng.vue'
import PrimPrjMng from '@/views/admin/prj/PrimPrjMng.vue'
import BsnssPrtnrMng from "@/views/admin/prtnr/BsnssPrtnrMng";
import BddngPrcssMng from "@/views/admin/bddng/BddngPrcssMng";
import BddngListMng from "@/views/admin/bddng/BddngListMng";
import PrjBddngPrcssMng from "@/views/admin/bddng/PrjBddngPrcssMng";
import BddngSettingMng from "@/views/admin/bddng/BddngSettingMng";
import WrttnCntrctMng from "@/views/admin/wrttn/WrttnCntrctMng";
import CntrctExpnsMng from "@/views/admin/expns/CntrctExpnsMng";
import DsgnMng from "@/views/admin/dsgn/DsgnMng";
import MnfctMng from "@/views/admin/mnfct/MnfctMng";
import CntrctMng from "@/views/admin/cntrct/CntrctMng";
import IntrrMng from "@/views/admin/intrr/IntrrMng";
import PrjPymntPrcssMng from "@/views/admin/pymnt/PrjPymntPrcssMng";
import AdminMng from "@/views/admin/dsgnt/AdminMng";
import CnslrMng from "@/views/admin/dsgnt/CnslrMng";
import ExprDtMng from "@/views/admin/dt/ExprDtMng";
import UsdMtrlList from "@/views/admin/usdmtrl/UsdMtrlList";
import UnitPriceList from "@/views/admin/estmt/UnitPriceList";
import UnitPriceEdit from "@/views/admin/estmt/UnitPriceEdit";
import EstimationList from "@/views/admin/estmt/EstimationList";
import EstimationEdit from "@/views/admin/estmt/EstimationEdit";
import FaqEdit from "@/views/admin/site_mgmnt/faq/FaqEdit"
import FaqList from "@/views/admin/site_mgmnt/faq/FaqList"
import TextTemplateEdit from "@/views/admin/site_mgmnt/panda/TextTemplateEdit"
import TextTemplateList from "@/views/admin/site_mgmnt/panda/TextTemplateList"
import PartnershipEdit from "@/views/admin/site_mgmnt/partnership/PartnershipEdit"
import PartnershipList from "@/views/admin/site_mgmnt/partnership/PartnershipList"
import PartnershipSetCtgry from "@/views/admin/site_mgmnt/partnership/PartnershipSetCtgry"
import QuestionFormList from "@/views/admin/site_mgmnt/question/QuestionFormList"
import QuestionFormEdit from "@/views/admin/site_mgmnt/question/QuestionFormEdit"
import QuestionSetCtgry from "@/views/admin/site_mgmnt/question/QuestionSetCtgry"
import RecentEdit from "@/views/admin/site_mgmnt/recent/RecentEdit"
import RecentList from "@/views/admin/site_mgmnt/recent/RecentList"
import MemberEdit from "@/views/admin/site_mgmnt/member/MemberEdit"
import MemberList from "@/views/admin/site_mgmnt/member/MemberList"
import ProxyPage from "@/views/admin/site_mgmnt/recent/ProxyPage"
import CompanyInfo from "@/views/admin/site_mgmnt/companyinfo/CompanyInfo"
import CertificateList from "@/views/admin/site_mgmnt/certificates/CertificateList"
import DownloadItemList from "@/views/admin/site_mgmnt/download/DownloadItemList"
import NewsList from "@/views/admin/site_mgmnt/news/NewsList"
import PartyList from "@/views/admin/site_mgmnt/sicksha/PartyList"
import PartyEnlist from "@/views/admin/site_mgmnt/sicksha/PartyEnlist"
import SmtpHistory from "@/views/admin/site_mgmnt/smtp_history/SmtpHistory"
import CodeList from "@/views/admin/site_mgmnt/code/CodeList"
import AuthList from "@/views/admin/site_mgmnt/usr_authority/AuthList"
import EventResult from '@/views/admin/site_mgmnt/event/EventResult.vue'
import EventKeywordList from '@/views/admin/site_mgmnt/schedule_mgmnt/EventKeywordList.vue'

export default [
    {
        path: '/admin',
        name: 'main',
        component: UsrMng
    },
    {
        path: '/admin/usr/usr-mng',
        name: 'UsrMng',
        component: UsrMng
    },
    {
        path: '/admin/usr/usr-dtl/:usrId',
        name: 'UsrDtl',
        component: UsrDtl
    },
    {
        path: '/admin/usr/usr-sttc',
        name: 'UsrSttc',
        component: UsrSttc
    },
    {
        path: '/admin/usr/prj-mng',
        name: 'PrjMng',
        component: PrjMng
    },
    {
        path: '/admin/usr/primprj-mng',
        name: 'PrimPrjMng',
        component: PrimPrjMng
    },
    {
        path: '/admin/dsgnt/admin-mng',
        name: 'AdminMng',
        component: AdminMng
    },
    {
        path: '/admin/dsgnt/cnslr-mng',
        name: 'CnslrMng',
        component: CnslrMng
    },
    {
        path: '/admin/prtnr/bsnss-prtnr-mng',
        name: 'BsnssPrtnrMng',
        component: BsnssPrtnrMng
    },
    {
        path: '/admin/dsgn/dsgn-mng',
        name: 'DsgnMng',
        component: DsgnMng
    },
    {
        path: '/admin/mnfct/mnfct-mng',
        name: 'MnfctMng',
        component: MnfctMng
    },
    {
        path: '/admin/cntrct/cntrct-mng',
        name: 'CntrctMng',
        component: CntrctMng
    },
    {
        path: '/admin/intrr/intrr-mng',
        name: 'IntrrMng',
        component: IntrrMng
    },
    {
        path: '/admin/bddng/bddng-setting-mng',
        name: 'BddngSettingMng',
        component: BddngSettingMng
    },
    {
        path: '/admin/bddng/prj-bddng-prcss-mng',
        name: 'PrjBddngPrcssMng',
        component: PrjBddngPrcssMng
    },
    {
        path: '/admin/bddng/bddng-prcss-mng',
        name: 'BddngPrcssMng',
        component: BddngPrcssMng
    },
    {
        path: '/admin/bddng/bddng-list-mng',
        name: 'BddngPrcssMng',
        component: BddngListMng
    },
    {
        path: '/admin/expns/cntrct-expns-mng',
        name: 'CntrctExpnsMng',
        component: CntrctExpnsMng
    },
    {
        path: '/admin/wrttn/wrttn-cntrct-mng',
        name: 'WrttnCntrctMng',
        component: WrttnCntrctMng
    },
    {
        path: '/admin/pymnt/prj-pymnt-prcss-mng',
        name: 'PrjPymntPrcssMng',
        component: PrjPymntPrcssMng
    },
    {
        path: '/admin/dt/expr-dt-mng',
        name: 'ExprDtMng',
        component: ExprDtMng
    },
    {
        path: '/admin/usdmtrl/list',
        name: 'UsdMtrlList',
        component: UsdMtrlList
    },
    {
        path: '/admin/estmt/unit-price',
        name: 'UnitPriceList',
        component: UnitPriceList
    },
    {
        path: '/admin/estmt/unit-price/:stdMtrlId',
        name: 'UnitPriceEdit',
        component: UnitPriceEdit
    },
    {
        path: '/admin/estmt/list',
        name: 'EstimationList',
        component: EstimationList
    },
    {
        path: '/admin/estmt/dtl/:estmtId',
        name: 'EstimationEdit',
        component: EstimationEdit
    },



    {
        path: '/proxypage',
        name: 'ProxyPage',
        component: ProxyPage
    },
    {
        path: '/admin/site/company-info',
        name: 'CompanyInfo',
        component: CompanyInfo
    },
    {
        path: '/admin/site/recent-edit',
        name: 'RecentEdit',
        component: RecentEdit
    },
    {
        path: '/admin/site/recent-edit/:recentId',
        name: 'RecentEdit',
        component: RecentEdit
    },
    {
        path: '/admin/site/recent-list',
        name: 'RecentList',
        component: RecentList
    },
    {
        path: '/admin/site/news-list',
        name: 'NewsList',
        component: NewsList
    },    
    {
        path: '/admin/site/faq-edit/:faqId',
        name: 'FaqEdit',
        component: FaqEdit
    },
    {
        path: '/admin/site/faq-list',
        name: 'FaqList',
        component: FaqList
    },    
    {
        path: '/admin/site/partnership-edit/:partnerId',
        name: 'PartnershipEdit',
        component: PartnershipEdit
    },
    {
        path: '/admin/site/partnership-list',
        name: 'PartnershipList',
        component: PartnershipList
    },
    {
        path: '/admin/site/partnership-setcategory',
        name: 'PartnershipSetCtgry',
        component: PartnershipSetCtgry
    },
    {
        path: '/admin/site/questionform-edit/:formId',
        name: 'QuestionFormEdit',
        component: QuestionFormEdit
    },
    {
        path: '/admin/site/questionform-list',
        name: 'QuestionFormList',
        component: QuestionFormList
    },
    {
        path: '/admin/site/question-setcategory',
        name: 'QuestionSetCtgry',
        component: QuestionSetCtgry
    },
    {
        path: '/admin/site/member-edit/:memberId',
        name: 'MemberEdit',
        component: MemberEdit
    },
    {
        path: '/admin/site/member-list',
        name: 'MemberList',
        component: MemberList
    },
    {
        path: '/admin/site/certificate-list',
        name: 'CertificateList',
        component: CertificateList
    },
    {
        path: '/admin/site/download-list',
        name: 'DownloadItemList',
        component: DownloadItemList
    },
    {
        path: '/admin/site/event-result',
        name: 'EventResult',
        component: EventResult
    },        
    {
        path: '/admin/site/template-edit/:templateId',
        name: 'TextTemplateEdit',
        component: TextTemplateEdit
    },
    {
        path: '/admin/site/template-list',
        name: 'TextTemplateList',
        component: TextTemplateList
    },




    {
        path: '/admin/system/party-list',
        name: 'PartyList',
        component: PartyList
    },
    {
        path: '/admin/system/party-enlist/:partyId',
        name: 'PartyEnlist',
        component: PartyEnlist
    },
    {
        path: '/admin/system/schedule/keyword',
        name: 'EventKeywordList',
        component: EventKeywordList
    },
    {
        path: '/admin/system/smtp/history',
        name: 'SmtpHistory',
        component: SmtpHistory
    },    
    {
        path: '/admin/system/auth-list',
        name: 'AuthList',
        component: AuthList
    },    
    {
        path: '/admin/system/code-list',
        name: 'CodeList',
        component: CodeList
    },

]