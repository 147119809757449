<template>
    <div class="m3_contetns" style="margin-top: 50px">

    <!-- 로그인 box 시작 { -->
    <div class="m_mem_box">

        <div style="width:90%">

            <div class="x_div_center pb-3"><div class="h3 ff-kor1 fc-w6">회원정보찾기</div></div>

            <div class="d-flex p-3 px-3" style="background-color:#e9e9e9; border:1px solid #d9d9d9; border-radius:.5rem">
                <div class="x_div_center pe-2"><i class="fa-solid fa-circle-info fs-12"></i></div>
                <div><span class="fkr-set1 fc-w5">회원가입시 등록하신 휴대폰 번호를 입력해주세요.</span></div>
            </div>

            <div class="x_div_center py-3 x_div_end">
                <div class="x_div_center">
                    <div class="x_div_center px-2">
                        <i class="fa-solid fa-circle-check pe-1"></i> <a href="/member/findid"><span class="ff-kor1 fs-085 fw-bold">아이디 찾기</span></a> │
                        <i class="fa-regular fa-circle-check pe-1"></i> <a href="/member/resetpass"><span class="ff-kor1 fs-085">비밀번호 재설정</span></a>
                    </div>
                </div>
            </div>

            <div>
                <div class="text-start" style="padding-bottom:.5rem"><span class="fkr-set1">휴대폰번호</span></div>
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9">
                    <div style="width:300px"><input type="text" v-model="phone" class="form-control ff-eng1" style="width:100%; height:36px; border:1px solid #d9d9d9; border-radius:2rem"></div>
                    <div style="width:20px"></div>
                    <div class="w-100"><button class="fkr-set1 fc-w1" @click="callAuth()" style="width:130px; height:36px; background-color:#fff; border:1px solid #000; color:#000; border-radius:2rem">인증요청</button></div>
                </div>
                <div class="text-start" style="padding:1rem 0 .5rem 0"><span class="fkr-set1">인증번호 입력</span></div>
                <div class="x_div_center pb-3" style="border-bottom:1px solid #d9d9d9">
                    <div style="width:300px"><input type="text" v-model="certNmbr" class="form-control ff-eng1"  style="width:100%; height:36px; border:1px solid #d9d9d9; border-radius:2rem"></div>
                    <div style="width:20px"></div>
                    <div class="w-100"><button class="fkr-set1 fc-w1" @click="confirmAuth()" style="width:130px; height:36px; background-color:#fff; border:1px solid #000; color:#000; border-radius:2rem">인증확인<i v-if="submitting" style="margin-left: 10px" class='fa fa-spinner fa-spin'></i></button></div>
                </div>
            </div>

            <div class="px-3 py-3"><span class="fkr-set1 fc-w5">* 인증 완료시 팝업으로 찾으시는 아이디가 안내됩니다.</span></div>

            <div class="x_div_center pt-3"><button class="fkr-set1 fc-w1" style="width:300px; height:36px; border:0; background-color:#000; border-radius:4px;" onclick="location.href='/member/login'">로그인 화면으로 돌아가기</button></div>

            <!-- 이용자 아이디 시작 { -->
			<div id="showId" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
				<div class="modal-dialog" role="document">
					<div class="modal-content">

						<div class="modal-body">
							<div class="x_div_center py-3"><span class="fkr-set1 fs-13">고객님의 아이디는 다음과 같습니다</span></div>
							<div class="x_div_center" style="display:grid !important">
								<div class="p-3" style="width:100%;" v-for="(info, index) in userInfo" :key="index">
									<div class="x_div_center fen-set1 fs-095 fc-w10"> {{ info.email + info.oauthType }} </div>
								</div>
							</div>
							<div class="x_div_center"><div class="pt-3" data-dismiss="modal"><button class="fkr-set1 fc-w1" @click="hideModal()" style="width:130px; height:36px; background-color:#eee7e7; border:1px solid #4e0000; color:#4e0000; border-radius:2rem">확인</button></div></div>
						</div>

					</div>
				</div>
			</div>
			<!-- } 이용자 아이디 끝 -->
        </div>

    </div>
    <!-- } 로그인 box 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'FindId',
    data() {
        return {
            submitting: false,
            phone: '',
            certNmbr: '',
            userInfo: []
        }
    },
    async mounted() {
        if(await Auth.isLogedIn()){
            this.$router.push('/');
        }
    },
    watch: {
        phone(val) {
            this.phone = val.replace(/-/g, '').replace(/\s/g, '');
        }
    },
    methods: {
        hideModal() {
            window.$('#showId').modal('hide');
        },
        async callAuth() {
            if(!this.phone) {
                alert('휴대폰번호를 입력해주세요!');
            }
            else {
                var phoneRegular = this.phone.replace(/^0/g, '82');
                var result = await Api.post('/api/v1/common/send/sms', {phone: phoneRegular});
                if(result.result){
                    this.usrCertId = result.data.usrCertId;
                    alert('입력하신 번호로 인증번호가 전송되었습니다.\n인증번호를 입력하고 확인 버튼을 눌러주세요.')
                }
                else{
                    alert(result.errormsg);
                }
            }
        },
        async confirmAuth() {
            this.submitting = true;
            var result = await Api.post('/api/v1/common/check/certNmbr', {certNmbr: this.certNmbr, usrCertId: this.usrCertId});
            if(result.result){
                var rtnval = await Api.post('/api/v1/user/find/usr', {phone: this.phone});
                if(rtnval.result){
                    if(rtnval.data.length > 0) {
                        rtnval.data.forEach((info) => {
                            var tail = '';
                            switch(info.oauthType) {
                                case 'KAKAO' : tail = ' - 소셜계정(카카오)';
                                    break;
                                case 'NAVER' : tail = ' - 소셜계정(네이버)';
                                    break;
                                case 'APPLE' : tail = ' - 소셜계정(애플)';
                                    break;
                            }
                            if(info.dltYn == 'Y') {
                                tail = ' (탈퇴계정)';
                            }
                            this.userInfo.push({email: info.email, oauthType: tail});
                        })
                        window.$('#showId').modal('show');
                    }
                    else {
                        alert('해당 번호로 등록된 계정이 존재하지 않습니다.')
                    }
                }
                else{
                    alert(rtnval.errormsg);
                }
            }
            else{
                alert(result.errormsg);
            }
            this.submitting = false;
        }
    }
}
</script>