import { render, staticRenderFns } from "./Prj.vue?vue&type=template&id=179aa2ab&scoped=true&"
import script from "./Prj.vue?vue&type=script&lang=js&"
export * from "./Prj.vue?vue&type=script&lang=js&"
import style0 from "./Prj.vue?vue&type=style&index=0&id=179aa2ab&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "179aa2ab",
  null
  
)

export default component.exports